const RoyalVillasCarousel = () => {
    return(
        <div id="carouselExampleCaptions" class="carousel slide" data-mdb-ride="carousel">
            <div class="carousel-indicators">
                <button
                type="button"
                data-mdb-target="#carouselExampleCaptions"
                data-mdb-slide-to="0"
                class="active"
                aria-current="true"
                aria-label="Slide 1"
                ></button>
                <button
                type="button"
                data-mdb-target="#carouselExampleCaptions"
                data-mdb-slide-to="1"
                aria-label="Slide 2"
                ></button>
                <button
                type="button"
                data-mdb-target="#carouselExampleCaptions"
                data-mdb-slide-to="2"
                aria-label="Slide 3"
                ></button>
                <button
                type="button"
                data-mdb-target="#carouselExampleCaptions"
                data-mdb-slide-to="3"
                aria-label="Slide 4"
                ></button>
                <button
                type="button"
                data-mdb-target="#carouselExampleCaptions"
                data-mdb-slide-to="4"
                aria-label="Slide 5"
                ></button>
                <button
                type="button"
                data-mdb-target="#carouselExampleCaptions"
                data-mdb-slide-to="5"
                aria-label="Slide 6"
                ></button>
            </div>
            <div class="carousel-inner">
                <div class="carousel-item active">
                    <img src="/assets/projects/Royal Villas/slider/01.jpg" class="d-block w-100" alt="The Ewen Park - Royal Villas"/>
                </div>
                <div class="carousel-item">
                        <img src="/assets/projects/Royal Villas/slider/02.jpg" class="d-block w-100" alt="The Ewen Park - Royal Villas"/>
                </div>
                <div class="carousel-item">
                    <img src="/assets/projects/Royal Villas/slider/03.jpg" class="d-block w-100" alt="The Ewen Park - Royal Villas"/>
                </div>
                <div class="carousel-item">
                    <img src="/assets/projects/Royal Villas/slider/04.jpg" class="d-block w-100" alt="The Ewen Park - Royal Villas"/>
                </div>
                <div class="carousel-item">
                    <img src="/assets/projects/Royal Villas/slider/05.jpg" class="d-block w-100" alt="The Ewen Park - Royal Villas"/>
                </div>
                <div class="carousel-item">
                    <img src="/assets/projects/Royal Villas/slider/06.png" class="d-block w-100" alt="The Ewen Park - Royal Villas"/>
                </div>
            </div>
        </div>
    );
}

export default RoyalVillasCarousel;