import { Link } from 'react-router-dom';

const Carousel = () => {
    return(
        <>
        <div id="carouselExampleIndicators" className="carousel slide slides p-0" data-mdb-ride="carousel">
            <div className="carousel-indicators">
                <button
                type="button"
                data-mdb-target="#carouselExampleIndicators"
                data-mdb-slide-to="0"
                className="active"
                aria-current="true"
                aria-label="Slide 1"
                ></button>
                <button
                type="button"
                data-mdb-target="#carouselExampleIndicators"
                data-mdb-slide-to="1"
                aria-label="Slide 2"
                ></button>
                <button
                type="button"
                data-mdb-target="#carouselExampleIndicators"
                data-mdb-slide-to="2"
                aria-label="Slide 3"
                ></button>
                <button
                type="button"
                data-mdb-target="#carouselExampleIndicators"
                data-mdb-slide-to="3"
                aria-label="Slide 4"
                ></button>
                <button
                type="button"
                data-mdb-target="#carouselExampleIndicators"
                data-mdb-slide-to="4"
                aria-label="Slide 5"
                ></button>
                <button
                type="button"
                data-mdb-target="#carouselExampleIndicators"
                data-mdb-slide-to="5"
                aria-label="Slide 6"
                ></button>
                <button
                type="button"
                data-mdb-target="#carouselExampleIndicators"
                data-mdb-slide-to="6"
                aria-label="Slide 7"
                ></button>
                <button
                type="button"
                data-mdb-target="#carouselExampleIndicators"
                data-mdb-slide-to="7"
                aria-label="Slide 8"
                ></button>
                {/* <button
                type="button"
                data-mdb-target="#carouselExampleIndicators"
                data-mdb-slide-to="8"
                aria-label="Slide 9"
                ></button> */}
            </div>
            <div className="carousel-inner headCar">
                <div className="carousel-item active">
                    <img src="/assets/Banners/kasar_himalayan.jpg" className="d-block" alt="Ewen Realtors"/>
                </div>
                <div className="carousel-item">
                    <Link to="/projects/royal-villas">
                        <img src="/assets/Banners/3.jpg" className="d-block" alt="Ewen Realtors"/>
                    </Link>
                </div>
                <div className="carousel-item">
                    <Link to="/projects/himalayan-vista">
                        <img src="/assets/Banners/2.jpg" className="d-block" alt="Ewen Realtors"/>
                    </Link>
                </div>
                {/* <div className="carousel-item">
                    <Link to="/projects/hill-village">
                        <img src="/assets/Banners/1.jpg" className="d-block" alt="Ewen Realtors"/>
                    </Link>
                </div> */}
                <div className="carousel-item">
                    <img src="/assets/Banners/Banners 01.jpg" className="d-block" alt="Ewen Realtors"/>
                </div>
                <div className="carousel-item">
                    <img src="/assets/Banners/Banners 05_layout.jpg" className="d-block" alt="Ewen Realtors"/>
                </div>
                <div className="carousel-item">
                    <img src="/assets/Banners/Banners 04.jpg" className="d-block" alt="Ewen Realtors"/>
                </div>
                <div className="carousel-item">
                    <img src="/assets/Banners/Banners 03.jpg" className="d-block" alt="Ewen Realtors"/>
                </div>
                <div className="carousel-item">
                    <img src="/assets/Banners/Banners 02.jpg" className="d-block" alt="Ewen Realtors"/>
                </div>
            </div>
        </div>
        </>
    );
}

export default Carousel;