import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Carousel from "react-bootstrap/Carousel";

const Facts = () => {
    const testimonial = [
        {
          content:
            "Ranikhet means Queen’s Meadow. The name was given by Rani Padmini who chose Ranikhet as her residence because she was allured by the beauty of this place."
        },
        {
          content:
            "In Ranikhet, there is a 9-hole golf course called Upat Golf course, which is one of the highest golf courses in Asia which is 5 kilometres from main city Ranikhet. "
        },
        {
          content:
            "An open-air fernery was opened in Ranikhet, Uttarakhand's Almora district, by Nilambar Kunetha, a well-known fern expert. There are 120 different types of ferns in the collection."
        },
        {
          content:
            "The Kumaon Regiment Museum displays pictures and artefacts that illustrate the contributions of the Regiment to the World Wars, the Sino-Indian War, the Indo-Pakistani War, and Operation Pawan."
        },
        {
         content:
              "There are about 4000 species of plants in Ranikhet, making it very flora-rich. The place has large diversity in its natural vegetation due to its varying altitudes, climatic factors, and soil type."
        }
      ];
    return(
        <section className="facts_BG">
            <div className="container pb-3">
                <div className="card rounded-9 border-1 py-5 text-center facts" style={{background: 'rgba(255,255,255,0.3)', backdropFilter: 'blur(3px)'}}>
                    <div className="">
                        <i class="fas fa-lightbulb fa-4x text-warning"></i>
                        <h3 className="pt-4 text-center font-weight-bolder text-dark">Facts About Ranikhet</h3>
                    </div>
                    <Carousel interval={1000}>
                        {testimonial.map((c, index) => {
                        return (
                            <Carousel.Item interval={5000}>
                                <div className="p-4">
                                    <div className="d-flex justify-content-center">
                                        <div className="col-md-7 text-center text-dark fact" style={{fontWeight: '400'}}>
                                            <i style={{fontFamily: 'Barcelony Signature'}}>"{c.content}"</i>
                                        </div>
                                    </div>
                                </div>
                            </Carousel.Item>
                        );
                        })}
                    </Carousel>
                </div>
            </div>
        </section>
    );
}

export default Facts;