import React, { useRef, useState } from 'react';
import emailjs from '@emailjs/browser';
import Helmet from 'react-helmet';

import ReCAPTCHA from "react-google-recaptcha";

const ContactForm = () => {
    const form = useRef();

    const sendEmail = (e) => {
        e.preventDefault();

        emailjs.sendForm('service_se9nbis', 'template_gep47uf', form.current, 'bXugWrokuHug3Z57X')
        .then((result) => {
            //   console.log(result.text);
            e.target.reset();
            alert('Your Request sended successfully to our team.');

            // using Java Script method to get PDF file
            fetch('/assets/The Ewen Park - Brochure.pdf').then(response => {
                response.blob().then(blob => {
                    // Creating new object of PDF file
                    const fileURL = window.URL.createObjectURL(blob);
                    // Setting various property values
                    let alink = document.createElement('a');
                    alink.href = fileURL;
                    alink.download = 'The Ewen Park - Brochure.pdf';
                    alink.click();
                })
            })

            }, (error) => {
                alert(error.text);
                // console.log(error.text);
        });
        alert('Please Wait...');
    };

    return(
        <>
        <div className="row m-0 align-items-center my-md-5">
                <div className="col-md-5">
                    <img src="/assets/images/contact-us.jpg" style={{width: '100%'}} />
                </div>
                <div className="col-md-7 p-md-4">
                    <div className="border rounded-3 p-3">
                        <h2 className="mb-4">Contact Us</h2>
                        <form ref={form} onSubmit={sendEmail} id="demo-form">
                            <label className="py-2">Username</label>
                            <input name="username" type="text" className="form-control mb-3" required />

                            <label className="py-2">Phone</label>
                            <input  name="phone" type="tel" className="form-control mb-3" required />

                            <label className="py-2">Email</label>
                            <input name="email" type="email" className="form-control mb-3" required />

                            <label className="py-2">Message</label>
                            <textarea name="message" className="form-control mb-3" rows={3}></textarea>

                            <ReCAPTCHA sitekey="6Ld5i_sgAAAAABBFPrR64C89-JyZKliGVHH7MHEP" />

                            <button className="btn btn-primary rounded-2 text-light shadow-1 text-capitalize mt-3" type="submit">Send & Download Brochure</button>
                        </form>
                    </div>
                </div>
            </div>
        </>
    );
}

export default ContactForm;