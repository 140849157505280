import { NavLink } from 'react-router-dom';
import AllServices from './AllServices';
import Helmet from 'react-helmet';

const PropertyLegalServices = () => {
    document.title = "Property Legal Services | Ewen Realtors - Best Real Estate in India";
    const ldJson = 
    {
        "@context" : "http://schema.org",
        "@type" : "Real Estate Business",
        "name" : "Ewen Realtors",
        "image" : "https://ewenrealtors.com/logo.svg",
        "telephone" : "+91 852-616-4444",
        "address" : {
          "@type" : "PostalAddress",
          "streetAddress" : "Ewen Realtors NH 109, Nainital Road, Kathgodam, Uttarakhand 263139"
        }
    }
    return( 
        <div className='text-start p-0 m-0'>
            <Helmet>
                <meta name="description" content="At Ewen Realtors, our experts also provide effective guidance and help to clients in the documentation process related to property deals. Being experienced real estate professionals, we ensure fairness and transparency in all our assignments. With the Property Legal Services provided by us, clients can have a hassle-free reality experience." />
                <link rel="canonical" href="https://ewenrealtors.com/services/property-legal-services" />
                <meta property="og:locale" content="en_US" />
                <meta property="og:type" content="website" />
                <meta property="og:title" content="Property Legal Services | Ewen Realtors - Best Real Estate in India" />
                <meta property="og:description" content="At Ewen Realtors, our experts also provide effective guidance and help to clients in the documentation process related to property deals. Being experienced real estate professionals, we ensure fairness and transparency in all our assignments. With the Property Legal Services provided by us, clients can have a hassle-free reality experience." />
                <meta property="og:url" content="https://ewenrealtors.com/services/property-legal-services" />
                <meta property="og:site_name" content="Ewen Realtors" />
                <meta property="article:publisher" content="https://www.facebook.com/ewenrealtors" />
                <meta property="article:modified_time" content="2022-03-23T06:20:43+00:00" />
                <meta property="og:image:width" content="800" />
                <meta property="og:image:height" content="450" />
                <meta property="og:image:type" content="image/jpeg" /> 
                
                {/* JSON-LD markup generated by Google Structured Data Markup Helper. */}
                <script type="application/ld+json">
                    {JSON.stringify(ldJson)}
                </script>
            </Helmet>
            <AllServices />
            <div className='my-4 container'>
                <h1 className="mb-4">Property Legal Services</h1>
                <img src="/assets/images/Property-Legal-Services.jpg" style={{width: '100%', borderRadius: '4px'}} />

                <p className='mt-4'>
                Ewen Realtors specializes in providing effective Property Legal Services to clients. The Property Legal Services provided by us include Property Documentation, Property Law Services, and Property Litigation. </p>

                <p>Our operation extends to the entire Uttarakhand region. Our legal experts are well versed with all property laws, legal rules and regulations regarding property deals, etc. they can effectively guide the clients in the right direction. With their help clients can ensure that their decisions regarding property buying and selling etc are in compliance with the legal laws.</p>

                <p>At  Ewen Realtors, our experts also provide effective guidance and help to clients in the documentation process related to property deals. Being experienced real estate professionals, we ensure fairness and transparency in all our assignments. With the Property Legal Services provided by us, clients can have a hassle-free reality experience.</p>

                <p>Real estate regulations regulate the buying and selling of real estate, including land and structures built on it. It also deals with legal issues related to items such as equipment and institutions related to assets and structures</p>

                <p>The legal property services provided by Ewen Realtors ensure that proper measures are taken while the acquisition or sale of the property. They may also be concerned about how a property adapts to a change in use. Acts, property taxes, estate planning, development control, and titles are all covered by Ewen Realtors&#8217; legal property service.</p>

                <p>Real estate can be residential or commercial. It can be held by one person but rented out to another.</p>

                <p>Land which can be bought and sold, and since it is so valuable, there are numerous local laws in place to ensure that real estate transactions are executed properly and documented.</p>

                <p>It can be passed down from one generation to the next through estate planning, or it can be held by multiple people.</p>

                <div className="my-4 mt-md-5">
                    <h2>Key Points To Remember Before Buying Property</h2>
                    <img src="/assets/images/advantages of real estate consultant.jpg" style={{width: '100%', borderRadius: '4px'}} className="my-3" />
                    <p className="mt-3">
                    Precautions you must take before buying any property to prevent fraud.&nbsp;</p>
                    <ol><li>Before signing any contract, go through all the documentation.</li></ol>
                    <ol start="2"><li>Look out for the land use permit.</li></ol>
                    <ol start="3"><li>Verify construction approval certificate before acquiring any property ( RERA).&nbsp;</li></ol>
                    <ol start="4"><li>Check the occupancy certificate.</li></ol>
                    <ol start="5"><li>Check all tax payment receipts before purchasing any property.</li></ol>
                </div>

                <div className="my-4 mt-md-5">
                    <h2>Laws You Should Know Before Buying Property</h2>
                    <img src="/assets/images/Laws You Should Know Before Buying Property.jpg" style={{width: '100%', borderRadius: '4px'}} className="my-3" />
                </div>

                <div className="my-4 mt-md-5">
                    <p>State and local governments have laws regarding the uses of land; for example, each plot of land must acquire in accordance with local zoning laws, and landowners may not harm the environment. Here are some laws mentioned below that you should know before buying any property.&nbsp;</p>

                    <ol><li><strong>The Transfer Of Property Act, 1882</strong></li></ol>

                    <p>The Transfer of Property Act 1882 is an Indian law that controls property transfers in India. It includes specific provisions defining what constitutes a transfer and the conditions that must be met. It come into effect on<strong> July 1, 1882</strong>.</p>

                    <p>The Act defines <strong>&#8220;transfer of property</strong>&#8221; as &#8220;any act by which a person transmits property to one or more persons, or to himself and one or more other persons.&#8221; The act of transmitting can take place now or in the future.</p>

                    <p>The person can be an individual, a company or an association, or a group of individuals, and any type of property, including immovable property, can be transferred.</p>

                    <p>Property should be transferred by a <strong>qualified person</strong>:</p>

                    <p>For a valid transfer, the party must be of stable mind, not intoxicated, a major, or not a person disqualified by law from signing a contract of transfer of property with another person.</p>

                    <p>There are 137 Sections in The Transfer Act, 1882</p>

                    <p>To know more about the Transfer Of Property Act, 1882, <a href="https://www.indiacode.nic.in/bitstream/123456789/2338/1/A1882-04.pdf" target="_blank" rel="noreferrer noopener nofollow">click here </a></p>

                    <ol start="2"><li><strong>The Indian Contract Act, 1872&nbsp;</strong></li></ol>

                    <p>The Indian Contract Act of 1872 establishes the legal framework that governs contracts in India. It applies to all Indian states from 2019, Jammu and Kashmir Reorganization Act. It come into effect on September 1, 1872, and it has 266 sections.</p>

                    <p>The Indian Contract Act of 1872 illustrates a contract as &#8220;<strong>a legally binding agreement</strong>.&#8221; An agreement is a two-party settlement that contains commitments or promises that both parties must fulfill. It refers to a contract when such an agreement is made legally enforceable.</p>

                    <p>To know more, visit <a href="https://legislative.gov.in/sites/default/files/A1872-09.pdf" target="_blank" rel="noreferrer noopener nofollow">legislative.gov.in.</a></p>

                    <ol start="3"><li><strong>The Indian Registration Act,1908&nbsp;</strong></li></ol>

                    <p>The Registration Act of 1908 was enacted to ensure the registration of documents and the preservation of all important information especially concerning transactions involving land or other immovable property. Having a document registered can increase the document&#8217;s integrity.</p>

                    <p>Learn more about <a href="https://www.indiacode.nic.in/bitstream/123456789/12230/1/the_registration_act%2C_1908.pdf" target="_blank" rel="noreferrer noopener nofollow">The Indian Registration Act, 1908</a>.</p>

                    <ol start="4"><li><strong>Real Estate Regulatory Authority ( RERA)&nbsp;</strong></li></ol>

                    <p>The Real Estate (Regulation and Development) Act of 2016 established the Real Estate Regulatory Authority to protect home buyers and enhance real estate investments.</p>

                    <p>RERA generally focuses on security, transparency, fairness, quality, and authorization to respect the transaction of real estate&nbsp;</p>

                    <p>Explore more about <a href="https://www.bankbazaar.com/home-loan/rera-act.html" target="_blank" rel="noreferrer noopener nofollow">Real Estate Regulatory Authority ( RERA)</a></p>

                    <p><strong>Services Provided By Ewen Realtors</strong></p>

                    <ol><li>Property verification: Before our client signs the contract, Ewen realtors thoroughly verify every document of the property.</li></ol>

                    <ol start="2"><li>Property registration: Ewen Realtors take the responsibilities of property registration (RERA) off from the client’s shoulders.&nbsp;</li></ol>

                    <ol start="3"><li>Cost friendly: Ewen Realtors provides the best service at the lowest possible price.</li></ol>

                    <h2>FAQ</h2>

                    <div class="wp-block-uagb-faq uagb-faq__outer-wrap uagb-block-b6e0601f uagb-faq-icon-row uagb-faq-layout-accordion uagb-faq-expand-first-false uagb-faq-inactive-other-true uagb-faq-equal-height" data-faqtoggle="true" role="tablist"><div class="uagb-faq__wrap uagb-buttons-layout-wrap">
                    <div class="wp-block-uagb-faq-child uagb-faq-child__outer-wrap uagb-block-25c2fc72"><div class="uagb-faq-child__wrapper"><div class="uagb-faq-item" role="tab" tabindex="0"><div class="uagb-faq-questions-button uagb-faq-questions"><span class="uagb-question">What properties come under The Transfer Of Property Act, 1882?</span></div><div class="uagb-faq-content"><span><p>Ans. The Transfer Of Property Act covers sale, mortgage, lease, exchange, and gifts. </p></span></div></div></div></div>

                    <div class="wp-block-uagb-faq-child uagb-faq-child__outer-wrap uagb-block-ab21a358"><div class="uagb-faq-child__wrapper"><div class="uagb-faq-item" role="tab" tabindex="0"><div class="uagb-faq-questions-button uagb-faq-questions"><span class="uagb-question">How many Sections are there in The Transfer Of Property Act, 1882?</span></div><div class="uagb-faq-content"><span><p>Ans. There are 137 Sections in The Transfer Of Property Act, 1882. </p></span></div></div></div></div>

                    <div class="wp-block-uagb-faq-child uagb-faq-child__outer-wrap uagb-block-d71c044d"><div class="uagb-faq-child__wrapper"><div class="uagb-faq-item" role="tab" tabindex="0"><div class="uagb-faq-questions-button uagb-faq-questions"><span class="uagb-question">How many Sections are there in The Indian Contract Act, 1872?</span></div><div class="uagb-faq-content"><span><p>Ans. There are 266 Sections in The Indian Contract Act, 1872.  </p></span></div></div></div></div>

                    <div class="wp-block-uagb-faq-child uagb-faq-child__outer-wrap uagb-block-d59718a0"><div class="uagb-faq-child__wrapper"><div class="uagb-faq-item" role="tab" tabindex="0"><div class="uagb-faq-questions-button uagb-faq-questions"><span class="uagb-question">When did The Indian Contract Act come into effect?</span></div><div class="uagb-faq-content"><span><p>Ans.  The Indian Contract Act came into effect on September 1, 1872 </p></span></div></div></div></div>

                    <div class="wp-block-uagb-faq-child uagb-faq-child__outer-wrap uagb-block-158f4221"><div class="uagb-faq-child__wrapper"><div class="uagb-faq-item" role="tab" tabindex="0"><div class="uagb-faq-questions-button uagb-faq-questions"><span class="uagb-question">What is the full form of The RERA Act?</span></div><div class="uagb-faq-content"><span><p>Ans. RERA stands for Real Estate Regulatory Authority </p></span></div></div></div></div>

                    <div class="wp-block-uagb-faq-child uagb-faq-child__outer-wrap uagb-block-045d7fe3"><div class="uagb-faq-child__wrapper"><div class="uagb-faq-item" role="tab" tabindex="0"><div class="uagb-faq-questions-button uagb-faq-questions"><span class="uagb-question"> What is The Transfer Of Property Act, 1882?</span></div><div class="uagb-faq-content"><span><p>Ans.  The Transfer of Property Act, 1882 is an Indian law that controls property transfers.  </p></span></div></div></div></div>

                    <div class="wp-block-uagb-faq-child uagb-faq-child__outer-wrap uagb-block-179a2fce"><div class="uagb-faq-child__wrapper"><div class="uagb-faq-item" role="tab" tabindex="0"><div class="uagb-faq-questions-button uagb-faq-questions"><span class="uagb-question">What is the main of The RERA Act?</span></div><div class="uagb-faq-content"><span><p>Ans. The main aim of the RERA Act is to focus on security, transparency, fairness, quality, and authorization to respect the transaction of real estate   </p></span></div></div></div></div>

                    <div class="wp-block-uagb-faq-child uagb-faq-child__outer-wrap uagb-block-df37e7e7"><div class="uagb-faq-child__wrapper"><div class="uagb-faq-item" role="tab" tabindex="0"><div class="uagb-faq-questions-button uagb-faq-questions"><span class="uagb-question">What does The Indian Contract Act, 1872 illustrate?</span></div><div class="uagb-faq-content"><span><p>Ans.  The Indian Contract Act of 1872 illustrates a contract as &#8220;<strong>a legally binding agreement</strong>.&#8221; An agreement is a two-party settlement that contains commitments or promises that both parties must fulfill. It refers to a contract when such an agreement is made legally enforceable. </p></span></div></div></div></div>

                    <div class="wp-block-uagb-faq-child uagb-faq-child__outer-wrap uagb-block-dc59451a"><div class="uagb-faq-child__wrapper"><div class="uagb-faq-item" role="tab" tabindex="0"><div class="uagb-faq-questions-button uagb-faq-questions"><span class="uagb-question">What is the Indian Registration Act, 1908?</span></div><div class="uagb-faq-content"><span><p>Ans.  The Registration Act of 1908 was enacted to ensure the registration of documents and the preservation of all important information especially concerning transactions involving land or other immovable property.  </p></span></div></div></div></div>

                    <div class="wp-block-uagb-faq-child uagb-faq-child__outer-wrap uagb-block-adc9263a"><div class="uagb-faq-child__wrapper"><div class="uagb-faq-item" role="tab" tabindex="0"><div class="uagb-faq-questions-button uagb-faq-questions"><span class="uagb-question">When does The Transfer Of Property Act, 1882 come into effect?</span></div><div class="uagb-faq-content"><span><p>Ans.  The Transfer Of Property Act came into effect on July 1, 1882.  </p></span></div></div></div></div>
                    </div></div></div>
            </div>
        </div>
    );
}

export default PropertyLegalServices;