import '../css/Partner.css';
import Helmet from 'react-helmet';
import { NavLink, Link } from 'react-router-dom';

const RefundPolicy = () => {
    document.title = "Refund Policy - Ewen Realtors";
    const ldJson = 
    {
        "@context" : "http://schema.org",
        "@type" : "Real Estate Business",
        "name" : "Ewen Realtors",
        "image" : "https://ewenrealtors.com/logo.svg",
        "telephone" : "+91 852-616-4444",
        "address" : {
          "@type" : "PostalAddress",
          "streetAddress" : "Ewen Realtors NH 109, Nainital Road, Kathgodam, Uttarakhand 263139"
        }
    }
    const faq = {
        "@context": "https://schema.org",
        "@type": "FAQPage",
        "mainEntity": [{
            "@type": "Question",
            "name": "Is pre-booking amount refundable?",
            "acceptedAnswer": {
                "@type": "Answer",
                "text": "The pre-booking amount is refundable. The company only accepts the refund request made within 7 days after booking."
            }
        },{
            "@type": "Question",
            "name": "Does the company charge any fee for refunding?",
            "acceptedAnswer": {
            "@type": "Answer",
                "text": "No, the company does not charge any fee for refunding. But if you have chosen the site-visit plan offered by the company then only the amount will be deducted if you request for refund."
            }
        },{
            "@type": "Question",
            "name": "Do we get a full refund of pre-booking amount?",
            "acceptedAnswer": {
                "@type": "Answer",
                "text": "If you have chosen an online payment method then Razorpay charges a 2% convenience fee to cover the costs involved in transferring the funds from the customer's account after a refund."
            }
        }]
    }
    return(
        <div className="text-start pt-5">
            <Helmet>
                <meta name="description" content="Return is a procedure provided by respective sellers directly under the guideline in terms of which the option of refund is offered by the respective sellers to you." />
                <link rel="canonical" href="https://ewenrealtors.com/refund-policy" />
                <meta property="og:locale" content="en_US" />
                <meta property="og:type" content="website" />
                <meta property="og:title" content="Refund Policy | Ewen Realtors - Best Real Estate in India" />
                <meta property="og:description" content="Return is a procedure provided by respective sellers directly under the guideline in terms of which the option of refund is offered by the respective sellers to you." />
                <meta property="og:url" content="https://ewenrealtors.com/refund-policy" />
                <meta property="og:site_name" content="Ewen Realtors" />
                <meta property="article:publisher" content="https://www.facebook.com/ewenrealtors" />
                <meta property="article:modified_time" content="2022-03-23T06:20:43+00:00" />
                <meta property="og:image:width" content="800" />
                <meta property="og:image:height" content="450" />
                <meta property="og:image:type" content="image/jpeg" /> 
                
                {/* JSON-LD markup generated by Google Structured Data Markup Helper. */}
                <script type="application/ld+json">
                    {JSON.stringify(ldJson)}
                </script>

                <script type="application/ld+json">
                    {JSON.stringify(faq)}
                </script>
            </Helmet>
            <div className='container col-md-6' style={{color: 'rgba(0,0,0,0.75)'}}>
                <h1 className='mb-4'>Refund Policy - Ewen Realtors</h1>
                
                <p>Return is a procedure provided by respective sellers directly under the guideline in terms of which the option of refund is offered by the respective sellers to you.</p>

                <p>Under different circumstances, you may not have the same refund policy. The refund policy provided shall prevail over the general refund policy.</p>

                <p>Do read all sections carefully to understand the requirements and cases under which refund will be accepted.</p>

                <p>As per our company policies, you can apply for a refund within 7 days after booking. Refunds of pre-booking amounts are only accepted within 15 days of booking by the company.</p>  

                <p>The whole booking amount will be refunded within 5-7 working days. But there are some cases and conditions when the amount can be deducted.</p> 

                <h2>CASE 1</h2>

                <p>If the visit is conducted by Ewen Realtors and after that, you request a refund then the company is eligible to deduct the amount spent on-site visit.</p> 

                <p><b>IMPORTANT NOTE:</b> The amount deduction on refund by the company is only applicable when you choose the company’s site visiting plan or any investment done by the company while the site visit.</p> 

                <p>Otherwise, the site visit plan is free if you buy didn't ask for a refund and bought the property from us.</p> 

                <h2>CASE 2</h2>

                <p>If you pre-book property from the website and choose an online method like UPI, Direct Bank Transfer, Debit Card, Credit Card, Net Banking, and Popular wallets (JioMoney, Mobikwik, Airtel Money, etc).</p>

                <p>And after paying the pre-booking amount you request a refund then 2% of the total amount will be deducted as a convenience fee by Razorpay.</p> 

                <p>Due to the costs associated with transferring the funds to a customer's account after a refund, Razorpay has to charge this fee.</p> 

                <div className='p-4 text-dark rounded-5 mb-3' style={{background: '#eee'}}>
                    <h2>How To Claim A Refund?</h2>

                    <p>To claim your refund you have to follow the following steps:</p>

                    <ul>
                        <li>You have to email us on <b>info@ewenrealtors.com</b></li>
                        <li>You have to <b>attach the invoice</b> of your pre-booking. </li>
                        <li>You have to mention a valid reason for claiming your refund.</li>
                        <li>After mailing us wait for the confirmation mail from us.</li>
                    </ul>

                    <p><b>NOTE: This confirmation mail contains all the necesassry details if your refund is proceeded with a deduction. Deduction is applicable in 2 cases mentioned above.</b></p>

                    <p>Your refund will be initiated in 5-7 working days after the confirmation mail.</p>
                </div>

                <h2>FAQs</h2>

                <ol>
                    <li>
                        <b>Is pre-booking amount refundable?</b>
                        <p>
                            The pre-booking amount is refundable. The company only accepts the refund request made within 7 days after booking. 
                        </p>
                    </li>
                    <li>
                        <b>Does the company charge any fee for refunding?</b>
                        <p>
                            No, the company does not charge any fee for refunding. But if you have chosen the site-visit plan offered by the company then only the amount will be deducted if you request for refund. 
                        </p>
                    </li>
                    <li>
                        <b>Do we get a full refund of pre-booking amount?</b>
                        <p>
                            If you have chosen an online payment method then Razorpay charges a 2% convenience fee to cover the costs involved in transferring the funds from the customer's account after a refund.
                        </p>
                    </li>
                </ol>
            </div>
        </div>
    );
}

export default RefundPolicy;