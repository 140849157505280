import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import Helmet from 'react-helmet';
import '../css/Gallery.css';

var listOfImages =[];

class Gallery extends Component{
    importAll(r) {
        return r.keys().map(r);
    }
    componentWillMount() {
        listOfImages = this.importAll(require.context('./gallery/', false, /\.(png|jpg|JPEG|JPG|jpeg|PNG|svg)$/));
    }
    
    render(){
        document.title = "Gallery & Media | Ewen Realtors - Best Real Estate in India";
        const ldJson = 
        {
            "@context" : "http://schema.org",
            "@type" : "Real Estate Business",
            "name" : "Ewen Realtors",
            "image" : "https://ewenrealtors.com/logo.svg",
            "telephone" : "+91 852-616-4444",
            "address" : {
            "@type" : "PostalAddress",
            "streetAddress" : "Ewen Realtors NH 109, Nainital Road, Kathgodam, Uttarakhand 263139"
            }
        }
        return(
            <>
                <div className='container text-start'>
                    <Helmet>
                        <meta name="description" content="Gallery & Media | Ewen Realtors - Best Real Estate in India" />
                        <link rel="canonical" href="https://ewenrealtors.com/media" />
                        <meta property="og:locale" content="en_US" />
                        <meta property="og:type" content="website" />
                        <meta property="og:title" content="Gallery & Media | Ewen Realtors - Best Real Estate in India" />
                        <meta property="og:description" content="Gallery & Media | Ewen Realtors - Best Real Estate in India" />
                        <meta property="og:url" content="https://ewenrealtors.com/media" />
                        <meta property="og:site_name" content="Ewen Realtors" />
                        <meta property="article:publisher" content="https://www.facebook.com/ewenrealtors" />
                        <meta property="article:modified_time" content="2022-03-23T06:20:43+00:00" />
                        <meta property="og:image:width" content="800" />
                        <meta property="og:image:height" content="450" />
                        <meta property="og:image:type" content="image/jpeg" /> 
                        
                        {/* JSON-LD markup generated by Google Structured Data Markup Helper. */}
                        <script type="application/ld+json">
                            {JSON.stringify(ldJson)}
                        </script>
                    </Helmet>
                    <h1 className='my-4'>Gallery & Media</h1>
                    <div className="gallery mb-5">
                        {
                                listOfImages.map(
                                (image, index) => 
                                    <img key={index} src={image} alt="info" className='imgGallery' />
                                )
                        }
                    </div>
                </div>
            </>
        )
    }
}

export default Gallery;