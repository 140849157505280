import '../css/Partner.css';
import Helmet from 'react-helmet';
import { NavLink, Link } from 'react-router-dom';

const Disclaimer = () => {
    document.title = "Disclaimer - Ewen Realtors";
    const ldJson = 
    {
        "@context" : "http://schema.org",
        "@type" : "Real Estate Business",
        "name" : "Ewen Realtors",
        "image" : "https://ewenrealtors.com/logo.svg",
        "telephone" : "+91 852-616-4444",
        "address" : {
          "@type" : "PostalAddress",
          "streetAddress" : "Ewen Realtors NH 109, Nainital Road, Kathgodam, Uttarakhand 263139"
        }
    }
    return(
        <div className="text-start pt-5">
            <Helmet>
                <meta name="description" content="The company has the right to add, modify, or delete data from the website at any time, and may re-write the terms at any time without prior notice." />
                <link rel="canonical" href="https://ewenrealtors.com/disclaimer" />
                <meta property="og:locale" content="en_US" />
                <meta property="og:type" content="website" />
                <meta property="og:title" content="Disclaimer | Ewen Realtors - Best Real Estate in India" />
                <meta property="og:description" content="The company has the right to add, modify, or delete data from the website at any time, and may re-write the terms at any time without prior notice." />
                <meta property="og:url" content="https://ewenrealtors.com/disclaimer" />
                <meta property="og:site_name" content="Ewen Realtors" />
                <meta property="article:publisher" content="https://www.facebook.com/ewenrealtors" />
                <meta property="article:modified_time" content="2022-03-23T06:20:43+00:00" />
                <meta property="og:image:width" content="800" />
                <meta property="og:image:height" content="450" />
                <meta property="og:image:type" content="image/jpeg" /> 
                
                {/* JSON-LD markup generated by Google Structured Data Markup Helper. */}
                <script type="application/ld+json">
                    {JSON.stringify(ldJson)}
                </script>
            </Helmet>
            <div className='container col-md-6 mb-5' style={{color: 'rgba(0,0,0,0.75)'}}>
                <h1 className='mb-4'>Disclaimer - Ewen Realtors</h1>
                <p>Thank you for visiting <Link to="/" style={{color: '#4169e1'}}>www.ewenrealtors.com</Link>,</p>

                <p>The website mentioned above is the only official website of Ewen Realtors “Company”. We caution and suggest that any information on other websites that appear similar to our official website or contain any details about us or our projects should not be relied upon.</p>

                <p>The company has the right to add, modify, or delete data from the website at any time, and may re-write the terms at any time without prior notice.</p> 

                <p>Any such modifications bind you, and the company suggests that you revisit this page on a regular basis to evaluate the existing terms.</p>

                <p>Viewers/Users accept that the information on this website, including brochures and branding, is provided solely for informational purposes.</p> 

                <p>They should not depend upon any information to make any bookings or purchases in any of the Company's projects.</p>

                <p>You are solely responsible for your use of the website. This website is purely for general guidance. It is not a part of a proposal or a contract.</p> 

                <p>The standards of design and quality are subject to change without notice. A digital version of the artist's rendering will appear as a reflection of the actual design, which is simply a digital image.</p> 

                <p>In the prevailing content on the website(s), specific project details, plots, cottages, studio apartments, areas, amenities, services, terms of sale, and other terms are based on authorized and sanctioned plans, which are subject to change in accordance with relevant regulations or as guided by authorities.</p>

                <p>If not specifically stated, actual photos, visual effects, renderings, and maps of the project at the development/construction stage are purely reflective, informative, and representational in nature, and are artist's impressions only.</p> 

                <p>Accordingly, the Company cannot accept bookings or allotments based on images, materials, stock photography, projections, details, or descriptions currently provided and/or displayed on the website.</p>

                <p>Neither the Company nor any of its affiliates will be responsible for any direct, indirect, special, or other indirect damages as a result of the use of this website or any other URL, including, without limitation, any lost profits, business disruptions, loss of software or other data on your system, or otherwise, even if we are explicitly informed of such damages.</p>
                <p>The Company takes no responsibility for any other Website that you may access via this one. When you access a non-Company Real estate website, even if it includes the Company logo.</p>

                <p>Please realize that it is totally irrelevant to the Company and also that the Company has no responsibility for the content on that Website.</p> 

                <p>Furthermore, a link to a non-Company Website does not imply that the Company actually supports or accepts responsibility for the information or use of such Website.</p> 

                <p>It is your duty to take precautions to make sure that anything you choose for your use is free of viruses, worms, trojan horses, and other highly dangerous items.</p>

                <p>Thank you for your time and patience.</p> 

            </div>
        </div>
    );
}

export default Disclaimer;