import React, { Component } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';
import { useState, useEffect } from "react";
import '../css/PostView.css';
import BlogSearch from '../layout/BlogSearch';
import Helmet from 'react-helmet';
import moment from 'moment';

class SitemapBlogs extends Component {
  constructor(props) {
    super(props);
    this.state = {
      posts: [],
    };
  }

  componentDidMount() {
    axios.get("https://old.ewenrealtors.com/wp-json/wp/v2/posts?_embed&per_page=100").then(posts => {
      this.setState({
        posts: posts.data
      });
    });
  }

  createMarkup(html) {
    return { __html: html };
  }

  render() {
    return (
      <>
            {
                this.state.posts.map(post => (
                    <div className='col-md-4 p-0'>
                        <li className='pt-2 text-primary'>
                            <Link to={`/blog/${post.slug}`} key={post.id}>
                                <font dangerouslySetInnerHTML={{__html: post.title.rendered}} />
                            </Link>
                        </li>
                        <div id="excerptToolTip">
                          <img alt="Ewen Realtors Blogs" src={post._embedded['wp:featuredmedia'][0].source_url} style={{width: '100%', borderRadius: '8px 8px 0px 0px'}} />
                          <div class="pt-1" id="excerptHolder" dangerouslySetInnerHTML={{__html: post.excerpt.rendered.substring(0,240)+'...&nbsp;Read more'}}/>
                        </div>
                    </div>
                ))
            }
            <div className='loader text-center'>
            <div class="spinner-border text-info" role="status">
              <span class="visually-hidden">Loading...</span>
            </div>
          </div>
      </>
    );
  }

}

export default SitemapBlogs;