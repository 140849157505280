import { NavLink } from 'react-router-dom';
import AllServices from './AllServices';
import Helmet from 'react-helmet';

const ConstructionService = () => {
    document.title = "Construction Services | Ewen Realtors - Best Real Estate in India";
    const ldJson = 
    {
        "@context" : "http://schema.org",
        "@type" : "Real Estate Business",
        "name" : "Ewen Realtors",
        "image" : "https://ewenrealtors.com/logo.svg",
        "telephone" : "+91 852-616-4444",
        "address" : {
          "@type" : "PostalAddress",
          "streetAddress" : "Ewen Realtors NH 109, Nainital Road, Kathgodam, Uttarakhand 263139"
        }
    }
    return( 
        <div className='text-start p-0 m-0'>
            <Helmet>
                <meta name="description" content="We have been providing construction services since 2011. Our experts bring a lot of knowledge and experience to the project. Our reputation as a premier construction service provider is built on the experience, trust, and transparency we have with our clients." />
                <link rel="canonical" href="https://ewenrealtors.com/services/construction-service" />
                <meta property="og:locale" content="en_US" />
                <meta property="og:type" content="website" />
                <meta property="og:title" content="Construction Services | Ewen Realtors - Best Real Estate in India" />
                <meta property="og:description" content="We have been providing construction services since 2011. Our experts bring a lot of knowledge and experience to the project. Our reputation as a premier construction service provider is built on the experience, trust, and transparency we have with our clients." />
                <meta property="og:url" content="https://ewenrealtors.com/services/construction-service" />
                <meta property="og:site_name" content="Ewen Realtors" />
                <meta property="article:publisher" content="https://www.facebook.com/ewenrealtors" />
                <meta property="article:modified_time" content="2022-03-23T06:20:43+00:00" />
                <meta property="og:image:width" content="800" />
                <meta property="og:image:height" content="450" />
                <meta property="og:image:type" content="image/jpeg" /> 
                
                {/* JSON-LD markup generated by Google Structured Data Markup Helper. */}
                <script type="application/ld+json">
                    {JSON.stringify(ldJson)}
                </script>
            </Helmet>
            <AllServices />
            <div className='my-4 container'>
                <h1 className="mb-4">Construction Services</h1>
                <img src="/assets/images/Construction-Services.jpg" style={{width: '100%', borderRadius: '4px'}} />

                <p className='mt-4'>
                Ewen Realtors is the leading construction service provider in Uttarakhand. The term construction service includes a wide range of tasks and responsibilities in the course of the construction process from design to completion.
                </p>
                <p>
                While building, there are various service specifications to consider. To make your life easy, we specialize in providing construction and management services. 
                </p>
                <p>
                A create our home once and so it is important to have an accurate and detailed project management plan. The ability to mobilize the right people in order to provide a one-of-a-kind construction solution.
                </p>
                <p>
                With a solid focus on adding value to revenue. Ability to use innovation to meet new challenges and stay on top Ewen Realtors work hard because we understand our clients. 
                </p>

                <div className="my-4 mt-md-5">
                    <h2>Trust And Transparency </h2>
                    <p className="mt-3">
                    As a leading agency and service provider in the real estate industry, we provide our clients with safe and successful management of construction projects. 
                    </p>
                    <p>
                    We have been providing construction services since 2011. Our experts bring a lot of knowledge and experience to the project. Our reputation as a premier construction service provider is built on the experience, trust, and transparency we have with our clients.  
                    </p>
                    <p>
                    From progressive and up-to-date construction technology to innovative modular off-site production to the latest discoveries in sustainable construction, we have been leaders over (decades of experience). 
                    </p>
                    <p>
                    We use the expertise gained from this wealth of experience to help our customers and partners build a lasting heritage. We set an example and work with our employees and partners to create value for our customers and the communities we serve. 
                    </p>
                </div>

                <div className="my-4 mt-md-5">
                    <h2>Why Choose Ewen Realtors?</h2>
                    <img src="/assets/images/services/construction-service-ewen-realtors.jpg" style={{width: '100%', borderRadius: '4px'}} className="my-3" />
                    <p className="mt-3">
                    Our standards of quality, safety, innovation, and technical know-how provide our customers and industrial partners with special security. In addition, the breadth and depth of our expertise, and our local knowledge of the markets in which we operate, and our close relationship with the supply chain, play an important role in our continued success. 
                    </p>

                    <h3>1. Knowledge And Experience </h3>
                    <p>
                        Our reputation as a leading construction service provider is based on our experience.  From progressive and up-to-date construction technology to innovative modular off-site production to the latest discoveries in sustainable construction, we have been leaders over (decades of experience). We use the expertise gained from this wealth of experience to help our customers and partners build a lasting heritage. 
                    </p>

                    <h3>2. Pampering Your House With Care And Renovation </h3>
                    <p>
                        Experience, as well as accurate planning and execution, are required for successful building maintenance and renovation projects.
                    </p>
                    <p>
                        Such projects are often complex due to the need to consider a number of factors, such as analysis, evaluation of maintenance types and scopes, proposed work plans, technical specification preparation, and bidding documents. 
                    </p>
                    <p>
                        You might need it. Quotation and assistance in selecting a contractor Such projects also require construction consulting services such as construction management, monitoring, and evaluation.
                    </p>

                    <h3>3. Earth Science And Soil Maintenance Test </h3>
                    <p>
                        Ewen Realtors has a strong background in soil mechanics and earth science. The company’s experts have worked on a variety of projects ranging from soil and bedrock research to the evaluation of mechanical properties. 
                    </p>
                    <p>
                        The foundations of buildings should be built on stable and solid soils. Well-structured soil is more stable and the roots of our house will be strong. 
                    </p>

                    <h3>4. Cost-Effective</h3>
                    <p>
                        Ewen Realtors specialists have many years of experience in project management and construction supervision and have been involved in major projects as well as professional development and planning projects.
                    </p>
                    <p>
                        Cost control is one of the provisions for successful construction, and comprehensive construction management guarantees a complete overview at all construction stages.
                    </p>

                    <h3>5. Safety Measures (Earthquake-Prone System)</h3>
                    <p>
                        Safety is one of the most important things while constructing a house, building, or anything the major concern in everyone’s life is to be safe. 
                    </p>
                    <p>
                        Ewen Realtors construction services plan and take all the safety measures before building a house.  They provide an earthquake-prone system. Ewen Realtors specialists have experience in implementing quality systems. 
                    </p>
                </div>

                <div className="my-4 mt-md-5">
                    <h2>Benefits Of Construction Management Services</h2>
                    <img src="/assets/images/services/benefits-of-construction-services.jpg" style={{width: '100%', borderRadius: '4px'}} className="my-3" />
                    <p className="mt-3">
                        There are many advantages and benefits of construction management services. Some are mentioned below:  
                    </p>
                    <ul>
                        <li>
                            <b>Using Quality Material And Equipment:</b> Using high-quality material and equipment is the prime target of Construction Management services. 
                        </li>
                        <li>
                            <b>On Schedule:</b> Construction management services will complete their work as per the time allotted by them. Your project will be right on schedule.
                        </li>
                        <li>
                            <b>Budget With Quality:</b> Construction Management services will help in providing the best quality materials in your budget. 
                        </li>
                        <li>
                            <b>Safety Measure:</b> Construction will be done with all the safety measures. 
                        </li>
                    </ul>
                </div>

                
                <h2 id="faq-s">FAQ’s</h2>



                <div class="wp-block-uagb-faq uagb-faq__outer-wrap uagb-block-4119097d uagb-faq-icon-row uagb-faq-layout-accordion uagb-faq-expand-first-false uagb-faq-inactive-other-true uagb-faq-equal-height" data-faqtoggle="true" role="tablist"><div class="uagb-faq__wrap uagb-buttons-layout-wrap">
                <div class="wp-block-uagb-faq-child uagb-faq-child__outer-wrap uagb-block-e5c571a5"><div class="uagb-faq-child__wrapper"><div class="uagb-faq-item" role="tab" tabindex="0"><div class="uagb-faq-questions-button uagb-faq-questions"><span class="uagb-icon-active uagb-faq-icon-wrap"><svg xmlns="http://www.w3.org/2000/svg" viewbox="0 0 448 512"><path d="M416 208H32c-17.67 0-32 14.33-32 32v32c0 17.67 14.33 32 32 32h384c17.67 0 32-14.33 32-32v-32c0-17.67-14.33-32-32-32z"></path></svg></span><span class="uagb-question">What is the main motive of construction services?</span></div><div class="uagb-faq-content"><span><p>The main motive of construction services is to provide a quality construction process within your budget.</p></span></div></div></div></div>



                <div class="wp-block-uagb-faq-child uagb-faq-child__outer-wrap uagb-block-e30fd001"><div class="uagb-faq-child__wrapper"><div class="uagb-faq-item" role="tab" tabindex="0"><div class="uagb-faq-questions-button uagb-faq-questions"><span class="uagb-question">Why choose Ewen Realtors for construction services?</span></div><div class="uagb-faq-content"><span><p>Ewen Realtors has vast knowledge and experience and is the leading construction service provider in Uttarakhand.</p></span></div></div></div></div>



                <div class="wp-block-uagb-faq-child uagb-faq-child__outer-wrap uagb-block-dcada2d6"><div class="uagb-faq-child__wrapper"><div class="uagb-faq-item" role="tab" tabindex="0"><div class="uagb-faq-questions-button uagb-faq-questions"><span class="uagb-question">What are the benefits of construction management services?</span></div><div class="uagb-faq-content"><span><p>The benefits of construction management services are they use quality material and equipment along with the budget allotted by the client, take all the safety measures, and complete the project on schedule. </p></span></div></div></div></div>
                </div></div>

            </div>
        </div>
    );
}

export default ConstructionService;