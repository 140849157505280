import React from 'react';
import Helmet from 'react-helmet';

const Financial = () => {
    document.title = "Financial - The Ewen Park | Ewen Realtors";
    const ldJson = 
    {
        "@context" : "http://schema.org",
        "@type" : "Real Estate Business",
        "name" : "Ewen Realtors",
        "image" : "https://ewenrealtors.com/logo.svg",
        "telephone" : "+91 852-616-4444",
        "address" : {
            "@type" : "PostalAddress",
            "streetAddress" : "Ewen Realtors NH 109, Nainital Road, Kathgodam, Uttarakhand 263139"
        }
    }
    return(
        <>
            <Helmet>
                <meta name="description" content="Financial - Ewen Realtors" />
                <link rel="canonical" href="https://ewenrealtors.com/financial" />
                <meta property="og:locale" content="en_US" />
                <meta property="og:type" content="website" />
                <meta property="og:title" content="Financial | Ewen Realtors - Best Real Estate in India" />
                <meta property="og:description" content="Financial - Ewen Realtors" />
                <meta property="og:url" content="https://ewenrealtors.com/financial" />
                <meta property="og:site_name" content="Ewen Realtors" />
                <meta property="article:publisher" content="https://www.facebook.com/ewenrealtors" />
                <meta property="article:modified_time" content="2024-02-07T06:20:43+00:00" />
                <meta property="og:image:width" content="800" />
                <meta property="og:image:height" content="450" />
                <meta property="og:image:type" content="image/jpeg" /> 
                
                {/* JSON-LD markup generated by Google Structured Data Markup Helper. */}
                <script type="application/ld+json">
                    {JSON.stringify(ldJson)}
                    </script>
            </Helmet>
            <div className='container py-5' style={{minHeight: '50vh'}}>
                <div className="row">
                    <div className='col-12 mb-3'>
                        <h1 className='text-start'>Investors</h1>
                    </div>
                    <div className='col-12 mb-3'>
                        <img src='/assets/financial/financial.jpg' className='w-100' style={{height:'50vh', objectFit:'cover'}} />
                    </div>
                    <div className='col-12'>
                        <p>Ewen Realtors, a burgeoning startup, boasting 11 years of real estate expertise under the esteemed JMB group, is dedicated to "Defining Dreams" for its clientele. With a solid decade of experience, we have firmly cemented our position as one of Uttarakhand's premier real estate firms.</p>
                        <p>Our ascent to the top stems from our profound understanding of client needs, offering a comprehensive suite of real estate services. From purchase to construction, paperwork to financing, we guide you through every step of the process.</p>
                        <p>In response to the burgeoning demand for mountain townships post-Covid-19, Ewen Realtors has expanded its footprint beyond the mountains, now venturing into the coveted Queen’s Meadow in Ranikhet.</p>
                        <p>In line with our commitment to excellence, we proudly present "The Ewen Park," our latest project boasting breathtaking views of the Himalayas. This township is meticulously designed to offer residents a holistic living experience, allowing them to revel in the splendor of nature.</p>
                    </div>
                    <div className='col-12 my-4'>
                        <h2 className='text-start'>COMPLIANCES</h2>
                    </div>

                    <div className='col-md-12'>
                        <div className='row m-0'>
                            <div className='col-md-2 border-md-end pe-4 border-2'>
                                <ul class="nav nav-pills mb-3 flex-column p-0" id="pills-tab" role="tablist">
                                    <li class="nav-item m-0 p-0" role="presentation">
                                        <button class="nav-link p-3 rounded-0 mb-1 w-100" id="pills-home-tab" data-bs-toggle="pill" data-bs-target="#pills-home" type="button" role="tab" aria-controls="pills-home" aria-selected="true">Annual Report</button>
                                    </li>
                                    <li class="nav-item m-0 p-0" role="presentation">
                                        <button class="nav-link p-3 rounded-0 mb-1 w-100" id="pills-profile-tab" data-bs-toggle="pill" data-bs-target="#pills-profile" type="button" role="tab" aria-controls="pills-profile" aria-selected="false">Annual Return</button>
                                    </li>
                                    <li class="nav-item m-0 p-0" role="presentation">
                                        <button class="nav-link p-3 rounded-0 mb-1 w-100 active" id="pills-contact-tab" data-bs-toggle="pill" data-bs-target="#pills-contact" type="button" role="tab" aria-controls="pills-contact" aria-selected="false">Financial Statements</button>
                                    </li>
                                </ul>
                            </div>
                            <div className='col-md-10 border-top border p-4'>
                                <div class="tab-content" id="pills-tabContent">
                                    <div class="tab-pane fade" id="pills-home" role="tabpanel" aria-labelledby="pills-home-tab" tabindex="0">...</div>
                                    <div class="tab-pane fade" id="pills-profile" role="tabpanel" aria-labelledby="pills-profile-tab" tabindex="0">...</div>
                                    <div class="tab-pane fade show active" id="pills-contact" role="tabpanel" aria-labelledby="pills-contact-tab" tabindex="0">
                                        <div className='row m-0'>
                                            <div className='col-md-4 ps-0'>
                                                <a href='/assets/financial/EWEN%20REALTORS%20PVT%20LTD%202022-23.pdf' target='_blank'>
                                                    <button className='btn btn-outline-success btn-sm rounded-0 w-100 mb-3 p-3'>
                                                        Financial Statements 2023-24
                                                    </button>
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    
                </div>
            </div>
        </>
    );
}

export default Financial