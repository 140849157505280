const ReachUs = () => {
    return (
        <>
            <div className="card p-3">
                <h3>How Can You Reach?</h3>
                <p className="m-0 py-2">You can reach the location of our project by different modes of transportation.</p>
                <ul>
                    <li><b>Airways:</b> Pantnagar airport is the nearest airport and Ranikhet is 109 kilometers from the airport. It will take 3hr 45mins to reach Ranikhet from Pantnagar.</li>
                    <li><b>Railways:</b> Ranikhet is 84 kilometers away from the nearby railway station Kathgodam, and it takes 2 hours and 40 minutes to get there.</li>
                    <li><b>Roadways:</b> Ranikhet is 380 kilometers from Delhi. One can take a cab or there is a direct bus and it takes 8hr 55 min to reach the destination.</li>
                </ul>
            </div>
        </>
    );
}

export default ReachUs;