import { NavLink } from 'react-router-dom';
const Navbar = () => {
    return(
        <>
            <div className='nav__bar p-0' style={{background: '#fff', overflowX: 'auto'}}>
                <div className='container col-md-6'>
                    <div className='navScroll p-0'>
                            <div>
                                <NavLink exact to="/" className="navLinks">
                                    <button className=''><i className="fas fa-home fa-lg"></i>Home</button>
                                </NavLink>
                            </div>
                            <div id="hideonMobile">
                                <NavLink to="/about-us" className="navLinks">
                                    <button className=''><i className="fas fa-users fa-lg"></i>About</button>
                                </NavLink>
                            </div>
                            <div>
                                <NavLink to="/services" className="navLinks">
                                    <button className=''><i className="fas fa-concierge-bell fa-lg"></i>services</button>
                                </NavLink>
                            </div>
                            <div>
                                <NavLink to="/projects" className="navLinks">
                                    <button className=''><i className="fas fa-project-diagram fa-lg"></i>projects</button>
                                </NavLink>
                            </div>
                            <div id="hideonMobile">
                                <NavLink to="/blog" className="navLinks">
                                    <button className=''><i className="fas fa-cubes fa-lg"></i>blog</button>
                                </NavLink>
                            </div>
                            <div id="hideonMobile">
                                <NavLink to="/media" className="navLinks">
                                    <button className=''><i className="fas fa-images fa-lg"></i>media</button>
                                </NavLink>
                            </div>
                            <div id="hideonMobile">
                                <NavLink to="/contact-us" className="navLinks">
                                    <button className=''><i className="fas fa-phone fa-lg"></i>contact</button>
                                </NavLink>
                            </div>
                            <div id="hideonMobile">
                                <NavLink to="/investors" className="navLinks">
                                    <button className=''><i className="fas fa-phone fa-lg"></i>investors</button>
                                </NavLink>
                            </div>
                            <div>
                                <NavLink to="/menu" className="navLinks">
                                    <button className=''><i className="fas fa-bars fa-lg"></i>More</button>
                                </NavLink>
                            </div>
                    </div>
                </div>
            </div>

            {/* Floating Button  */}
            <div className='p-0 m-0' id="mailFloatingBtn" style={{zIndex: '2000'}}>
                <a href="https://goo.gl/maps/tC4L8fvq3rcT5UWNA" target='_blank'>
                    <button className='btn btn-success btn-floating btn-lg text-capitalize p-0' title="Get Directions">
                        <i class="fas fa-directions m-0 fa-sm"></i>
                    </button>
                </a><br/>
                <a href="tel:+918526164444">
                    <button className='btn btn-primary btn-floating btn-lg text-capitalize p-0 mt-2' title="Call Us">
                        <i className='fas fa-phone-alt m-0 fa-sm'></i>
                    </button>
                </a><br/>
                <NavLink to="/contact-us">
                    <button className='btn btn-danger btn-floating btn-lg text-capitalize p-0 mt-2' title="Mail Us">
                        <i className='fas fa-envelope m-0 fa-sm'></i>
                    </button>
                </NavLink>
            </div>
        </>
    );
}

export default Navbar;