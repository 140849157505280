import ScrollToTop from 'react-router-scroll-top'
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import { BrowserRouter as Router, Redirect, Route, Switch } from 'react-router-dom';
import NotFound from './components/pages/NotFound';
import Header from './components/layout/Header';
import About from './components/pages/About';
import Footer from './components/layout/Footer';
import Services from './components/pages/Services';
import BuilderAndDeveloper from './components/pages/services/BuiderAndDeveloper';
import ConstructionService from './components/pages/services/ConstructionServices';
import Contact from './components/pages/Contact';
import Partner from './components/pages/Partner';
import Blog from './components/pages/Blog';
import PostView from './components/pages/PostView';
import Careers from './components/pages/Careers';
import RealEstateConsultant from './components/pages/services/RealEstateConsultant';
import PropertyLegalServices from './components/pages/services/PropertyLegalServices';
import HomeLoanServices from './components/pages/services/HomeLoanServices';
import VastuConsultant from './components/pages/services/VastuConsultant';
import InteriorDesigner from './components/pages/services/InteriorDesigner';
import Gallery from './components/pages/Gallery';
import Main from './components/pages/main';
import Disclaimer from './components/pages/Disclaimer';
import RefundPolicy from './components/pages/RefundPolicy';
import PrivacyPolicy from './components/pages/PrivacyPolicy';
import TnC from './components/pages/TnC';
import Faq from './components/pages/Faq';
import Sitemap from './components/pages/Sitemap';
import allMenuMobile from './components/layout/allMenuMobile';
import Projects from './components/pages/Projects';
import RoyalVillas from './components/pages/Projects/RoyalVillas';
import HimalayanVista from './components/pages/Projects/HimalayanVista';
import HillVillage from './components/pages/Projects/HillVillage';
import RoyalApartment from './components/pages/Projects/RoyalApartment';
import RiverValleyEstate from './components/pages/Projects/RiverValleyEstate';
import KasarHimalayan from './components/pages/Projects/Kasar';
import One from './components/LandingPage/One';
import Financial from './components/Financial/Financial';
import Farmhouse from './components/pages/Projects/Farmhouse';
import Tathastu from './components/pages/Projects/Tathastu';

function App() {
  return (
    <div className="App">
      <Router>
        <ScrollToTop>
          <Header />
          <Switch>
            <Route exact path="/" component={Main} />
            <Route exact path="/menu" component={allMenuMobile} />
            <Route exact path="/about-us" component={About} />
            <Route exact path="/about" component={About} />
            <Route exact path="/services" component={Services} />
            <Route exact path="/contact-us" component={Contact} />
            <Route exact path="/projects" component={Projects} />
            {/* <Route exact path="/partner" component={Partner} /> */}
            <Route exact path="/careers" component={Careers} />
            <Route exact path="/media" component={Gallery} />
            <Route exact path="/blog" component={Blog} />
            <Route exact path="/disclaimer" component={Disclaimer} />
            <Route exact path="/refund-policy" component={ RefundPolicy} />
            <Route exact path="/privacy-policy" component={ PrivacyPolicy} />
            <Route exact path="/terms-and-conditions" component={TnC} />
            <Route path='/blog/:slug' component={PostView} />
            <Route path='/faqs' component={Faq} />
            <Route path='/sitemap' component={Sitemap} />
            <Route path='/investors' component={Financial} />

            {/* Services  */}
            <Route exact path="/services/builder-and-developer" component={BuilderAndDeveloper} />
            <Route exact path="/services/construction-service" component={ConstructionService} />
            <Route exact path="/services/real-estate-consultant" component={RealEstateConsultant} />
            <Route exact path="/services/property-legal-services" component={PropertyLegalServices} />
            <Route exact path="/services/home-loan-services" component={HomeLoanServices} />
            <Route exact path="/services/vastu-consultant" component={VastuConsultant} />
            <Route exact path="/services/interior-designer" component={InteriorDesigner} />

            {/* Projects  */}
            <Route exact path="/projects/royal-villas" component={RoyalVillas} />
            <Route exact path="/projects/himalayan-vista" component={HimalayanVista} />
            <Route exact path="/projects/royal-apartments" component={RoyalApartment} />
            <Route exact path="/projects/river-vallery-estate" component={RiverValleyEstate} />
            <Route exact path="/projects/kasar-himalayan-chalet" component={KasarHimalayan} />
            <Route exact path="/projects/ewen-farms" component={Farmhouse} />
            <Route exact path="/projects/tathastu" component={Tathastu} />
            {/* <Route exact path="/projects/hill-village" component={HillVillage} /> */}

            {/* Landing Pages  */}
            <Route path='/best-real-estate-deals-in-india' component={One} />

            <Route component={NotFound} />
          </Switch>
          <Footer />
        </ScrollToTop>
      </Router>
    </div>
  );
}

export default App;
