import { NavLink } from 'react-router-dom';
import AllProjects from '../services/AllProjects';
import Helmet from 'react-helmet';
import ContactForm from '../ContactForm';
import CallToAction from './CallToAction';
import Amenities from './Amenities';
import ReachUs from './ReachUs';

const Tathastu = () => {
    document.title = "Ewen Farms - The Ewen Park | Ewen Realtors";
    const ldJson = 
    {
        "@context" : "http://schema.org",
        "@type" : "Real Estate Business",
        "name" : "Ewen Realtors",
        "image" : "https://ewenrealtors.com/logo.svg",
        "telephone" : "+91 852-616-4444",
        "address" : {
            "@type" : "PostalAddress",
            "streetAddress" : "Ewen Realtors NH 109, Nainital Road, Kathgodam, Uttarakhand 263139"
        }
    }

    return(
        <>
            <Helmet>
                {/* <meta name="description" content="Ewen Realtors presents Phase 2 of “The Ewen Park” i.e Dwarsu. It is a grand township near Baburkhola in Ranikhet. The place is immensely popular for its natural habitat and serene environment. The township which is being built in Dwarsu also has a wide range of vegetation which enhances the beauty of the township." /> */}
                <link rel="canonical" href="https://ewenrealtors.com/projects/ewen-farms" />
                <meta property="og:locale" content="en_US" />
                <meta property="og:type" content="website" />
                <meta property="og:title" content="Ewen Farms | Ewen Realtors - Best Real Estate in India" />
                <meta property="og:description" content="Ewen Realtors presents Phase 2 of “The Ewen Park” i.e Dwarsu. It is a grand township near Baburkhola in Ranikhet. The place is immensely popular for its natural habitat and serene environment. The township which is being built in Dwarsu also has a wide range of vegetation which enhances the beauty of the township." />
                <meta property="og:url" content="https://ewenrealtors.com/projects/ewen-farms" />
                <meta property="og:site_name" content="Ewen Realtors" />
                <meta property="article:publisher" content="https://www.facebook.com/ewenrealtors" />
                <meta property="article:modified_time" content="2022-03-23T06:20:43+00:00" />
                <meta property="og:image:width" content="800" />
                <meta property="og:image:height" content="450" />
                <meta property="og:image:type" content="image/jpeg" /> 
                
                {/* JSON-LD markup generated by Google Structured Data Markup Helper. */}
                <script type="application/ld+json">
                    {JSON.stringify(ldJson)}
                </script>
            </Helmet>
            
            <AllProjects/>
            <div className='my-3 container text-start'>
                <div className='row m-0 align-items-center my-md-5 flex-row-reverse'>
                    {/* <div className='col-md-5 text-center px-md-5'> */}
                        {/* <TathastuCarousel/> */}
                    {/* </div> */}
                    <div className='col-md-12'>
                        <h1 className='text-start projectHeading'>The Ewen Park: Tathastu</h1>
                        <p style={{fontWeight:'800'}}>Nature's blessings.</p>
                        <p className='text-secondary'>
                            Discover Thathastu, a peaceful haven tucked away in the scenic beauty of Majkhali. Surrounded by lush greenery and offering stunning views of the valley, it's the perfect escape from city life.
                        </p>
                        <p className='text-secondary'>
                            Thathastu is designed for nature lovers, providing all the comforts you need amidst the tranquility of nature. Whether you're taking leisurely walks through the greenery or simply enjoying the sounds of birds chirping, every moment here is about finding peace and relaxation.
                        </p>
                        <p className='text-secondary'>
                            So, come and experience the simplicity and beauty of Thathastu. It's where nature's embrace awaits, ready to rejuvenate your spirit and offer you a slice of serene living.
                        </p>
                    </div>
                </div>
                <Amenities/>
            </div>

            <CallToAction/>

            <div className='my-3 container text-start'>
                {/* Master Plan  */}
                <div className='py-4 mt-3'>
                    <h2 className='text-center pb-3'>Master Plan</h2>
                    <div class="row m-0 p-0 align-items-center">
                        <div class="nav flex-fill nav-pills me-3 col-md-3 d-flex justify-content-center p-0" id="v-pills-tab" role="tablist" aria-orientation="vertical">
			                <a href="https://drive.google.com/file/d/1QZKZ6_zpL3Z28dNAjLfiavVsNLEKCU9u/view?usp=sharing" target='_blank'>
                                <button class="nav-link" id="v-pills-messages-tab" data-bs-toggle="pill" type="button" role="tab" aria-controls="tab4" aria-selected="false">Layout</button>
                            </a>
			                <a href="https://drive.google.com/file/d/1SlN-ggjCn_YjaQkAtzG7XbRIQIInYtQ_/view?usp=sharing" target='_blank'>
                                <button class="nav-link" id="v-pills-messages-tab" data-bs-toggle="pill" type="button" role="tab" aria-controls="tab4" aria-selected="false">Payment Plan</button>
                            </a>
                        </div>
                    </div>
                </div>
                

                {/* Location Map  */}
                <div className='py-4'>
                    <h2 className='text-center mb-3'>Location Map</h2>
                    <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d5322.5055653498575!2d79.5444167!3d29.659527800000003!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x2eb07a8ff42a302!2zMjnCsDM5JzM0LjMiTiA3OcKwMzInMzkuOSJF!5e1!3m2!1sen!2sin!4v1667972462401!5m2!1sen!2sin"allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade" style={{width: '100%', height: '400px', borderRadius: '10px'}} className="shadow-sm"></iframe>
                </div>

                <ReachUs/>

                <ContactForm/>
            </div>

        </>
    );
}

export default Tathastu;