import React, { Component } from 'react';
import axios from 'axios';
import '../css/PostView.css';
import moment from 'moment';

import { Link, NavLink } from 'react-router-dom';
import RecentPosts from './RecentPosts';
import {Helmet} from "react-helmet";

import {
  FacebookShareButton,
  TwitterShareButton,
  WhatsappShareButton
} from "react-share";
import PostPageForm from './PostPageForm';

class PostView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      post: {},
      loading: true
    };
    this.createMarkup = this.createMarkup.bind();
  }

  componentDidMount() {
    const slug = this.props.match.params.slug;
    axios
      .get(`https://old.ewenrealtors.com/wp-json/wp/v2/posts?_embed&slug=${slug}`)
      .then(post => {
        this.setState({
          post: post.data[0],
          loading: false
        });
      });
  }

  createMarkup(html) {
    return { __html: html };
  }

  render() {
    let build;
    if (this.state.post.title) {
      const ldJson = 
      {
        "@context" : "http://schema.org",
        "@type" : "Article",
        "name" : this.state.post.title.rendered,
        "author" : {
          "@type" : "Organization",
          "name" : "Ewen Realtors"
        },
        "datePublished" : this.state.post.date,
        "dateModified" : this.state.post.modified_gmt,
        "image" : this.state.post.yoast_head_json.og_image[0].url,
        "articleSection" : ['Blog'], "inLanguage":"en-US",
        "description" : this.state.post.yoast_head_json.description,
        "url" : "https://ewenrealtors.com/blog/" + this.state.post.slug,
        "publisher" : {
          "@type" : "Organization",
          "name" : "Ewen Realtors"
        }
      }
      
      document.title = this.state.post.yoast_head_json.title;
      build = (
        <>
        <Helmet>
          <meta name='robots' content='index, follow, max-image-preview:large, max-snippet:-1, max-video-preview:-1' />
          <meta name="description" content={this.state.post.yoast_head_json.description} />
          <link rel="canonical" href={"https://ewenrealtors.com/blog/" + this.state.post.slug} />
          <meta property="og:locale" content="en_US" />
          <meta property="og:type" content="article" />
          <meta property="og:title" content={this.state.post.title.rendered + " - Ewen Realtors"} />
          <meta property="og:description" content={this.state.post.yoast_head_json.description} />
          <meta property="og:url" content={"https://ewenrealtors.com/blog/" + this.state.post.slug} />
          <meta property="og:site_name" content="Ewen Realtors" />
          <meta property="article:publisher" content="https://www.facebook.com/ewenrealtors" />
          <meta property="article:author" content="https://www.facebook.com/ewenrealtors" />
          <meta property="article:published_time" content={this.state.post.date} />
          <meta property="article:modified_time" content={this.state.post.modified_gmt} />
          <meta property="og:image" content={this.state.post.yoast_head_json.og_image[0].url} />
          <meta property="og:image:width" content="960" />
          <meta property="og:image:height" content="500" />
          <meta property="og:image:type" content="image/png" /> 
          <meta name="twitter:card" content={this.state.post.yoast_head_json.twitter_card} />
          <meta name="twitter:creator" content={this.state.post.yoast_head_json.twitter_creator} />
          <meta name="twitter:site" content={this.state.post.yoast_head_json.twitter_site} />
          <meta name="twitter:label1" content="Written by" />
          <meta name="twitter:data1" content="Ewen Realtors" />
          <meta name="twitter:label2" content="Est. reading time" />
          <meta name="twitter:data2" content="5 minutes" />
          {/* JSON-LD markup generated by Google Structured Data Markup Helper. */}
          <script type="application/ld+json">
            {JSON.stringify(ldJson)}
          </script>
        </Helmet>

        {/* Blog Content  */}
          <div className='my-3 ewen-breadcrumb container text-start col-md-10' style={{zIndex: '1000'}}>
            <Link to="/" className="badge badge-primary mx-1 text-success">Home</Link>
            <Link to="/blog" className="badge badge-warning mx-1 text-dark">Blog</Link>
            <Link to="#" dangerouslySetInnerHTML={{__html: this.state.post.title.rendered}} className="badge badge-primary mx-1 text-dark" />
          </div>
          <div className='container p-0 col-md-10 text-start'>
            <div className='post px-3'>
              <h1 dangerouslySetInnerHTML={{__html: this.state.post.title.rendered}} className="font-weight-bolder mb-3" />

                <div className="d-flex justify-content-between align-items-center mt-3">
                  <div style={{fontSize: '13px', color: 'rgba(0,0,0,0.8)'}} className="px-1">
                    <i className='fas fa-globe-asia'></i> {moment(this.state.post.date).format("MMM DD, YYYY")}
                  </div>
                  <div>
                    <img src='/logo.svg' alt="Ewen Realtors Logo" className='postUser' />
                  </div>
                </div>
              <img alt={this.state.post.title.rendered} src={this.state.post.yoast_head_json.og_image[0].url} className="featuredImage my-3" />

              <div
                dangerouslySetInnerHTML={this.createMarkup(
                  this.state.post.content.rendered
                )}
              />
            </div>
          </div>
        </>
      );
    } else {
      build = <div className='text-center my-5 container'><img src={"/assets/animations/Blog Dex.gif"} alt="Loading blog animation" style={{width: '100%'}} /></div>;
    }

    const rndInt = Math.floor(Math.random() * 5000) + 1;
    const newUrl = window.location.href;

    return (
      <div className='container p-0'> 
        <div className='row m-0'>
          <div className='col-md-8 p-0 mb-3'>{build}</div>
          <div className='col-md-4 text-start pt-md-4'>


            <div className='recentPosts p-md-3 text-start'>
              
              {/* Propmotional Banners  */}
              
              <div className='mb-4 p-0 mt-0'>
                  <PostPageForm />
              </div>

              {/* End of Propmotional Banners  */}

              {/* Social Media Share  */}
              <div className='d-flex align-items-center justify-content-between mb-3'>
                <div className=''>
                  <button className='btn btn-light btn-pill text-capitalize shadow-0' style={{cursor: 'default', background: '#eef', fontSize: '13px'}}>
                    <i className='far fa-eye fa-sm'></i> <font className='font-weight-bold'>{rndInt}</font> Views
                  </button>
                </div>

                <div>
                  <FacebookShareButton url={newUrl}>
                    <button className="btn btn-floating btn-sm shadow-0 p-0" style={{background: '#4267B2'}}>
                      <i className='fab fa-facebook-f text-light'></i>
                    </button>
                  </FacebookShareButton>
                  <TwitterShareButton url={newUrl}>
                    <button className="btn btn-floating btn-sm shadow-0 p-0 mx-1" style={{background: '#00acee'}}>
                      <i className='fab fa-twitter text-light'></i>
                    </button>
                  </TwitterShareButton>
                  <WhatsappShareButton url={newUrl}>
                    <button className="btn btn-floating btn-sm shadow-0 p-0" style={{background: '#25D366'}}>
                      <i className='fab fa-whatsapp text-light'></i>
                    </button>
                  </WhatsappShareButton>
                </div>
              </div>
              <h5 className='mb-3 font-weight-bolder'>Recent Posts</h5>
              <RecentPosts />
              <div className='text-start text-primary pb-2'>
                <Link to="/blog">View more</Link>
              </div>

            </div>
          </div>
        </div>
      </div>
    );
  }
} 

export default PostView;