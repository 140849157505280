import React from 'react'
import { NavLink, Link } from 'react-router-dom';
import Faq from '../pages/Faq';
import CallToAction from '../pages/Projects/CallToAction';

function One() {
    document.title = "Best REal Estate Deals In India - Ewen Realtors";
    const ldJson = 
    {
        "@context" : "http://schema.org",
        "@type" : "Real Estate Business",
        "name" : "Ewen Realtors",
        "image" : "https://ewenrealtors.com/logo.svg",
        "telephone" : "+91 852-616-4444",
        "address" : {
          "@type" : "PostalAddress",
          "streetAddress" : "Ewen Realtors NH 109, Nainital Road, Kathgodam, Uttarakhand 263139"
        }
    }
  return (
    <>
        {/* Hero  */}
        <div style={{backgroundImage: `url('/assets/landingpage/hero.jpeg')`, height: '75vh', backgroundSize: 'cover', backgroundPosition: 'top', backgroundColor: 'rgba(0,0,0,1)'}} className="d-flex align-items-center justify-content-center">
            <div className='container col-md-6'>
                <h1 className='text-light text-center mb-4'>Your <span className='text-dark'>Dream Home</span> Awaits in <span className='text-dark'>Paradise!</span></h1>
                <p className='text-light text-center' style={{fontWeight: '600'}}>Imagine waking up each morning to the melodious symphony of birds, surrounded by the majestic Himalayas, and indulging in the lifestyle you've always dreamed of.</p>
                <p className='text-light text-center' style={{fontWeight: '600'}}>Nestled in the heart of Uttarakhand “The Ewen Park” is a lavish township where every day feels like a dream come true.</p>
                <div className='mt-5'>
                    <a href="#amenitiesread">
                    <button className='btn btn-light rounded-pill px-4 btn-lg' style={{fontSize: '14px'}}> Read More</button>
                    </a>
                </div>
            </div>
        </div>

        {/* CTA  */}
        <CallToAction/>

        {/* Project Video  */}
        <div className='bg-light'>
            <div className='py-5 container'>
                <h2 className='mb-5 container col-md-8'>Immerse yourself in our world and see for yourself what awaits you in this paradise!</h2>
                <div>
                    <div className='container'>
                        <div className='p-0 m-0'>
                            <iframe src="https://www.youtube.com/embed/ydZEb-jL49c" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen style={{borderRadius: '20px !important', width:'100%', height: '500px'}}></iframe>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        {/* Projects  */}
        <div className='py-5 container'>
        <h2 className='mb-5'>Explore Our Exclusive Projects</h2>
        <div className='row m-0 align-items-center justify-content-center'>
                {/* Royal Villas  */}
                <div className='col-md-4 mb-3'>
                    <div className='card bg-dark rounded-5 border-0 p-0'>
                        <img src="/assets/images/royal-apartments.jpg" style={{borderRadius: '8px 8px 0px 0px', height: '230px', objectFit: 'cover', objectPosition:'top'}} />
                        <div className='d-flex justify-content-center'>
                            <div className='p-3 col-md-11' style={{fontFamily: 'roboto'}}>
                                <div className='text-center'>
                                    <img src="/assets/images/PREMIUM.svg" style={{height:'18px'}} />
                                    <p className='text-uppercase text-center' style={{color: 'gold', fontFamily: 'roboto', letterSpacing: '-0.25px', fontSize: '18px', fontWeight: '700'}}>Royal Villas & Apartments</p>
                                </div>
                                {/* <h3 className='py-2 font-weight-bolder'>₹ 37,00,000*</h3> */}
                                <div className='d-flex mt-2'>
                                    <div className=''>
                                        <i className='fas fa-check-circle text-warning fa-sm' style={{position: 'relative', top: '-3px'}}></i>
                                    </div>
                                    <div className='px-2' style={{fontSize: '13px', color: 'rgba(255,255,255,0.8)'}}>On Site Maintenance</div>
                                </div>

                                <div className='d-flex'>
                                    <div className=''>
                                        <i className='fas fa-check-circle text-warning fa-sm' style={{position: 'relative', top: '-3px'}}></i>
                                    </div>
                                    <div className='px-2' style={{fontSize: '13px', color: 'rgba(255,255,255,0.8)'}}>24/7 Water</div>
                                </div>
                                <div className='d-flex'>
                                    <div className=''>
                                        <i className='fas fa-check-circle text-warning fa-sm' style={{position: 'relative', top: '-3px'}}></i>
                                    </div>
                                    <div className='px-2' style={{fontSize: '13px', color: 'rgba(255,255,255,0.8)'}}>24/7 Electricity</div>
                                </div>
                                <div className='d-flex'>
                                    <div className=''>
                                        <i className='fas fa-check-circle text-warning fa-sm' style={{position: 'relative', top: '-3px'}}></i>
                                    </div>
                                    <div className='px-2' style={{fontSize: '13px', color: 'rgba(255,255,255,0.8)'}}>Wi-Fi & Mobile Network</div>
                                </div>
                                <div className='d-flex'>
                                    <div className=''>
                                        <i className='fas fa-check-circle text-warning fa-sm' style={{position: 'relative', top: '-3px'}}></i>
                                    </div>
                                    <div className='px-2' style={{fontSize: '13px', color: 'rgba(255,255,255,0.8)'}}>Entry – UHF Based</div>
                                </div>
                                <div className='d-flex'>
                                    <div className=''>
                                        <i className='fas fa-check-circle text-warning fa-sm' style={{position: 'relative', top: '-3px'}}></i>
                                    </div>
                                    <div className='px-2' style={{fontSize: '13px', color: 'rgba(255,255,255,0.8)'}}>Security (CCTV, Guards, etc.)</div>
                                </div>

                                <div className='d-flex'>
                                    <div className=''>
                                        <i className='fas fa-check-circle text-warning fa-sm' style={{position: 'relative', top: '-3px'}}></i>
                                    </div>
                                    <div className='px-2' style={{fontSize: '13px', color: 'rgba(255,255,255,0.8)'}}>Himalayan View</div>
                                </div>
                                <div className='text-center text-light py-3'>
                                    {/* <div className='text-center text-primary pb-3' style={{fontSize: '24px', fontWeight: '600'}}>
                                        <sup>₹</sup>25,00,000<sup>*</sup>
                                    </div> */}
                                    <Link to="/projects/royal-villas">
                                        <button className='btn btn-warning rounded-pill shadow-none text-capitalize' style={{fontWeight: '500', padding: '6px 50px'}}>Learn More</button>
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* Royal Avenue  */}
                <div className='col-md-4 mb-3'>
                    <div className='card bg-dark rounded-5 border-0 p-0'>
                        <img src="/assets/images/projects/The Ewen Park - Himalayan Vista.jpg" style={{borderRadius: '8px 8px 0px 0px', height: '230px', objectFit: 'cover'}} />
                        <div className='d-flex justify-content-center'>
                            <div className='p-3 col-md-11' style={{fontFamily: 'roboto'}}>
                                <div className='text-center'>
                                    <img src="/assets/images/PREMIUM.svg" style={{height:'18px'}} />
                                    <p className='text-uppercase text-center' style={{color: 'gold', fontFamily: 'roboto', letterSpacing: '-0.25px', fontSize: '18px', fontWeight: '700'}}>The Himalayan Vista</p>
                                </div>
                                {/* <h3 className='py-2 font-weight-bolder'>₹ 30,00,000*</h3> */}
                                <div className='d-flex mt-2'>
                                    <div className=''>
                                        <i className='fas fa-check-circle text-warning fa-sm' style={{position: 'relative', top: '-3px'}}></i>
                                    </div>
                                    <div className='px-2' style={{fontSize: '13px', color: 'rgba(255,255,255,0.8)'}}>On Site Maintenance</div>
                                </div>
                                <div className='d-flex'>
                                    <div className=''>
                                        <i className='fas fa-check-circle text-warning fa-sm' style={{position: 'relative', top: '-3px'}}></i>
                                    </div>
                                    <div className='px-2' style={{fontSize: '13px', color: 'rgba(255,255,255,0.8)'}}>Gym & Spa</div>
                                </div>
                                <div className='d-flex'>
                                    <div className=''>
                                        <i className='fas fa-check-circle text-warning fa-sm' style={{position: 'relative', top: '-3px'}}></i>
                                    </div>
                                    <div className='px-2' style={{fontSize: '13px', color: 'rgba(255,255,255,0.8)'}}>24/7 Water & Electricity</div>
                                </div>
                                <div className='d-flex'>
                                    <div className=''>
                                        <i className='fas fa-check-circle text-warning fa-sm' style={{position: 'relative', top: '-3px'}}></i>
                                    </div>
                                    <div className='px-2' style={{fontSize: '13px', color: 'rgba(255,255,255,0.8)'}}>Wi-Fi & Mobile Network</div>
                                </div>
                                <div className='d-flex'>
                                    <div className=''>
                                        <i className='fas fa-check-circle text-warning fa-sm' style={{position: 'relative', top: '-3px'}}></i>
                                    </div>
                                    <div className='px-2' style={{fontSize: '13px', color: 'rgba(255,255,255,0.8)'}}>Entry – UHF Based, Security (CCTV, Guards, etc.)</div>
                                </div>
                                <div className='d-flex'>
                                    <div className=''>
                                        <i className='fas fa-check-circle text-warning fa-sm' style={{position: 'relative', top: '-3px'}}></i>
                                    </div>
                                    <div className='px-2 text-start' style={{fontSize: '13px', color: 'rgba(255,255,255,0.8)'}}>Yoga, Sports, Club House For Senior citizens, etc.</div>
                                </div>
                                <div className='d-flex'>
                                    <div className=''>
                                        <i className='fas fa-check-circle text-warning fa-sm' style={{position: 'relative', top: '-3px'}}></i>
                                    </div>
                                    <div className='px-2' style={{fontSize: '13px', color: 'rgba(255,255,255,0.8)'}}>Himalayan View</div>
                                </div>
                                <div className='text-center text-light py-3'>
                                    {/* <div className='text-center text-primary pb-3' style={{fontSize: '24px', fontWeight: '600'}}>
                                        <sup>₹</sup>28,00,000<sup>*</sup>
                                    </div> */}
                                    <Link to="/projects/himalayan-vista">
                                        <button className='btn btn-warning rounded-pill shadow-none text-capitalize' style={{fontWeight: '500', padding: '6px 50px'}}>Learn More</button>
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* Kasar Himalayan Chalet  */}
                <div className='col-md-4 mb-3'>
                    <div className='card bg-dark rounded-5 border-0 p-0'>
                        <img src="/assets/images/projects/kasar.jpg" style={{borderRadius: '8px 8px 0px 0px', height: '230px', objectFit: 'cover'}} />
                        <div className='d-flex justify-content-center'>
                            <div className='p-3 col-md-11' style={{fontFamily: 'roboto'}}>
                                <div className='text-center'>
                                    <img src="/assets/images/PREMIUM.svg" style={{height:'18px'}} />
                                    <p className='text-uppercase text-center' style={{color: 'gold', fontFamily: 'roboto', letterSpacing: '-0.25px', fontSize: '18px', fontWeight: '700'}}>Kasar Himalayan Chalet</p>
                                </div>
                                {/* <h3 className='py-2 font-weight-bolder'>₹ 30,00,000*</h3> */}
                                <div className='d-flex mt-2'>
                                    <div className=''>
                                        <i className='fas fa-check-circle text-warning fa-sm' style={{position: 'relative', top: '-3px'}}></i>
                                    </div>
                                    <div className='px-2' style={{fontSize: '13px', color: 'rgba(255,255,255,0.8)'}}>On Site Maintenance</div>
                                </div>
                                <div className='d-flex'>
                                    <div className=''>
                                        <i className='fas fa-check-circle text-warning fa-sm' style={{position: 'relative', top: '-3px'}}></i>
                                    </div>
                                    <div className='px-2' style={{fontSize: '13px', color: 'rgba(255,255,255,0.8)'}}>Gym & Spa</div>
                                </div>
                                <div className='d-flex'>
                                    <div className=''>
                                        <i className='fas fa-check-circle text-warning fa-sm' style={{position: 'relative', top: '-3px'}}></i>
                                    </div>
                                    <div className='px-2' style={{fontSize: '13px', color: 'rgba(255,255,255,0.8)'}}>24/7 Water & Electricity</div>
                                </div>
                                <div className='d-flex'>
                                    <div className=''>
                                        <i className='fas fa-check-circle text-warning fa-sm' style={{position: 'relative', top: '-3px'}}></i>
                                    </div>
                                    <div className='px-2' style={{fontSize: '13px', color: 'rgba(255,255,255,0.8)'}}>Wi-Fi & Mobile Network</div>
                                </div>
                                <div className='d-flex'>
                                    <div className=''>
                                        <i className='fas fa-check-circle text-warning fa-sm' style={{position: 'relative', top: '-3px'}}></i>
                                    </div>
                                    <div className='px-2' style={{fontSize: '13px', color: 'rgba(255,255,255,0.8)'}}>Entry – UHF Based, Security (CCTV, Guards, etc.)</div>
                                </div>
                                <div className='d-flex'>
                                    <div className=''>
                                        <i className='fas fa-check-circle text-warning fa-sm' style={{position: 'relative', top: '-3px'}}></i>
                                    </div>
                                    <div className='px-2 text-start' style={{fontSize: '13px', color: 'rgba(255,255,255,0.8)'}}>Yoga, Sports, Club House For Senior citizens, etc.</div>
                                </div>
                                <div className='d-flex'>
                                    <div className=''>
                                        <i className='fas fa-check-circle text-warning fa-sm' style={{position: 'relative', top: '-3px'}}></i>
                                    </div>
                                    <div className='px-2' style={{fontSize: '13px', color: 'rgba(255,255,255,0.8)'}}>Himalayan View</div>
                                </div>
                                <div className='text-center text-light py-3'>
                                    {/* <div className='text-center text-primary pb-3' style={{fontSize: '24px', fontWeight: '600'}}>
                                        <sup>₹</sup>28,00,000<sup>*</sup>
                                    </div> */}
                                    <Link to="/projects/kasar-himalayan-chalet">
                                        <button className='btn btn-warning rounded-pill shadow-none text-capitalize' style={{fontWeight: '500', padding: '6px 50px'}}>Learn More</button>
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        {/* Amenities  */}
        <div className='bg-light' id='amenitiesread'>
            <div className='py-5 container col-md-8'>
                <div className='py-2 mb-0'>
                    <h2 className='text-center pb-4'>Amenities</h2>
                    <p className='text-center'>“The Ewen Park” offers luxurious city-like amenities while providing you with the opportunity to live close to nature and witness the breathtaking views of the majestic Himalayan range.</p>
                    <div className='container col-md-12 pt-4'>
                        <img src='/assets/landingpage/Amenities.png' style={{width: '100%'}} />
                    </div>
                </div>
            </div>
        </div>

        {/* Testimonials  */}
        <div>
            <div className='py-5 container'>
                <h2>Don't just take our word for it!</h2>
                <p className='text-center'>Hear from our satisfied clients who have experienced the magic of “The Ewen Park”</p>
                <div className='row m-0 pt-4'>
                    <div className='col-md-3 mb-3'>
                        <div className='card p-3'>
                            <blockquote className='text-start'>Extremely hospitable team. Had a wonderful experience. If transparency and trust are the traits which you are looking for, Ewen Realtors is all you need.</blockquote>
                            <div className='text-start' style={{fontWeight:'700'}}>Manisha Gururani</div>
                        </div>
                    </div>
                    <div className='col-md-3 mb-3'>
                        <div className='card p-3'>
                            <blockquote className='text-start'>My experience with Ewen Realtors has been really good. They have a very diligent team which walks you through every minute detail.</blockquote>
                            <div className='text-start' style={{fontWeight:'700'}}>Anju Gururani</div>
                        </div>
                    </div>
                    <div className='col-md-3 mb-3'>
                        <div className='card p-3'>
                            <blockquote className='text-start'>They are developing real estate at Ranikhet. The staff is courteous. Yet to visit their site , but the firm appears genuine.</blockquote>
                            <div className='text-start' style={{fontWeight:'700'}}>Manish Khanna</div>
                        </div>
                    </div>
                    <div className='col-md-3 mb-3'>
                        <div className='card p-3'>
                            <blockquote className='text-start'>They have great Himalayan-view projects near Majkhali, Ranikhet. One of its kind project with all modern gated community amenities in Uttarakhand.</blockquote>
                            <div className='text-start' style={{fontWeight:'700'}}>Jyothish Jayan</div>
                        </div>
                    </div>
                    <div className='col-md-12'>
                        <a href="https://www.google.com/search?gs_ssp=eJzj4tVP1zc0LLQoN8ouKk4yYLRSNagwtkw0sEwyMk0yNjUzMzO1tDKoSEw1M01LSTU2SjJJNUtNMvfiTS1PzVMoSk3MKckvKgYAqckUvA&q=ewen+realtors&rlz=1C5CHFA_enIN1038IN1039&oq=ewen+rea&gs_lcrp=EgZjaHJvbWUqDQgBEC4YrwEYxwEYgAQyBggAEEUYOTINCAEQLhivARjHARiABDIHCAIQABiABDINCAMQLhivARjHARiABDIHCAQQABiABDIGCAUQRRg9MgYIBhBFGDwyBggHEEUYPdIBCDQ4MTVqMGo3qAIAsAIA&sourceid=chrome&ie=UTF-8#lrd=0x39a09b25b3566659:0xae65fde32b4e6eb7,1,,,," target="_blank" className='btn btn-link text-capitalize'>Read All Reviews</a>
                    </div>
                </div>
            </div>
        </div>

        {/* CTA  */}
        <CallToAction/>

        {/* FAQ  */}
        <div>
            <div className='py-5 container text-start'>
                <h2 className='text-start mb-4'>FAQs</h2>
                <div className="my-3 card">
                    <h2 className="accordion-header" id="headingOne">
                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="false" aria-controls="collapseOne">
                        Who is Ewen Realtors? 
                        </button>
                    </h2>
                    <div id="collapseOne" className="accordion-collapse collapse" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                        <div className="accordion-body">
                        Ewen Realtors is an emerging real estate company in Uttarakhand. It is a startup in Uttarakhand with a young, passionate team working professionally to make your real estate journey worthwhile.
                        </div>
                    </div>
                </div>

                <div className="my-3 card">
                    <h2 className="accordion-header" id="headingTwo">
                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                        Where are the headquarters of Ewen Realtors?
                        </button>
                    </h2>
                    <div id="collapseTwo" className="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#accordionExample">
                        <div className="accordion-body">
                        Ewen Realtors headquarters is in Kathgodam, Haldwani Uttarakhand. 
                        </div>
                    </div>
                </div>

                <div className="my-3 card">
                    <h2 className="accordion-header" id="headingThree">
                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                        What does Ewen Realtors working on?
                        </button>
                    </h2>
                    <div id="collapseThree" className="accordion-collapse collapse" aria-labelledby="headingThree" data-bs-parent="#accordionExample">
                        <div className="accordion-body">
                        Ewen Realtors is working on a project “The Ewen Park” in Ranikhet, Uttarakhand which is popularly known as Queen’s Meadow. It is at an elevation of 6132ft above sea level.  
                        </div>
                    </div>
                </div>

                <div className="my-3 card">
                    <h2 className="accordion-header" id="headingFour">
                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour">
                        Where is Ranikhet?
                        </button>
                    </h2>
                    <div id="collapseFour" className="accordion-collapse collapse" aria-labelledby="headingFour" data-bs-parent="#accordionExample">
                        <div className="accordion-body">
                        Ranikhet is in the Almora district in Uttarakhand. It is 1869 meters above sea level and experiences a comforting climate throughout the year.  
                        </div>
                    </div>
                </div>

                <div className="my-3 card">
                    <h2 className="accordion-header" id="headingFive">
                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFive" aria-expanded="false" aria-controls="collapseFive">
                        What is “The Ewen Park”?
                        </button>
                    </h2>
                    <div id="collapseFive" className="accordion-collapse collapse" aria-labelledby="headingFive" data-bs-parent="#accordionExample">
                        <div className="accordion-body">
                        “The Ewen Park” is the name of the township being built in Ranikhet, Uttarakhand by Ewen Realtors. There are different phases of “The Ewen Park”.  
                        </div>
                    </div>
                </div>

                <div className="my-3 card">
                    <h2 className="accordion-header" id="headingSix">
                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseSix" aria-expanded="false" aria-controls="collapseSix">
                        How many phases are there in “The Ewen Park”?
                        </button>
                    </h2>
                    <div id="collapseSix" className="accordion-collapse collapse" aria-labelledby="headingSix" data-bs-parent="#accordionExample">
                        <div className="accordion-body">
                        There are three different phases in “The Ewen Park”. The first phase is “The Ewen Park”- Royal Villas which is in Baburkhola, the Second phase is named “The Ewen Park” - Royal Avenue in Dwarsun, and the third is known as “The Ewen Park” - Hill Village which is in Galli Basuyra.  
                        </div>
                    </div>
                </div>

                <div className="my-3 card">
                    <h2 className="accordion-header" id="headingSeven">
                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseSeven" aria-expanded="false" aria-controls="collapseSeven">
                        What makes “The Ewen Park” different from other townships in the mountains?
                        </button>
                    </h2>
                    <div id="collapseSeven" className="accordion-collapse collapse" aria-labelledby="headingSeven" data-bs-parent="#accordionExample">
                        <div className="accordion-body">
                        “The Ewen Park” has many breathtaking views including views of the Himalayan peaks like Nanda Devi, Trishul Complex, etc, stunning views of sunrise and sunsetting in the snowcapped Himalayas, Almora glittering and shimmering at night, and so on. 
                        </div>
                    </div>
                </div>

                <div className="my-3 card">
                    <h2 className="accordion-header" id="headingEight">
                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseEight" aria-expanded="false" aria-controls="collapseEight">
                        What amenities do Ewen Realtors are offering in “The Ewen Park”?
                        </button>
                    </h2>
                    <div id="collapseEight" className="accordion-collapse collapse" aria-labelledby="headingEight" data-bs-parent="#accordionExample">
                        <div className="accordion-body">
                        “The Ewen Park” is offering all the amenities ranging from basic to top-notch amenities to make your life easier and more comforting while living in the mountains. 
                        </div>
                    </div>
                </div>

                <div className="my-3 card">
                    <h2 className="accordion-header" id="headingNine">
                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseNine" aria-expanded="false" aria-controls="collapseNine">
                        What amenities are furnished in “The Ewen Park”?
                        </button>
                    </h2>
                    <div id="collapseNine" className="accordion-collapse collapse" aria-labelledby="headingNine" data-bs-parent="#accordionExample">
                        <div className="accordion-body">
                        The amenities which are furnished in the township are 24/7 electricity and water supply, parking space, 24/7 cafe, and dining service, gym, spa, on-site maintenance, UHF-based entry, wi-fi and mobile network, and so on. 
                        </div>
                    </div>
                </div>

                <div className="my-3 card">
                    <h2 className="accordion-header" id="headingTen">
                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTen" aria-expanded="false" aria-controls="collapseTen">
                        Is the project “The Ewen Park” RERA approved?
                        </button>
                    </h2>
                    <div id="collapseTen" className="accordion-collapse collapse" aria-labelledby="headingTen" data-bs-parent="#accordionExample">
                        <div className="accordion-body">
                        Phase 1 (Baburkhola)  and phase 3 (Galli Basuyra) are LDA (Local development authority) approved whereas phase 2 (Dwarsun) is going for RERA.
                        </div>
                    </div>
                </div>

                <div className="my-3 card">
                    <h2 className="accordion-header" id="headingEleven">
                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseEleven" aria-expanded="false" aria-controls="collapseEleven">
                        What are the features of “The Ewen Park” township?
                        </button>
                    </h2>
                    <div id="collapseEleven" className="accordion-collapse collapse" aria-labelledby="headingEleven" data-bs-parent="#accordionExample">
                        <div className="accordion-body">
                        “The Ewen Park” has many breathtaking views, a serene environment, a delightful climate, top-notch amenities, a natural water source, and so on. This township offers comfort and convenience for you and your family. 
                        </div>
                    </div>
                </div>
            </div>
        </div>

        {/* About  */}
        <div className='bg-light'>
            <div className='container py-5'>
                <h1 className="mb-4 text-start">About Us</h1>
                <p>Ewen Realtors is a new startup with an aim of “Defining Dreams” with 11 years of experience in real estate with the JMB group. The company is firmly established as one of the best emerging real estate companies in Uttarakhand with a decade of experience. </p>

                <p>The reason for being and emerging as the best is that we understand our clients in every aspect by providing a wide array of real estate services. Also, we will walk you through every stage of the process, from buying to building to paperwork to home financing and much more.</p>

                <p>Ewen Realtors intend to take your real estate experience to new heights and keep your priorities on top. Since Covid-19 demand for townships in the mountains has increased and is gaining a lot of attention all over India. </p>

                <p>So, After successfully covering the mountains market the some of the finest development in Uttarakhand, Ewen Realtors has now landed in the Queen’s Meadow Ranikhet.</p>

                <p>In keeping with our commitment to providing a world-class township in Uttarakhand, we are pleased to introduce our ongoing project “The Ewen Park” with a majestic view of the Himalayas. Township is designed to offer you a wholesome living experience. Live a blissful life and embrace the beauty of nature.</p>
                
                <p>Smart living and a convenient lifestyle that you wished for in your life. A well-connected township that has nature as its neighbour. </p>
            </div>
        </div>
        
    </>
  )
}

export default One
