const Amenities = () => {
    return (
        <>
            <div className='py-2 mb-0'>
                <h2 className='text-center pb-4'>Amenities</h2>
                <div className='container col-md-8'>
                    <img src='/assets/projects/Himalayan Vista/amenities/Amenities.svg' style={{width: '100%'}} />
                </div>
                <p className='pt-4'>
                    Ewen Realtors are offering much more amenities that you all get in cities like sports (outdoor and indoor), spa, gym, common space, road connectivity to every plot, and much more. We are focusing on providing you with all the amenities ranging from basic to A-one amenities because we want you to live a luxurious life.
                </p>
            </div>
        </>
    );
}

export default Amenities;