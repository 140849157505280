import { Link } from "react-router-dom";
import '../css/allMenuMobile.css';

const allMenuMobile = () => {
    return (
        <div className="container col-md-6 p-0">
            <div className="pt-3 px-3 text-start">
                <h4 className="p-0 m-0 pb-1 mb-2">Menu</h4>
                <Link to="/contact-us">
                    <div className="d-flex align-items-center py-2 rounded-4 my-2" id="gradientBtn">
                        <div className="text-white rounded text-center" style={{width: '40px'}}><i className="fas fa-tags m-2"></i></div>
                        <div className="px-2 text-white" style={{fontWeight: '500', fontSize: '14px'}}>Book Now @ ₹51,000 Only</div>
                    </div>
                </Link>
            </div>
            <div className="text-start px-3 pb-5" id="allMenuMobileNavs">
                <Link to="/">
                    <div className="d-flex align-items-center py-2">
                        <div className="text-secondary rounded text-center" style={{width: '40px'}}><i className="fas fa-home m-2"></i></div>
                        <div className="px-2" style={{fontWeight: '500', fontSize: '14px'}}>Home</div>
                    </div>
                </Link>
                <Link to="/about-us">
                    <div className="d-flex align-items-center py-2">
                        <div className="text-secondary rounded text-center" style={{width: '40px'}}><i className="fas fa-users m-2"></i></div>
                        <div className="px-2" style={{fontWeight: '500', fontSize: '14px'}}>About Us</div>
                    </div>
                </Link>
                <Link to="/services">
                    <div className="d-flex align-items-center py-2">
                        <div className="text-secondary rounded text-center" style={{width: '40px'}}><i className="fas fa-concierge-bell m-2"></i></div>
                        <div className="px-2" style={{fontWeight: '500', fontSize: '14px'}}>Services</div>
                    </div>
                </Link>
                <Link to="/projects">
                    <div className="d-flex align-items-center py-2">
                        <div className="text-secondary rounded text-center" style={{width: '40px'}}><i className="fas fa-project-diagram m-2"></i></div>
                        <div className="px-2" style={{fontWeight: '500', fontSize: '14px'}}>Projects</div>
                    </div>
                </Link>
                <Link to="/blog">
                    <div className="d-flex align-items-center py-2">
                        <div className="text-secondary rounded text-center" style={{width: '40px'}}><i className="fas fa-cubes m-2"></i></div>
                        <div className="px-2" style={{fontWeight: '500', fontSize: '14px'}}>Blog</div>
                    </div>
                </Link>
                <Link to="/media">
                    <div className="d-flex align-items-center py-2">
                        <div className="text-secondary rounded text-center" style={{width: '40px'}}><i className="fas fa-images m-2"></i></div>
                        <div className="px-2" style={{fontWeight: '500', fontSize: '14px'}}>Media</div>
                    </div>
                </Link>
                <Link to="/contact-us">
                    <div className="d-flex align-items-center py-2">
                        <div className="text-secondary rounded text-center" style={{width: '40px'}}><i className="fas fa-phone-alt m-2"></i></div>
                        <div className="px-2" style={{fontWeight: '500', fontSize: '14px'}}>Contact Us</div>
                    </div>
                </Link>
                <Link to="/careers">
                    <div className="d-flex align-items-center py-2">
                        <div className="text-secondary rounded text-center" style={{width: '40px'}}><i className="fas fa-graduation-cap m-2"></i></div>
                        <div className="px-2" style={{fontWeight: '500', fontSize: '14px'}}>Careers</div>
                    </div>
                </Link>
                <Link to="/partner">
                    <div className="d-flex align-items-center py-2">
                        <div className="text-secondary rounded text-center" style={{width: '40px'}}><i className="fas fa-handshake"></i></div>
                        <div className="px-2" style={{fontWeight: '500', fontSize: '14px'}}>Join As Partner</div>
                    </div>
                </Link>
                <Link to="/faqs">
                    <div className="d-flex align-items-center py-2">
                        <div className="text-secondary rounded text-center" style={{width: '40px'}}><i className="fas fa-question-circle m-2"></i></div>
                        <div className="px-2" style={{fontWeight: '500', fontSize: '14px'}}>FAQs</div>
                    </div>
                </Link>

                {/* Projects  */}
                {/* <div className="row m-0 mt-3 mb-2">
                    <div className="col-12 p-0">
                        <h6 className="px-2 pt-2 pb-2 m-0">Project - The Ewen Park</h6>
                    </div>
                    <div className="col-6 px-2">
                        <Link to="/projects/royal-villas">
                            <div className="card border-0" style={{background: '#fff'}}>
                                <div className="p-0">
                                    <img src="/assets/images/projects/The Ewen Park - Royal Villas.jpg" style={{width: '100%', borderRadius: '4px 4px 0px 0px'}} />
                                </div>
                                <div className="p-2" style={{fontSize: '15px', fontWeight: '600'}}>
                                    <p className="p-0 m-0">Royal Villas</p>
                                    <p className="p-0 m-0" style={{fontSize: '12px', fontWeight: '400', color: 'rgba(0,0,0,0.75)'}}><i className="fas fa-map-marker-alt fa-sm"></i> Majhkhali, Ranikhet </p>
                                </div>
                            </div>
                        </Link>
                    </div>
                    <div className="col-6 px-2">
                        <Link to="/projects/hill-village">
                            <div className="card border-0" style={{background: '#fff'}}>
                                <div className="p-0">
                                    <img src="/assets/images/projects/The Ewen Park - Hill Village.jpg" style={{width: '100%', borderRadius: '4px 4px 0px 0px'}} />
                                </div>
                                <div className="p-2" style={{fontSize: '15px', fontWeight: '600'}}>
                                    <p className="p-0 m-0">Hill Village</p>
                                    <p className="p-0 m-0" style={{fontSize: '12px', fontWeight: '400', color: 'rgba(0,0,0,0.75)'}}><i className="fas fa-map-marker-alt fa-sm"></i> Majhkhali, Ranikhet </p>
                                </div>
                            </div>
                        </Link>
                    </div>
                    <div className="col-6 px-2">
                        <Link to="/projects/himalayan-vista">
                            <div className="card border-0" style={{background: '#fff'}}>
                                <div className="p-0">
                                    <img src="/assets/images/projects/The Ewen Park - Himalayan Vista.jpg" style={{width: '100%', borderRadius: '4px 4px 0px 0px'}} />
                                </div>
                                <div className="p-2" style={{fontSize: '15px', fontWeight: '600'}}>
                                    <p className="p-0 m-0">Himalayan Vista</p>
                                    <p className="p-0 m-0" style={{fontSize: '12px', fontWeight: '400', color: 'rgba(0,0,0,0.75)'}}><i className="fas fa-map-marker-alt fa-sm"></i> Majhkhali, Ranikhet </p>
                                </div>
                            </div>
                        </Link>
                    </div>
                </div> */}

                {/* Services  */}
                <div className="row m-0 my-2">
                    <div className="col-12 p-0">
                        <h6 className="px-2 pt-2 pb-2 m-0">Services</h6>
                    </div>
                    <div className="col-6 px-2">
                        <Link to="/services/builder-and-developer">
                            <div className="card p-2">
                                <div className="p-0">
                                    <img src="/assets/images/services/icons/Builder Icon.svg" style={{height: '35px'}} />
                                </div>
                                <div className="px-0 pt-2" style={{fontSize: '12px'}}>Builder & Developer</div>
                            </div>
                        </Link>
                    </div>
                    <div className="col-6 px-2">
                        <Link to="/services/construction-service">
                            <div className="card p-2">
                                <div className="p-0">
                                    <img src="/assets/images/services/icons/Construction Icon.svg" style={{height: '35px'}} />
                                </div>
                                <div className="px-0 pt-2" style={{fontSize: '12px'}}>Construction Services</div>
                            </div>
                        </Link>
                    </div>
                    <div className="col-6 px-2">
                        <Link to="/services/real-estate-consultant">
                            <div className="card p-2">
                                <div className="p-0">
                                    <img src="/assets/images/services/icons/Real Estate Icon.svg" style={{height: '35px'}} />
                                </div>
                                <div className="px-0 pt-2" style={{fontSize: '12px'}}>Real Estate Consultant</div>
                            </div>
                        </Link>
                    </div>
                    <div className="col-6 px-2">
                        <Link to="/services/property-legal-services">
                            <div className="card p-2">
                                <div className="p-0">
                                    <img src="/assets/images/services/icons/Property Legal Icon.svg" style={{height: '35px'}} />
                                </div>
                                <div className="px-0 pt-2" style={{fontSize: '12px'}}>Property Legal Services</div>
                            </div>
                        </Link>
                    </div>
                    <div className="col-6 px-2">
                        <Link to="/services/home-loan-services">
                            <div className="card p-2">
                                <div className="p-0">
                                    <img src="/assets/images/services/icons/Home Loan Icon.svg" style={{height: '35px'}} />
                                </div>
                                <div className="px-0 pt-2" style={{fontSize: '12px'}}>Home Loan Services</div>
                            </div>
                        </Link>
                    </div>
                    <div className="col-6 px-2">
                        <Link to="/services/vastu-consultant">
                            <div className="card p-2">
                                <div className="p-0">
                                    <img src="/assets/images/services/icons/Vastu Icon.svg" style={{height: '35px'}} />
                                </div>
                                <div className="px-0 pt-2" style={{fontSize: '12px'}}>Vastu Consultant</div>
                            </div>
                        </Link>
                    </div>
                    <div className="col-6 px-2">
                        <Link to="/services/interior-designer">
                            <div className="card p-2">
                                <div className="p-0">
                                    <img src="/assets/images/services/icons/Interior Icon.svg" style={{height: '35px'}} />
                                </div>
                                <div className="px-0 pt-2" style={{fontSize: '12px'}}>Interior Designer</div>
                            </div>
                        </Link>
                    </div>
                </div>

                <div className="p-0 pb-5 m-0">
                    <h6 className="p-2 m-0">Policies</h6>
                    <Link to="/disclaimer">
                        <div className="d-flex align-items-center py-2">
                            <div className="text-secondary rounded text-center" style={{width: '40px'}}><i className="fas fa-question-circle m-2"></i></div>
                            <div className="px-2" style={{fontWeight: '500', fontSize: '14px'}}>Disclaimer</div>
                        </div>
                    </Link>
                    <Link to="/privacy-policy">
                        <div className="d-flex align-items-center py-2">
                            <div className="text-secondary rounded text-center" style={{width: '40px'}}><i className="fas fa-question-circle m-2"></i></div>
                            <div className="px-2" style={{fontWeight: '500', fontSize: '14px'}}>Privacy Policy</div>
                        </div>
                    </Link>
                    <Link to="/terms-and-conditions">
                        <div className="d-flex align-items-center py-2">
                            <div className="text-secondary rounded text-center" style={{width: '40px'}}><i className="fas fa-question-circle m-2"></i></div>
                            <div className="px-2" style={{fontWeight: '500', fontSize: '14px'}}>Terms & Conditions</div>
                        </div>
                    </Link>
                    <Link to="/refund-policy">
                        <div className="d-flex align-items-center py-2">
                            <div className="text-secondary rounded text-center" style={{width: '40px'}}><i className="fas fa-question-circle m-2"></i></div>
                            <div className="px-2" style={{fontWeight: '500', fontSize: '14px'}}>Refund Policy</div>
                        </div>
                    </Link>

                    <h6 className="p-2 m-0">Sitemap</h6>

                    <Link to="/sitemap">
                        <div className="d-flex align-items-center py-2">
                            <div className="text-secondary rounded text-center" style={{width: '40px'}}><i className="fas fa-list m-2"></i></div>
                            <div className="px-2" style={{fontWeight: '500', fontSize: '14px'}}>Sitemap</div>
                        </div>
                    </Link>
                    <div className="text-center pt-1" style={{fontSize: '10px', color: 'rgba(0,0,0,0.75)'}}>
                        EwenRealtors<sup>&copy;</sup> 2022
                    </div>
                </div>


            </div>
        </div>
    );
}

export default allMenuMobile;
