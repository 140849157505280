import Pricing from "./Pricing";
import AllProjects from "./services/AllProjects";

const Projects = () => {
    document.title = "Projects - Ewen Realtors";
    const ldJson = 
    {
        "@context" : "http://schema.org",
        "@type" : "Real Estate Business",
        "name" : "Ewen Realtors",
        "image" : "https://ewenrealtors.com/logo.svg",
        "telephone" : "+91 852-616-4444",
        "address" : {
            "@type" : "PostalAddress",
            "streetAddress" : "Ewen Realtors NH 109, Nainital Road, Kathgodam, Uttarakhand 263139"
        }
    }
    return (
        <>
        <AllProjects/>
        <img src="/assets/images/our-projects.jpg" style={{width: '100%', Radius: '4px'}} />
        <div className="container p-3 px-md-5 mt-2 mt-md-4 mb-2 ">
            <div style={{textAlign: 'justify'}}>
                <p>A place where you just don't experience a good life. You live it by taking your energy to a new level surrounded by greenery and enthusiasm. Your second home or holiday home will be your favourite holiday destination. Smart living and convenient lifestyle that you desired all your life.</p>

                <p>Ewen Realtors is doing development in a vibrant location with a spectacular view of the Himalayas. Ewen Realtors presents “The Ewen Park” a township in the mountains to redefine your standard of living.</p> 

                <p>It was never our intention to build a simple house but to lay a foundation that would transform the way we live. There is everything you could ever need in this premium township.</p> 
            </div>
        </div>

        {/* Amenities  */}
        <section className="container">
            <h2 className='text-center mt-3 mb-4'>Amenities</h2>
            <p className='text-center'>Ewen Realtors are providing all the essentials as well as luxurious amenities. We are here to make your stay worthwhile and valuable by presenting facilities to make your life more pleasant and comfortable. To know more check out the amenities mentioned above.</p>

            <div className='row m-0'>
                <div className='col-md-4 p-3'>
                    <div className='card projectAmenity p-3 border-0'>
                        <img src="/assets/images/amenities/on-site-maintenance.jpg" />
                        <h3 className='text-center'>On Site Maintenance</h3>
                        <p className='text-center'>We offer on-site maintenance for a better and smoother life in the mountains.</p>
                    </div>
                </div>
                <div className='col-md-4 p-3'>
                    <div className='card projectAmenity p-3 border-0'>
                        <img src="/assets/images/amenities/gym-n-spa.jpg" />
                        <h3 className='text-center'>Gym & Spa</h3>
                        <p className='text-center'>Gym with qualified trainers and spa with well-versed staff because we care for you.</p>
                    </div>
                </div>
                <div className='col-md-4 p-3'>
                    <div className='card projectAmenity p-3 border-0'>
                        <img src="/assets/images/amenities/water-n-electricity.jpg" />
                        <h3 className='text-center'>24/7 Water & Electricity</h3>
                        <p className='text-center'>All three factors can affect our peaceful time. We provide water and electricity facilities 24 hours a day.</p>
                    </div>
                </div>
                <div className='col-md-4 p-3'>
                    <div className='card projectAmenity p-3 border-0'>
                        <img src="/assets/images/amenities/wifi-n-mobile-network.jpg" />
                        <h3 className='text-center'>Wi-Fi & Mobile Network</h3>
                        <p className='text-center'>To make your stay more convenient, we provide full mobile network service and Wi-Fi.</p>
                    </div>
                </div>
                <div className='col-md-4 p-3'>
                    <div className='card projectAmenity p-3 border-0'>
                        <img src="/assets/images/amenities/uf-based-entry.jpg" />
                        <h3 className='text-center'>Entry – UHF Based</h3>
                        <p className='text-center'>Where to park your vehicle can be an issue. We take care of all your concerns by providing you with a personal parking space.</p>
                    </div>
                </div>
                <div className='col-md-4 p-3'>
                    <div className='card projectAmenity p-3 border-0'>
                        <img src="/assets/images/amenities/all-amenities.jpg" />
                        <h3 className='text-center'>Much More…</h3>
                        <p className='text-center'>Security (CCTV, Guards, etc.), Yoga, Sports, Temple, Party Hall, Club House For Senior citizens, Cinema Hall, etc.</p>
                    </div>
                </div>
            </div>
        </section>
        
        <Pricing />

        {/* Reasons  */}
        <div className="row m-0 justify-content-center my-2 my-md-4">
            <div className="col-md-11 ">
            <h2 className="py-3 text-center mb-4 text-uppercase">Reason To Choose <font style={{color: '#4d9c48'}}>The Ewen Park</font></h2>
                <div className=" row m-0 align-items-center justify-content-center" id="reverse">
                    <div className="col-md-4 my-2 my-md-3">
                        <h3 id="reasonleft">LOCATION</h3>
                        <p id="reason_desc_left">If you are looking for peace, tranquillity, and closeness to nature, “The Ewen Park” is the best place for you.</p>
                    </div>
                    <div className="col-md-4  my-3 p-0">
                        <img src="/assets/images/projects/reasons/ewenpark location.jpg" id="reasonImg" />
                    </div>
                </div>
                <div className="row m-0 align-items-center justify-content-center">
                    <div className="col-md-4 my-2 my-md-3 p-0">
                        <img src="/assets/images/projects/reasons/ewenpark climate.jpg" id="reasonImg" />
                    </div>
                    <div className="col-md-4 my-2 my-md-3">
                        <h3 id="reasonRight">CLIMATIC CONDITION</h3>
                        <p id="reason_desc_right">Township is at a perfect elevation for enjoying all seasons. You can enjoy cool weather in summers, lush greenery in monsoon, and snowfall in winters.</p>
                    </div>
                </div>
                <div className=" row m-0 align-items-center justify-content-center" id="reverse">
                    <div className="col-md-4  my-3">
                        <h3 id="reasonleft">BREATHTAKING VIEWS</h3>
                        <p id="reason_desc_left">This premium township comes with a stunning view of the Himalayan peaks like Trishul Complex, Nanda Kot, Nanda Devi, and so on.</p>
                    </div>
                    <div className="col-md-4 my-2 my-md-3 p-0">
                        <img src="/assets/images/projects/reasons/ewenpark view.jpg" id="reasonImg" />
                    </div>
                </div>
                <div className="row m-0 align-items-center justify-content-center">
                    <div className="col-md-4 my-2 my-md-3 p-0">
                        <img src="/assets/images/projects/reasons/close to nature.jpg" id="reasonImg" />
                    </div>
                    <div className="col-md-4 my-2 my-md-3">
                        <h3 id="reasonRight">CLOSE TO NATURE</h3>
                        <p id="reason_desc_right">The Ewen Park is in the lap of nature surrounded by thick vegetation of oak, deodar, and pine trees and a wide range of fauna.</p>
                    </div>
                </div>
                <div className=" row m-0 align-items-center justify-content-center" id="reverse">
                    <div className="col-md-4 my-2 my-md-3">
                        <h3 id="reasonleft">AMENITIES</h3>
                        <p id="reason_desc_left">This township comes with not just basic amenities you need but even those you still haven't thought about getting in the mountains.</p>
                    </div>
                    <div className="col-md-4  my-3 p-0">
                        <img src="/assets/images/projects/reasons/amenities.jpg" id="reasonImg" />
                    </div>
                </div>
                <div className="row m-0 align-items-center justify-content-center">
                    <div className="col-md-4 my-2 my-md-3 p-0">
                        <img src="/assets/images/projects/reasons/neighbouring hill station.jpg" id="reasonImg" />
                    </div>
                    <div className="col-md-4 my-2 my-md-3">
                        <h3 id="reasonRight">NEIGHBOURING HILL STATION</h3>
                        <p id="reason_desc_right">Do you know Ranikhet is connected to 16 different other hill stations within 100 Km like Nainital, Mukteshwar, Almora, etc. You can go exploring them too.</p>
                    </div>
                </div>
            </div>
        </div>

        <section className="container text-start">
            <blockquote style={{fontSize: '24px', borderLeft: '2px solid #000'}} className="px-3 py-3 my-5">Being able to smell the fresh air and disconnect from the news and your phone – there’s nothing like living in mountains.</blockquote>
            <h2 id="faqs" className='my-4'>FAQs</h2>

            <p>We know that you have so many doubts and questions in your mind. We have answered some of the common queries raised by hundreds&#8217; of our users, which are mentioned below:</p>

            <div class="wp-block-uagb-faq uagb-faq__outer-wrap uagb-block-d859e0d4 uagb-faq-icon-row uagb-faq-layout-accordion uagb-faq-expand-first-false uagb-faq-inactive-other-true uagb-faq-equal-height" data-faqtoggle="true" role="tablist"><div class="uagb-faq__wrap uagb-buttons-layout-wrap">
            <div class="wp-block-uagb-faq-child uagb-faq-child__outer-wrap uagb-block-137d1d67"><div class="uagb-faq-child__wrapper"><div class="uagb-faq-item" role="tab" tabindex="0"><div class="uagb-faq-questions-button uagb-faq-questions"><span class="uagb-question">Where are the projects of Ewen Realtors?</span></div><div class="uagb-faq-content"><span><p>Ewen Realtors is working on three projects. The Ewen Park, The Ewen Village, and The Ewen Studio.</p></span></div></div></div></div>

            <div class="wp-block-uagb-faq-child uagb-faq-child__outer-wrap uagb-block-0b9ec037"><div class="uagb-faq-child__wrapper"><div class="uagb-faq-item" role="tab" tabindex="0"><div class="uagb-faq-questions-button uagb-faq-questions"><span class="uagb-question">Where is the village Babur Khola?</span></div><div class="uagb-faq-content"><span><p>The village Babur Khola is found 5 kilometers from Majkhali and 17 kilometers from Ranikhet.</p></span></div></div></div></div>

            <div class="wp-block-uagb-faq-child uagb-faq-child__outer-wrap uagb-block-2d8edf96"><div class="uagb-faq-child__wrapper"><div class="uagb-faq-item" role="tab" tabindex="0"><div class="uagb-faq-questions-button uagb-faq-questions"><span class="uagb-question">Does Ranikhet experience snowfall?</span></div><div class="uagb-faq-content"><span><p>Yes, Ranikhet experience snowfall from the month of December to February.</p></span></div></div></div></div>

            <div class="wp-block-uagb-faq-child uagb-faq-child__outer-wrap uagb-block-9cbd336a"><div class="uagb-faq-child__wrapper"><div class="uagb-faq-item" role="tab" tabindex="0"><div class="uagb-faq-questions-button uagb-faq-questions"><span class="uagb-question">What Ewen Realtors are doing?</span></div><div class="uagb-faq-content"><span><p>Ewen Realtors is working on creating an elite township in the village of Baburkhola, 17 kilometers from Ranikhet, nestled in the arms of nature.</p></span></div></div></div></div>

            <div class="wp-block-uagb-faq-child uagb-faq-child__outer-wrap uagb-block-3671e563"><div class="uagb-faq-child__wrapper"><div class="uagb-faq-item" role="tab" tabindex="0"><div class="uagb-faq-questions-button uagb-faq-questions"><span class="uagb-question">Why Ewen Realtors is building a township in Baburkhola?</span></div><div class="uagb-faq-content"><span><p>BaburkholaVillage resembles the Scottish countryside, just a few 5 km from Majhkali. Himalayan ranges are visible which include peaks such as Nanda Devi Massif, Nanda Ghunti, Trishul Complex, Panchachuli, Mrigthuni, Nanda Kot, etc. It also has a wide range of flora and fauna.</p></span></div></div></div></div>
            </div></div>
        </section>
        </>
    );
}

export default Projects;