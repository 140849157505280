import { NavLink } from 'react-router-dom';
import AllProjects from '../services/AllProjects';
import Helmet from 'react-helmet';
import ContactForm from '../ContactForm';
import CallToAction from './CallToAction';
import Amenities from './Amenities';
import HimalayanVistaCarousel from './Carousel/HimalayanVistaCarousel';
import ReachUs from './ReachUs';

const HimalayanVista = () => {
    document.title = "Himalayan Vista - The Ewen Park | Ewen Realtors";
    const ldJson = 
    {
        "@context" : "http://schema.org",
        "@type" : "Real Estate Business",
        "name" : "Ewen Realtors",
        "image" : "https://ewenrealtors.com/logo.svg",
        "telephone" : "+91 852-616-4444",
        "address" : {
            "@type" : "PostalAddress",
            "streetAddress" : "Ewen Realtors NH 109, Nainital Road, Kathgodam, Uttarakhand 263139"
        }
    }

    return(
        <>
            <Helmet>
                <meta name="description" content="Ewen Realtors presents Phase 2 of “The Ewen Park” i.e Dwarsu. It is a grand township near Baburkhola in Ranikhet. The place is immensely popular for its natural habitat and serene environment. The township which is being built in Dwarsu also has a wide range of vegetation which enhances the beauty of the township." />
                <link rel="canonical" href="https://ewenrealtors.com/projects/himalayan-vista" />
                <meta property="og:locale" content="en_US" />
                <meta property="og:type" content="website" />
                <meta property="og:title" content="Himalayan Vista | Ewen Realtors - Best Real Estate in India" />
                <meta property="og:description" content="Ewen Realtors presents Phase 2 of “The Ewen Park” i.e Dwarsu. It is a grand township near Baburkhola in Ranikhet. The place is immensely popular for its natural habitat and serene environment. The township which is being built in Dwarsu also has a wide range of vegetation which enhances the beauty of the township." />
                <meta property="og:url" content="https://ewenrealtors.com/projects/himalayan-vista" />
                <meta property="og:site_name" content="Ewen Realtors" />
                <meta property="article:publisher" content="https://www.facebook.com/ewenrealtors" />
                <meta property="article:modified_time" content="2022-03-23T06:20:43+00:00" />
                <meta property="og:image:width" content="800" />
                <meta property="og:image:height" content="450" />
                <meta property="og:image:type" content="image/jpeg" /> 
                
                {/* JSON-LD markup generated by Google Structured Data Markup Helper. */}
                <script type="application/ld+json">
                    {JSON.stringify(ldJson)}
                </script>
            </Helmet>
            <AllProjects/>
            <div className='my-3 container text-start'>
                <div className='row m-0 align-items-center my-md-5 flex-row-reverse'>
                    <div className='col-md-5 text-center px-md-5'>
                        <HimalayanVistaCarousel/>
                    </div>
                    <div className='col-md-7'>
                        <h1 className='text-start projectHeading'>Himalayan Vista</h1>
                        <p className='text-secondary font-italic'>Nature will be your neighbour.</p>
                        <p className='text-justify'>
                            Presenting you Himalayan Vista, Phase 2 of "The Ewen Park" by Ewen Realtors. Situated in the scenic location of Dwarsu, near Baburkhola in Ranikhet, this grand township offers a remarkable living experience amidst the breathtaking beauty of nature.
                        </p>
                        <p className='text-justify'>
                            Himalayan Vista is famous for its natural habitat and serene environment. If you're seeking a tranquil and peaceful lifestyle, this is the place for you. Surrounded by the awe-inspiring dense greenery with a breathtaking view of the Himalayan peaks, this township provides a haven for city people.
                        </p>
                    </div>
                </div>
                <Amenities/>
            </div>

            <CallToAction/>

            <div className='my-3 container text-start'>
                {/* Master Plan  */}
                <div className='py-4 mt-3'>
                    <h2 className='text-center pb-3'>Master Plan</h2>
                    <div class="row m-0 p-0 align-items-center">
                        <div class="nav flex-fill nav-pills me-3 col-md-3 d-flex justify-content-center p-0" id="v-pills-tab" role="tablist" aria-orientation="vertical">
			    <a href="https://drive.google.com/file/d/1B0ebXx_r2C43dhRRUhyH-_hcJZzlElr-/view?usp=drive_link" target='_blank'>
                                <button class="nav-link" id="v-pills-messages-tab" data-bs-toggle="pill" type="button" role="tab" aria-controls="tab4" aria-selected="false">Layout</button>
                            </a>

                            <button class="nav-link" id="v-pills-home-tab" data-bs-toggle="pill" data-bs-target="#tab1" type="button" role="tab" aria-controls="tab1" aria-selected="true">1, 2, 3 BHK Duplex</button>
                            <button class="nav-link" id="v-pills-profile-tab" data-bs-toggle="pill" data-bs-target="#tab2" type="button" role="tab" aria-controls="tab2" aria-selected="false">Location</button>
				<a href="https://drive.google.com/file/d/1VNX2rUjneluhAaG6KPIa616oF4I5Ctyy/view?usp=sharing" target='_blank'>
                                <button class="nav-link" id="v-pills-messages-tab" data-bs-toggle="pill" type="button" role="tab" aria-controls="tab4" aria-selected="false">Payment Plan</button>
                            </a>
                        </div>
                        <div class="tab-content col-md-12 text-center" id="v-pills-tabContent">
                            <div class="tab-pane fade" id="tab1" role="tabpanel" aria-labelledby="v-pills-home-tab" tabindex="0">
                                <div className='row m-0'>
                                    <div className='col-md-6 pb-2'>
                                        <a href='/assets/projects/Himalayan Vista/1BHK (VILLA-4,5) HIMALAYAN VISTA.jpg' target='_blank'>
                                            <img src='/assets/projects/Himalayan Vista/1BHK (VILLA-4,5) HIMALAYAN VISTA.jpg' style={{width: '100%', borderRadius: '10px'}} />
                                        </a>
                                    </div>
                                    <div className='col-md-6 pb-2'>
                                        <a href='/assets/projects/Himalayan Vista/2BHK (VILLA-6,7,8,9,10) HIMALAYAN VISTA.jpg' target='_blank'>
                                            <img src='/assets/projects/Himalayan Vista/2BHK (VILLA-6,7,8,9,10) HIMALAYAN VISTA.jpg' style={{width: '100%', borderRadius: '10px'}} />
                                        </a>
                                    </div>
                                    <div className='col-md-6 pb-2'>
                                        <a href='/assets/projects/Himalayan Vista/3BHK.jpg' target='_blank'>
                                            <img src='/assets/projects/Himalayan Vista/3BHK.jpg' style={{width: '100%', borderRadius: '10px'}} />
                                        </a>
                                    </div>
                                </div>
                            </div>
                            <div class="tab-pane fade" id="tab2" role="tabpanel" aria-labelledby="v-pills-profile-tab" tabindex="0">
                                <a href='/assets/projects/Himalayan Vista/Location.jpg' target='_blank'>
                                    <img src='/assets/projects/Himalayan Vista/Location.jpg' style={{width: '100%', borderRadius: '10px'}} />
                                </a>
                            </div>
                        </div>
                    </div>
                </div>

                {/* Location Map  */}
                <div className='py-4'>
                    <h2 className='text-center mb-3'>Location Map</h2>
                    <iframe src="https://www.google.com/maps/embed?pb=!1m17!1m12!1m3!1d3467.111592293381!2d79.544016!3d29.658535999999998!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m2!1m1!2zMjnCsDM5JzMwLjciTiA3OcKwMzInMzguNSJF!5e0!3m2!1sen!2sin!4v1688829396006!5m2!1sen!2sin"allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade" style={{width: '100%', height: '400px', borderRadius: '10px'}} className="shadow-sm"></iframe>
                </div>

                <ReachUs/>

                <ContactForm/>
            </div>

        </>
    );
}

export default HimalayanVista;
