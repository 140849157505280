const CostPaymentPlan = () => {
    return (
        <div className='container p-3 my-md-1 pt-4' id="payment-plan">
                        <h2 className='head2'>Cost & Payment Plan</h2>
                        <div style={{overflowX: 'auto'}}>
                            <table className='table mt-4 table-bordered table-lg'>
                                <tbody style={{fontWeight: '600'}}>  
                                    <tr>
                                        <th>CONSTRUCTION COST</th>    
                                        <td colSpan={3}>Depends on the design and requirements of ones choice.</td>
                                    </tr>   
                                    <tr>
                                        <th colSpan={100}>PAYMENT PLAN</th>
                                    </tr> 
                                    <tr>
                                        <td>1</td>
                                        <td>Booking Amount</td>
                                        <td>40% of Total Amount</td>
                                        <td>
                                            *15 days window to finalize the decision.
                                            *15 more days for 1st installment.
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>2</td>
                                        <td>1st Installment</td>
                                        <td>15% of Total Amount</td>
                                        <td>*1 month window.</td>
                                    </tr>
                                    <tr>
                                        <td>3</td>
                                        <td>2nd Installment</td>
                                        <td>15% of Total Amount</td>
                                        <td>*1 month window.</td>
                                    </tr>
                                    <tr>
                                        <td>4</td>
                                        <td>3rd Installment</td>
                                        <td>15% of Total Amount</td>
                                        <td>*1 month window.</td>
                                    </tr>
                                    <tr>
                                        <td>5</td>
                                        <td>Final Payment</td>
                                        <td>15% of Final Installment</td>
                                        <td>After Final Installment, the freehold land will be registered by your name.</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        
                        <div className='mt-3 mb-3 text-start'>
                            <a href="/contact-us">
                                <button className='btn btn-primary rounded-3 text-light text-capitalize shadow-1'><i className='fas fa-envelope'></i> Contact Us For Brochure</button>
                            </a>
                        </div>
                    </div>
    )
}

export default CostPaymentPlan;