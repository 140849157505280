import { NavLink } from 'react-router-dom';
import AllServices from './AllServices';
import Helmet from 'react-helmet';

const HomeLoanServices = () => {
    document.title = "Home Loan Services | Ewen Realtors - Best Real Estate in India";
    const ldJson = 
    {
        "@context" : "http://schema.org",
        "@type" : "Real Estate Business",
        "name" : "Ewen Realtors",
        "image" : "https://ewenrealtors.com/logo.svg",
        "telephone" : "+91 852-616-4444",
        "address" : {
          "@type" : "PostalAddress",
          "streetAddress" : "Ewen Realtors NH 109, Nainital Road, Kathgodam, Uttarakhand 263139"
        }
    }
    return( 
        <div className='text-start p-0 m-0'>
            <Helmet>
                <meta name="description" content="Ewen Realtors offers you the right home loan at a great rate. We will walk you through each step and provide you with all of the relevant information before you enter into a long-term repayment contract with a lending institution." />
                <link rel="canonical" href="https://ewenrealtors.com/services/home-loan-services" />
                <meta property="og:locale" content="en_US" />
                <meta property="og:type" content="website" />
                <meta property="og:title" content="Home Loan Services | Ewen Realtors - Best Real Estate in India" />
                <meta property="og:description" content="Ewen Realtors offers you the right home loan at a great rate. We will walk you through each step and provide you with all of the relevant information before you enter into a long-term repayment contract with a lending institution." />
                <meta property="og:url" content="https://ewenrealtors.com/services/home-loan-services" />
                <meta property="og:site_name" content="Ewen Realtors" />
                <meta property="article:publisher" content="https://www.facebook.com/ewenrealtors" />
                <meta property="article:modified_time" content="2022-03-23T06:20:43+00:00" />
                <meta property="og:image:width" content="800" />
                <meta property="og:image:height" content="450" />
                <meta property="og:image:type" content="image/jpeg" /> 
                
                {/* JSON-LD markup generated by Google Structured Data Markup Helper. */}
                <script type="application/ld+json">
                    {JSON.stringify(ldJson)}
                </script>
            </Helmet>
            <AllServices />
            <div className='my-4 container'>
                <h1 className="mb-4">Home Loan Services</h1>
                <img src="/assets/images/Home-Loan-Services.jpg" style={{width: '100%', borderRadius: '4px'}} />

                <blockquote class="wp-block-quote is-style-large mt-3"><p><strong><em>“A home is not a place, it&#8217;s a feel”</em></strong></p></blockquote>

                <p>Ewen Realtors offers you the right home loan at a great rate. We will walk you through each step and provide you with all of the relevant information before you enter into a long-term repayment contract with a lending institution.</p>

                <p>A home loan will bring you one step closer to buying your ideal home.</p>

                <ol><li><strong>Professional assistance</strong>: Before you apply for a home loan, we provide professional guidance that will answer all of your questions about home loans and provide you with standard tips.</li></ol>

                <ol start="2"><li><strong>Instant loan</strong>: We understand the significance of a home loan. In every way, we understand our customers and value their dream and aspiration. We offer instant loans to help you get one closer to owning your dream home.</li></ol>

                <ol start="3"><li><strong>Multiple offers</strong>: Ewen Realtors provide multiple offers regarding home loans with the top-most home loan finance companies of India.&nbsp;</li></ol>

                <ol start="4"><li><strong>Availability</strong>: We are here to provide proper guidance to you with 24*7 service. We will answer all your home loan queries within 24 hours.&nbsp;</li></ol>

                <h3>Factors To Calculate Home Loan Eligibility</h3>

                <img src="/assets/images/home-loan-eligibility.jpg" style={{width: '100%', borderRadius: '4px'}} />

                <p className='pt-3'>It is important to evaluate your home loan eligibility before applying for one. It will help you know your chances of getting your home loan approved.&nbsp;</p>

                <ul><li>Qualifying minimum age to apply [see table below]</li></ul>

                <ul><li>Net take-home salary</li></ul>

                <ul><li>CIBIL score of 650 or above</li></ul>

                <ul><li>Property type and services&nbsp;</li></ul>

                <ul><li>Tenure of home loan</li></ul>

                <ul><li>Remaining years of service</li></ul>

                <ul><li>The valid range of loan-to-value (60-90%)</li></ul>

                <h3 className='py-3'>Basic Criteria For Home Loan Eligibility&nbsp;</h3>

                <img src="/assets/images/home-loan-criteria.jpg" style={{width: '100%', borderRadius: '4px'}} />

                <p className='pt-3'>Here we have mentioned some basic criteria for home loan eligibility.&nbsp;</p>

                <table className='table table-striped'>
                    <tr>
                        <td>Age</td>
                        <td>18-70 Years</td>
                    </tr>
                    <tr>
                        <td>Eligible salary</td>
                        <td>25000 above</td>
                    </tr>
                    <tr>
                        <td>Work experience for salaried person</td>
                        <td>3 years and above</td>
                    </tr>
                    <tr>
                        <td>Business stability for self-employed</td>
                        <td>5 years and above</td>
                    </tr>
                    <tr>
                        <td>Max loan on the property </td>
                        <td>Up to 90%</td>
                    </tr>
                    <tr>
                        <td>Max EMI as a percent of income</td>
                        <td>65%</td>
                    </tr>
                    <tr>
                        <td>Min CIBIL Score</td>
                        <td>650</td>
                    </tr>
                    <tr>
                        <td>Eligibility with co-applicant</td>
                        <td>Up to 3 earning members may be added to get loan eligibility.</td>
                    </tr>
                </table>

                <h3 className='my-3'>List Of Documents Needed</h3>

                <p>List of documents for both salaried and self-employed needed while availing for a home loan.&nbsp;</p>

                <ol><li>Completely filled loan application form.</li></ol>

                <ol start="2"><li>Passport size photograph.</li></ol>

               <ol start="3"><li>Documents to prove your identity such as PAN card, Passport, Adhar card, Driving license, Voter ID, etc.&nbsp;</li></ol>

                <ol start="4"><li>Documents to prove your residency like water, electricity, telephone, gas cylinder bill, ration card, or any other government-issued to prove your residential address.&nbsp;</li></ol>

                <ol start="5"><li>Copy of bank account statement/passbook with entries of past 7 months.</li></ol>

                <ol start="6"><li>Proof of signature identification from the current banker.</li></ol>

                <ol start="7"><li>Copy of personal asset and liability statement</li></ol>

                <p>List of documents that are different for salaried and self-employed</p>

                <figure class="wp-block-table is-style-stripes"><table className='table my-4'><thead><tr><th class="has-text-align-left" data-align="left"> <strong>Salary/ Individual</strong> </th><th> <strong>Self-employed&nbsp;</strong> </th></tr></thead><tbody><tr><td class="has-text-align-left" data-align="left">Proof of job stability&nbsp;</td><td>Proof of business continuity&nbsp;</td></tr><tr><td class="has-text-align-left" data-align="left">TDS certificate of form 16 or copy of income tax return for last 2 fiscals.&nbsp;</td><td>&nbsp; Photocopies of challans as proof of advance tax payment</td></tr><tr><td class="has-text-align-left" data-align="left">Employer&#8217;s original salary certificate / last three months&#8217; salary tips</td><td>Copies of the most recent three-month income tax return or assessment order</td></tr><tr><td class="has-text-align-left" data-align="left">If the salaried employee has changed jobs within the last year, copies of the offer and joining letter from the new company must be submitted.</td><td></td></tr></tbody></table></figure>

                <h2>EMI Calculator</h2>

                <p>Calculate EMI before taking a home loan. This will give you an idea so that you can evaluate EMI and compare interest rates with others.&nbsp;</p>

                <p><em>The basic question which arises here is what is EMI?</em></p>

                <p><strong>EMI: Equated Monthly Installment</strong></p>

                <p>It is the amount that a borrower must pay to the lender each month as per the terms and conditions of a home loan</p>

                {/* CALCULATOR  */}
                <iframe src="https://emicalculator.net/widget/2.0/test.html" style={{height: '330px'}}></iframe>

                <p><strong>Benefits Of Home EMI Calculator</strong></p>

                <p>EMI calculator is an online tool that is easy to use for calculating EMI. The benefits of the calculator are as follows:&nbsp;</p>

                <ul><li>Provide you with answers in seconds</li></ul>

                <ul><li>Saves time and energy from&nbsp; doing manual calculations</li></ul>

                <ul><li>Help you access how much will the loan EMI</li></ul>

                <ul><li>Makes loan planning easier for you&nbsp;</li></ul>

                <p><strong>Top Home Loan finance companies in India</strong></p>

                <ol><li>HDFC</li><li>PNB housing finance</li><li>Lic housing finance</li><li>Indiabulls</li><li>Tata Capital</li></ol>

                <h2>FAQ</h2>

                <div class="wp-block-uagb-faq uagb-faq__outer-wrap uagb-block-bc902a2a uagb-faq-icon-row uagb-faq-layout-accordion uagb-faq-expand-first-false uagb-faq-inactive-other-true uagb-faq-equal-height" data-faqtoggle="true" role="tablist">
                    <div class="uagb-faq__wrap uagb-buttons-layout-wrap">
                        <div class="wp-block-uagb-faq-child uagb-faq-child__outer-wrap uagb-block-c0283125">
                            <div class="uagb-faq-child__wrapper">
                                <div class="uagb-faq-item" role="tab" tabindex="0">
                                    <div class="uagb-faq-questions-button uagb-faq-questions">
                                        <span class="uagb-question">Is it possible for me to get a home loan at the age of 18?</span>
                                    </div>
                                    <div class="uagb-faq-content">
                                        <span><p>Ans. The eligibility age criteria for a home loan is 18-70 years.<br/></p></span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="wp-block-uagb-faq-child uagb-faq-child__outer-wrap uagb-block-7142d91d">
                    <div class="uagb-faq-child__wrapper">
                        <div class="uagb-faq-item" role="tab" tabindex="0">
                            <div class="uagb-faq-questions-button uagb-faq-questions">
                                <span class="uagb-question">What does EMI stand for?</span>
                            </div>
                            <div class="uagb-faq-content">
                                <span><p>Ans. EMI stands for <strong>E</strong>quated<strong> M</strong>onthly<strong> I</strong>nstallment. <br/></p></span>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="wp-block-uagb-faq-child uagb-faq-child__outer-wrap uagb-block-12feaeb9">
                    <div class="uagb-faq-child__wrapper">
                        <div class="uagb-faq-item" role="tab" tabindex="0">
                            <div class="uagb-faq-questions-button uagb-faq-questions">
                                <span class="uagb-question">What is a home loan?</span>
                            </div>
                            <div class="uagb-faq-content">
                                <span><p>Ans. It is the process by which a person obtains a loan from a lender in order to purchase a home.</p></span>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="wp-block-uagb-faq-child uagb-faq-child__outer-wrap uagb-block-c6f3b538">
                    <div class="uagb-faq-child__wrapper">
                        <div class="uagb-faq-item" role="tab" tabindex="0">
                            <div class="uagb-faq-questions-button uagb-faq-questions">
                                <span class="uagb-question">What is the work of the EMI calculator?</span>
                            </div>
                            <div class="uagb-faq-content">
                                <span><p>Ans. The EMI calculator will give you an idea so that you can easily evaluate your EMI and compare interest rates with others.<br/></p></span>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="wp-block-uagb-faq-child uagb-faq-child__outer-wrap uagb-block-698cf34a">
                    <div class="uagb-faq-child__wrapper">
                        <div class="uagb-faq-item" role="tab" tabindex="0">
                            <div class="uagb-faq-questions-button uagb-faq-questions">
                                <span class="uagb-question">Which is the best bank for a home loan?</span>
                            </div>
                            <div class="uagb-faq-content">
                                <span><p>Ans. Here are the top 5 home loan finance companies which are available in India.<br/>1. HDFC<br/>2. PNB housing finance<br/>3. LIC housing finance<br/>4. Indiabulls<br/>5. Tata capital<br/></p></span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default HomeLoanServices;
