import emailjs from '@emailjs/browser';
import React, { useRef } from 'react';

const CallToAction = () => {

    const form = useRef();

    const sendPhone = (e) => {
        e.preventDefault();

        emailjs.sendForm('service_02sbdgf', 'template_ys7b9bg', form.current, 'mdnt0vxswt93nM4fx')
        .then((result) => {
            //   console.log(result.text);
            e.target.reset();
            alert('Your Request sended successfully to our team.');
            }, (error) => {
                alert(error.text);
                // console.log(error.text);
        });
        alert('Please Wait...');
    };

    return(
        <div style={{background: '#080643'}}>
            <div className='container'>
                <div className='row m-0 align-items-center rounded-2 py-4 p-md-4 text-light rounded'>
                    <div className='col-md-6'>
                        <h4 className='text-start m-0'>I'm Interested. Send details</h4>
                        <p className='font-italic m-0' style={{color: '#9A96FA'}}>Call me or send me details on this number.</p>
                    </div>
                    <div className='col-md-6 py-3'>
                        <form ref={form} onSubmit={sendPhone}>
                            <div className='d-md-flex p-0 align-items-center  rounded-pill'>
                                <div className='flex-fill d-md-flex'>
                                    <div className='my-2 flex-fill'>
                                    <input className='rounded-pill w-100 border-0 px-3 py-2' style={{outline: 'none'}} placeholder='Username' type='text' name='username' required />
                                    </div>
                                    <div className='my-2 px-lg-2 px-md-2'>
                                    <input className='rounded-pill w-100 border-0 px-3 py-2' style={{outline: 'none'}} placeholder='Mobile' type='tel' name='phone' required />
                                    </div>
                                </div>
                                <div className=''>
                                    <button className='btn btn-gradient btn-light rounded-pill shadow-0 text-capitalize w-100' type='send'>send</button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default CallToAction;