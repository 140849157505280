import CareerForm from "./CareerForm";
import Helmet from 'react-helmet';

const Careers = () => {
    document.title = "Careers | Ewen Realtors - Best Real Estate in India";
    const ldJson = 
    {
        "@context" : "http://schema.org",
        "@type" : "Real Estate Business",
        "name" : "Ewen Realtors",
        "image" : "https://ewenrealtors.com/logo.svg",
        "telephone" : "+91 852-616-4444",
        "address" : {
          "@type" : "PostalAddress",
          "streetAddress" : "Ewen Realtors NH 109, Nainital Road, Kathgodam, Uttarakhand 263139"
        }
    }

    return (
        <div className="text-start p-0 m-0">
            <Helmet>
                <meta name="description" content="Careers | Ewen Realtors - Best Real Estate in India" />
                <link rel="canonical" href="https://ewenrealtors.com/careers" />
                <meta property="og:locale" content="en_US" />
                <meta property="og:type" content="website" />
                <meta property="og:title" content="Careers | Ewen Realtors - Best Real Estate in India" />
                <meta property="og:description" content="Careers | Ewen Realtors - Best Real Estate in India" />
                <meta property="og:url" content="https://ewenrealtors.com/careers" />
                <meta property="og:site_name" content="Ewen Realtors" />
                <meta property="article:publisher" content="https://www.facebook.com/ewenrealtors" />
                <meta property="article:modified_time" content="2022-03-23T06:20:43+00:00" />
                <meta property="og:image:width" content="800" />
                <meta property="og:image:height" content="450" />
                <meta property="og:image:type" content="image/jpeg" /> 
                
                {/* JSON-LD markup generated by Google Structured Data Markup Helper. */}
                <script type="application/ld+json">
                    {JSON.stringify(ldJson)}
                </script>
            </Helmet>
            <div className="container my-4">
                <h1 className="mb-4">Careers</h1>

                {/* we'r hiring  */}
                <section>
                    <div className="row m-0 align-items-center">
                        <div className="col-md-5">
                            <img src="/assets/images/careers.png" style={{width: '100%', borderRadius: '4px'}} />
                        </div>
                        <div className="col-md-7 p-md-2">
                            <p style={{fontSize: '24px'}} className="pt-4">
                                Join Ewen Family, And Grow With Us!
                            </p>
                            <h2 className="py-4">
                                We're Hiring!
                            </h2>
                            <strong>
                                “All of us do not have equal talent. But, all of us have an equal opportunity to develop our talents”
                            </strong>
                            <p className="py-4">
                            Ewen Realtors are approaching young minds and talent who can be part of our dynamic and inspiring team. 
                            </p>
                            <a href="#form">
                                <button className="btn btn-dark text-capitalize rounded-2 btn-lg shadow-0">Join Now!</button>
                            </a>
                        </div>
                    </div>
                </section>

                {/* about ER */}
                <section>
                    <div className="row m-0 align-items-center">
                        <div className="col-md-6">
                            <h2 className="py-4">
                                About Ewen Realtors
                            </h2>
                            <p>
                                Ewen Realtors was an incorporated sector working since 2011 but now we are established as a corporate sector. We have started our corporate with the aim of  “Defining Dreams” 
                            </p>
                            <p>
                                Our primary goal is to provide client satisfaction and to walk you through the entire home-buying process. We support our clients by maintaining mutual understanding, transparency, and providing accurate information in all aspects.
                                </p>
                            <p>
                                Ewen Realtors is working on a project “The Ewen Park” near Majkhali, Ranikhet. The project “The Ewen Park” has two more segments under it “The Ewen Village” and “The Ewen Studio”
                                </p>
                            <p>
                                “The Ewen Park” consists of villas and cottages and it is basically for residential purposes. It is built at Baburkhola village near Majkhali, Ranikhet. 
                            </p>
                        </div>
                        <div className="col-md-6">
                            <img src="/assets/images/about-er.png" style={{width: '100%', borderRadius: '4px'}} />
                        </div>
                    </div>
                </section>

                <p className="my-3">
                    “The Ewen Village” and “The Ewen Studio” are basically for residential as well as commercial purposes. They are under construction near Baburkhola village at Dwarsu. “The Ewen Village” consists of villas and cottages based on a village theme and “The Ewen Studio” consists of studio villas. 
                </p>

                <section className="my-5">
                    <h2 className="text-center">Career In Ewen Realtors</h2>
                    <blockquote className="text-center">“Small aim is a crime; have great aim”</blockquote>
                    <p className="text-center mb-4">We support our team members and inspire them to grow with the company. We offer excellent employee ethics in addition to a better compensation package. We invite imaginative and unique minds to join our working group and grow alongside us.</p>

                    <div className="row m-0 justify-content-center">
                        <div className="col-md-4 mb-3">
                            <div className="card p-3 border-0 mb-3">
                                <div className="text-center">
                                    <img src="/assets/images/jyoti-rautela.jpeg" style={{height: '200px', width: '200px', borderRadius: '50%', objectFit: 'cover', border: '4px solid #eee', padding: '5px'}} />
                                    <p style={{fontSize: '13px'}} className="m-0 mb-2 mt-3 text-center"><i>My decision to join Ewen Realtors was one of the most rewarding decisions I've ever made. Being a part of such an exceptional organization is an honor and I would recommend Ewen Realtors to anyone aspiring to career advancement.</i></p>
                                    <div className="pt-0" style={{fontSize: '1rem', fontWeight: '600'}}>Jyoti Rautela</div>
                                    <div style={{fontSize: '13px'}}>(Sr. Key Account Manager)</div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4 mb-3">
                            <div className="card p-3 border-0 mb-3">
                                <div className="text-center">
                                    <img src="/assets/images/anam-fatima.jpeg" style={{height: '200px', width: '200px', borderRadius: '50%', objectFit: 'cover', border: '4px solid #eee', padding: '5px'}} />
                                    <p style={{fontSize: '13px'}} className="m-0 mb-2 mt-3 text-center"><i>Ewen Realtors has provided me with numerous opportunities to develop my skills as a professional, which allowed me to achieve both personal and professional growth. Working here is an incredibly rewarding experience.</i></p>
                                    <div className="pt-0" style={{fontSize: '1rem', fontWeight: '600'}}>Anam Fatima</div>
                                    <div style={{fontSize: '13px'}}>(Sr. Key Account Manager)</div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4 mb-3">
                            <div className="card p-3 border-0 mb-3">
                                <div className="text-center">
                                    <img src="/assets/images/talia-khalid.jpg" style={{height: '200px', width: '200px', borderRadius: '50%', objectFit: 'cover', border: '4px solid #eee', padding: '5px'}} />
                                    <p style={{fontSize: '13px'}} className="m-0 mb-2 mt-3 text-center"><i>The opportunities for professional growth and development also made it an ideal place to build a successful career. Ewen Realtors is an excellent employment opportunity if you are looking for a dynamic and fulfilling environment to work in.</i></p>
                                    <div className="pt-0" style={{fontSize: '1rem', fontWeight: '600'}}>Talia Khalid</div>
                                    <div style={{fontSize: '13px'}}>(Content Writer)</div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4 mb-3">
                            <div className="card p-3 border-0 mb-3">
                                <div className="text-center">
                                    <img src="/assets/images/dinesh-samant.jpg" style={{height: '200px', width: '200px', borderRadius: '50%', objectFit: 'cover', border: '4px solid #eee', padding: '5px'}} />
                                    <p style={{fontSize: '13px'}} className="m-0 mb-2 mt-3 text-center"><i>Working at Ewen Realtors is an absolute pleasure. The company's commitment to excellence, collaborative work environment, and dedication to employee development made it a standout organization.</i></p>
                                    <div className="pt-0" style={{fontSize: '1rem', fontWeight: '600'}}>Dinesh Samant</div>
                                    <div style={{fontSize: '13px'}}>(SMM)</div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4 mb-3">
                            <div className="card p-3 border-0 mb-3">
                                <div className="text-center">
                                    <img src="/assets/images/abhishek-arya.png" style={{height: '200px', width: '200px', borderRadius: '50%', objectFit: 'cover', border: '4px solid #eee', padding: '5px'}} />
                                    <p style={{fontSize: '13px'}} className="m-0 mb-2 mt-3 text-center"><i>As a member of the team at Ewen Realtors, I was impressed by the company's culture and support. Everyone was always willing to lend a hand, offer advice, or share knowledge, which created a positive and productive work environment.</i></p>
                                    <div className="pt-0" style={{fontSize: '1rem', fontWeight: '600'}}>Abhishek Arya</div>
                                    <div style={{fontSize: '13px'}}>(Web Developer)</div>
                                </div>
                            </div>
                        </div>
                    </div>

                </section>

                <section className="py-4" id="form">
                    <div className="text-center">
                        <h3>Upload Your Resume</h3>
                        <p className="text-center">We want you to join our company, and we will support all your innovative and one-of-a-kind ideas, as well as try to get the best out of you.</p>

                        {/* FORM  */}

                        <CareerForm />

                    </div>
                </section>
            </div>
        </div>
    );
}

export default Careers;