import React, { Component } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';
import { useState, useEffect } from "react";
import '../css/PostView.css';
import BlogSearch from '../layout/BlogSearch';
import Helmet from 'react-helmet';
import moment from 'moment';

class BlogLatest extends Component {
  constructor(props) {
    super(props);
    this.state = {
      posts: [],
    };
  }

  componentDidMount() {
    axios.get("https://old.ewenrealtors.com/wp-json/wp/v2/posts?_embed&per_page=3").then(posts => {
      this.setState({
        posts: posts.data
      });
    });
  }

  createMarkup(html) {
    return { __html: html };
  }

  render() {
    return (
      <>
            {
                this.state.posts.map(post => (
                    <div className='my-4 card rounded-3 border-0' id="latestPost">
                        <Link to={`/blog/${post.slug}`} key={post.id}>
                        <div className="row m-0 align-items-center">
                            <div className='col-md-6 p-0'>
                                <img alt={post.title.rendered} src={post.yoast_head_json.og_image[0].url} style={{height: '100%', width: '100%', objectFit: 'contain', backgroundColor: '#fff', borderRadius: '8px' }} />
                            </div>
                            <div className='col-md-6'>
                              <div className="d-flex" style={{flexDirection: 'column', height: '100%'}}>
                                <div style={{flex: '1'}} className="d-flex align-items-center">
                                  <font dangerouslySetInnerHTML={{__html: post.title.rendered}} style={{fontWeight: '800'}} id="blogTitle" />
                                </div>
                                <div className='py-1'>
                                  <p className='pt-1 postExcerpt'>
                                      <font dangerouslySetInnerHTML={{__html: post.excerpt.rendered.substring(0,250)+'...&nbsp;'}} style={{fontSize: '11px', color: 'dimgray'}} />
                                  </p>
                                </div>
                                <div className='d-flex align-items-center justify-content-between'>
                                    <div style={{fontSize: '11px', color: 'rgba(0,0,0,0.8)'}}>
                                        <i className='fas fa-globe-asia'></i> {moment(post.date).format("MMM DD, YYYY")}
                                    </div>
                                    <div>
                                        <img src='/logo.svg' className='postUser' />
                                    </div>
                                </div>
                              </div>
                            </div>
                        </div>
                        </Link>
                    </div>
                ))
            }
      </>
    );
  }

}

export default BlogLatest;