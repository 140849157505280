import Helmet from 'react-helmet';
import { NavLink, Link } from 'react-router-dom';

const Faq = () => {
    document.title = "FAQ's - Ewen Realtors";

    const faqSchema = 
    {
        "@context": "https://schema.org",
        "@type": "FAQPage",
        "name" : "Ewen Realtors",
        "image" : "https://ewenrealtors.com/logo.svg",
        "telephone" : "+91 852-616-4444",
        "address" : {
          "@type" : "PostalAddress",
          "streetAddress" : "Ewen Realtors NH 109, Nainital Road, Kathgodam, Uttarakhand 263139"
        },
        "mainEntity": [{
          "@type": "Question",
          "name": "Who is Ewen Realtors?",
          "acceptedAnswer": {
            "@type": "Answer",
            "text": "Ewen Realtors is an emerging real estate company in Uttarakhand. It is a startup in Uttarakhand with a young, passionate team working professionally to make your real estate journey worthwhile."
          }
        },{
          "@type": "Question",
          "name": "Where are the headquarters of Ewen Realtors?",
          "acceptedAnswer": {
            "@type": "Answer",
            "text": "Ewen Realtors headquarters is in Kathgodam, Haldwani Uttarakhand."
          }
        },{
          "@type": "Question",
          "name": "What does Ewen Realtors working on?",
          "acceptedAnswer": {
            "@type": "Answer",
            "text": "Ewen Realtors is working on a project “The Ewen Park” in Ranikhet, Uttarakhand which is popularly known as Queen’s Meadow. It is at an elevation of 6132ft above sea level."
          }
        },{
          "@type": "Question",
          "name": "Where is Ranikhet?",
          "acceptedAnswer": {
            "@type": "Answer",
            "text": "Ranikhet is in the Almora district in Uttarakhand. It is 1869 meters above sea level and experiences a comforting climate throughout the year."
          }
        },{
          "@type": "Question",
          "name": "What is “The Ewen Park”?",
          "acceptedAnswer": {
            "@type": "Answer",
            "text": "“The Ewen Park” is the name of the township being built in Ranikhet, Uttarakhand by Ewen Realtors. There are different phases of “The Ewen Park”."
          }
        },{
          "@type": "Question",
          "name": "How many phases are there in “The Ewen Park”?",
          "acceptedAnswer": {
            "@type": "Answer",
            "text": "There are three different phases in “The Ewen Park”. The first phase is “The Ewen Park”- Royal Villas which is in Baburkhola, the Second phase is named “The Ewen Park” - Royal Avenue in Dwarsun, and the third is known as “The Ewen Park” - Hill Village which is in Galli Basuyra."
          }
        },{
          "@type": "Question",
          "name": "What makes “The Ewen Park” different from other townships in the mountains?",
          "acceptedAnswer": {
            "@type": "Answer",
            "text": "“The Ewen Park” has many breathtaking views including views of the Himalayan peaks like Nanda Devi, Trishul Complex, etc, stunning views of sunrise and sunsetting in the snowcapped Himalayas, Almora glittering and shimmering at night, and so on."
          }
        },{
          "@type": "Question",
          "name": "What amenities do Ewen Realtors are offering in “The Ewen Park”?",
          "acceptedAnswer": {
            "@type": "Answer",
            "text": "“The Ewen Park” is offering all the amenities ranging from basic to top-notch amenities to make your life easier and more comforting while living in the mountains."
          }
        },{
          "@type": "Question",
          "name": "What amenities are furnished in “The Ewen Park”?",
          "acceptedAnswer": {
            "@type": "Answer",
            "text": "The amenities which are furnished in the township are 24/7 electricity and water supply, parking space, 24/7 cafe, and dining service, gym, spa, on-site maintenance, UHF-based entry, wi-fi and mobile network, and so on."
          }
        },{
          "@type": "Question",
          "name": "Is the project “The Ewen Park” RERA approved?",
          "acceptedAnswer": {
            "@type": "Answer",
            "text": "Phase 1 (Baburkhola) and phase 3 (Galli Basuyra) are LDA (Local development authority) approved whereas phase 2 (Dwarsun) is going for RERA."
          }
        },{
          "@type": "Question",
          "name": "What are the features of “The Ewen Park” township?",
          "acceptedAnswer": {
            "@type": "Answer",
            "text": "“The Ewen Park” has many breathtaking views, a serene environment, a delightful climate, top-notch amenities, a natural water source, and so on. This township offers comfort and convenience for you and your family."
          }
        }]
    }

    return(
        <div className="text-start pt-5">
            <Helmet>
                <meta name="description" content="FAQ's - Ewen Realtors" />
                <link rel="canonical" href="https://ewenrealtors.com/faqs" />
                <meta property="og:locale" content="en_US" />
                <meta property="og:type" content="website" />
                <meta property="og:title" content="FAQ's | Ewen Realtors - Best Real Estate in India" />
                <meta property="og:description" content="FAQ's - Ewen Realtors" />
                <meta property="og:url" content="https://ewenrealtors.com/faqs" />
                <meta property="og:site_name" content="Ewen Realtors" />
                <meta property="article:publisher" content="https://www.facebook.com/ewenrealtors" />
                <meta property="article:modified_time" content="2022-03-23T06:20:43+00:00" />
                <meta property="og:image:width" content="800" />
                <meta property="og:image:height" content="450" />
                <meta property="og:image:type" content="image/jpeg" /> 
                
                {/* JSON-LD markup generated by Google Structured Data Markup Helper. */}
                <script type="application/ld+json">
                    {JSON.stringify(faqSchema)}
                </script>
            </Helmet>
            <div className='container col-md-6' style={{color: 'rgba(0,0,0,0.75)'}}>
                <h1>FAQ's</h1>

                <div className="my-3 card">
                    <h2 className="accordion-header" id="headingOne">
                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="false" aria-controls="collapseOne">
                        Who is Ewen Realtors? 
                        </button>
                    </h2>
                    <div id="collapseOne" className="accordion-collapse collapse" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                        <div className="accordion-body">
                        Ewen Realtors is an emerging real estate company in Uttarakhand. It is a startup in Uttarakhand with a young, passionate team working professionally to make your real estate journey worthwhile.
                        </div>
                    </div>
                </div>

                <div className="my-3 card">
                    <h2 className="accordion-header" id="headingTwo">
                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                        Where are the headquarters of Ewen Realtors?
                        </button>
                    </h2>
                    <div id="collapseTwo" className="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#accordionExample">
                        <div className="accordion-body">
                        Ewen Realtors headquarters is in Kathgodam, Haldwani Uttarakhand. 
                        </div>
                    </div>
                </div>

                <div className="my-3 card">
                    <h2 className="accordion-header" id="headingThree">
                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                        What does Ewen Realtors working on?
                        </button>
                    </h2>
                    <div id="collapseThree" className="accordion-collapse collapse" aria-labelledby="headingThree" data-bs-parent="#accordionExample">
                        <div className="accordion-body">
                        Ewen Realtors is working on a project “The Ewen Park” in Ranikhet, Uttarakhand which is popularly known as Queen’s Meadow. It is at an elevation of 6132ft above sea level.  
                        </div>
                    </div>
                </div>

                <div className="my-3 card">
                    <h2 className="accordion-header" id="headingFour">
                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour">
                        Where is Ranikhet?
                        </button>
                    </h2>
                    <div id="collapseFour" className="accordion-collapse collapse" aria-labelledby="headingFour" data-bs-parent="#accordionExample">
                        <div className="accordion-body">
                        Ranikhet is in the Almora district in Uttarakhand. It is 1869 meters above sea level and experiences a comforting climate throughout the year.  
                        </div>
                    </div>
                </div>

                <div className="my-3 card">
                    <h2 className="accordion-header" id="headingFive">
                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFive" aria-expanded="false" aria-controls="collapseFive">
                        What is “The Ewen Park”?
                        </button>
                    </h2>
                    <div id="collapseFive" className="accordion-collapse collapse" aria-labelledby="headingFive" data-bs-parent="#accordionExample">
                        <div className="accordion-body">
                        “The Ewen Park” is the name of the township being built in Ranikhet, Uttarakhand by Ewen Realtors. There are different phases of “The Ewen Park”.  
                        </div>
                    </div>
                </div>

                <div className="my-3 card">
                    <h2 className="accordion-header" id="headingSix">
                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseSix" aria-expanded="false" aria-controls="collapseSix">
                        How many phases are there in “The Ewen Park”?
                        </button>
                    </h2>
                    <div id="collapseSix" className="accordion-collapse collapse" aria-labelledby="headingSix" data-bs-parent="#accordionExample">
                        <div className="accordion-body">
                        There are three different phases in “The Ewen Park”. The first phase is “The Ewen Park”- Royal Villas which is in Baburkhola, the Second phase is named “The Ewen Park” - Royal Avenue in Dwarsun, and the third is known as “The Ewen Park” - Hill Village which is in Galli Basuyra.  
                        </div>
                    </div>
                </div>

                <div className="my-3 card">
                    <h2 className="accordion-header" id="headingSeven">
                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseSeven" aria-expanded="false" aria-controls="collapseSeven">
                        What makes “The Ewen Park” different from other townships in the mountains?
                        </button>
                    </h2>
                    <div id="collapseSeven" className="accordion-collapse collapse" aria-labelledby="headingSeven" data-bs-parent="#accordionExample">
                        <div className="accordion-body">
                        “The Ewen Park” has many breathtaking views including views of the Himalayan peaks like Nanda Devi, Trishul Complex, etc, stunning views of sunrise and sunsetting in the snowcapped Himalayas, Almora glittering and shimmering at night, and so on. 
                        </div>
                    </div>
                </div>

                <div className="my-3 card">
                    <h2 className="accordion-header" id="headingEight">
                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseEight" aria-expanded="false" aria-controls="collapseEight">
                        What amenities do Ewen Realtors are offering in “The Ewen Park”?
                        </button>
                    </h2>
                    <div id="collapseEight" className="accordion-collapse collapse" aria-labelledby="headingEight" data-bs-parent="#accordionExample">
                        <div className="accordion-body">
                        “The Ewen Park” is offering all the amenities ranging from basic to top-notch amenities to make your life easier and more comforting while living in the mountains. 
                        </div>
                    </div>
                </div>

                <div className="my-3 card">
                    <h2 className="accordion-header" id="headingNine">
                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseNine" aria-expanded="false" aria-controls="collapseNine">
                        What amenities are furnished in “The Ewen Park”?
                        </button>
                    </h2>
                    <div id="collapseNine" className="accordion-collapse collapse" aria-labelledby="headingNine" data-bs-parent="#accordionExample">
                        <div className="accordion-body">
                        The amenities which are furnished in the township are 24/7 electricity and water supply, parking space, 24/7 cafe, and dining service, gym, spa, on-site maintenance, UHF-based entry, wi-fi and mobile network, and so on. 
                        </div>
                    </div>
                </div>

                <div className="my-3 card">
                    <h2 className="accordion-header" id="headingTen">
                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTen" aria-expanded="false" aria-controls="collapseTen">
                        Is the project “The Ewen Park” RERA approved?
                        </button>
                    </h2>
                    <div id="collapseTen" className="accordion-collapse collapse" aria-labelledby="headingTen" data-bs-parent="#accordionExample">
                        <div className="accordion-body">
                        Phase 1 (Baburkhola)  and phase 3 (Galli Basuyra) are LDA (Local development authority) approved whereas phase 2 (Dwarsun) is going for RERA.
                        </div>
                    </div>
                </div>

                <div className="my-3 card">
                    <h2 className="accordion-header" id="headingEleven">
                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseEleven" aria-expanded="false" aria-controls="collapseEleven">
                        What are the features of “The Ewen Park” township?
                        </button>
                    </h2>
                    <div id="collapseEleven" className="accordion-collapse collapse" aria-labelledby="headingEleven" data-bs-parent="#accordionExample">
                        <div className="accordion-body">
                        “The Ewen Park” has many breathtaking views, a serene environment, a delightful climate, top-notch amenities, a natural water source, and so on. This township offers comfort and convenience for you and your family. 
                        </div>
                    </div>
                </div>

            </div>
        </div>
    );
}


export default Faq;