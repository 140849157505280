import { NavLink } from 'react-router-dom';
import ContactForm from '../ContactForm';
import AllProjects from '../services/AllProjects';
import Amenities from './Amenities';
import CallToAction from './CallToAction';
import RoyalVillasCarousel from './Carousel/RoyalVillasCarousel';
import './Project.css';
import ReachUs from './ReachUs';
import Helmet from 'react-helmet';

const RoyalVillas = () => {
    document.title = "Royal Villas - The Ewen Park | Ewen Realtors";
    const ldJson = 
    {
        "@context" : "http://schema.org",
        "@type" : "Real Estate Business",
        "name" : "Ewen Realtors",
        "image" : "https://ewenrealtors.com/logo.svg",
        "telephone" : "+91 852-616-4444",
        "address" : {
            "@type" : "PostalAddress",
            "streetAddress" : "Ewen Realtors NH 109, Nainital Road, Kathgodam, Uttarakhand 263139"
        }
    }
    return(
        <>
            <Helmet>
                <meta name="description" content="Our project ‘The Ewen Park’ is a 52 Nali project (12480 sq. yards or 112320 sq ft) located near Ranikhet. Ranikhet is widely popular as” Queen Of Land” It has a myriad range of vegetation and wildlife, experiences delightful weather throughout the year, has many tourist spots, and is the hub of many adventurous activities." />
                <link rel="canonical" href="https://ewenrealtors.com/projects/royal-villas" />
                <meta property="og:locale" content="en_US" />
                <meta property="og:type" content="website" />
                <meta property="og:title" content="Royal | Ewen Realtors - Best Real Estate in India" />
                <meta property="og:description" content="Our project ‘The Ewen Park’ is a 52 Nali project (12480 sq. yards or 112320 sq ft) located near Ranikhet. Ranikhet is widely popular as” Queen Of Land” It has a myriad range of vegetation and wildlife, experiences delightful weather throughout the year, has many tourist spots, and is the hub of many adventurous activities." />
                <meta property="og:url" content="https://ewenrealtors.com/projects/royal-villas" />
                <meta property="og:site_name" content="Ewen Realtors" />
                <meta property="article:publisher" content="https://www.facebook.com/ewenrealtors" />
                <meta property="article:modified_time" content="2022-03-23T06:20:43+00:00" />
                <meta property="og:image:width" content="800" />
                <meta property="og:image:height" content="450" />
                <meta property="og:image:type" content="image/jpeg" /> 
                
                {/* JSON-LD markup generated by Google Structured Data Markup Helper. */}
                <script type="application/ld+json">
                    {JSON.stringify(ldJson)}
                </script>
            </Helmet>
            <AllProjects/>
            
            {/* <img src='/assets/Banners/3.jpg' className='projectBanner' /> */}
            <div className='my-3 container text-start'>
                <div className='row m-0 align-items-center my-md-5 flex-row-reverse'>
                    <div className='col-md-5 text-center px-md-5'>
                        <RoyalVillasCarousel/>
                    </div>
                    <div className='col-md-7'>
                        <h2 className='projectHeading text-start'>Royal Villas/Apartments</h2>
                        <p className='text-secondary font-italic'>Tranquil and extravagant lifestyle.</p>
                        <p>Welcome to The Royal Villas, a residential project nestled in the lap of nature near Ranikhet, in Baburkhola. This is a 52 Nali (12480 sq. yards or 112320 sq ft) project offering a breathtaking view of the majestic Himalayas.</p>
                        <p>You will also witness a breathtaking sunrise in the morning as all the clouds come down to kiss you good morning, and in the evening, you can see Almora shimmering from the lights.&nbsp;</p>
                        <p>That&rsquo;s not it this phase is also surrounded by dense vegetation of oak, deodar, and pine trees, this place is a haven for nature lovers. The area is also home to a diverse range of fauna, adding to the charm and natural beauty of the location.</p>
                    </div>
                    <Amenities/>
                </div>

            </div>

            <CallToAction/>

            <div className='my-3 container text-start'>
                {/* Master Plan  */}
                <div className='py-4 mt-3'>
                    <h2 className='text-center pb-3'>Master Plan</h2>
                    <div class="row m-0 p-0 align-items-center">
                        <div class="nav flex-fill nav-pills me-3 col-md-3 d-flex justify-content-center p-0" id="v-pills-tab" role="tablist" aria-orientation="vertical">
			   <a href="https://drive.google.com/file/d/1riSlC5DEeu7ScdGqothbHHrodLnXTW0O/view?usp=drive_link" target='_blank'>
                                <button class="nav-link" id="v-pills-messages-tab" data-bs-toggle="pill" type="button" role="tab" aria-controls="tab4" aria-selected="false">Layout</button>
                            </a>
                            <button class="nav-link" id="v-pills-messages-tab" data-bs-toggle="pill" data-bs-target="#tab3" type="button" role="tab" aria-controls="tab3" aria-selected="false">Location</button>
			<a href="https://drive.google.com/file/d/1AeyENfqG7NvKOs_4ghRBpDrSpWCpsrtR/view?usp=drive_link" target="_blank">
                            <button class="nav-link" id="v-pills-messages-tab" data-bs-toggle="pill" data-bs-target="#tab4" type="button" role="tab" aria-controls="tab4" aria-selected="false">Payment Plan</button>
			</a>
                        </div>
                        <div class="tab-content col-md-12 text-center" id="v-pills-tabContent">
                            <div class="tab-pane fade" id="tab3" role="tabpanel" aria-labelledby="v-pills-messages-tab" tabindex="0">
                                <a href='/assets/projects/Royal Villas/Royal Villas Location.jpg' target='_blank'>
                                    <img src='/assets/projects/Royal Villas/Royal Villas Location.jpg' style={{width: '100%', maxHeight: '800px', objectFit: 'contain', borderRadius: '10px'}} />
                                </a>
                            </div>
                        </div>
                    </div>
                </div>

                {/* Location Map  */}
                <div className='py-4'>
                    <h2 className='text-center mb-3'>Location Map</h2>
                    <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d13868.862888493582!2d79.5488259!3d29.6555139!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x4152b85494da6aef!2sTHE%20EWEN%20PARK!5e0!3m2!1sen!2sin!4v1667987968814!5m2!1sen!2sin"allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade" style={{width: '100%', height: '400px', borderRadius: '10px'}} className="shadow-sm"></iframe>
                </div>

                <ReachUs/>

                <ContactForm/>
            </div>

        </>
    );
}

export default RoyalVillas;