import React, { Component } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';
import { useState, useEffect } from "react";
import '../css/PostView.css';
import BlogSearch from '../layout/BlogSearch';
import moment from 'moment';

class RecentPosts extends Component {
  constructor(props) {
    super(props);
    this.state = {
      posts: [],
    };
  }

  componentDidMount() {
    axios.get("https://old.ewenrealtors.com/wp-json/wp/v2/posts?_embed&per_page=5").then(posts => {
      this.setState({
        posts: posts.data
      });
    });
  }

  createMarkup(html) {
    return { __html: html };
  }

  render() {
    return (
      <div className='container col-md-12 text-start p-0'>
        {
            this.state.posts.map(post => (
                <a href={`/blog/${post.slug}`} key={post.id} target="_blank">
                    <div className='card shadow-0 p-0 my-3 border-0'>
                        <div className='row m-0 align-items-center justify-content-between'>
                            <div className='col-md-2 p-0 text-center'>
                                <img alt={post.title.rendered} src={post.yoast_head_json.og_image[0].url} className="recentFeaturedImage m-0" />
                            </div>
                            <div className='col-md-10 px-md-1'>
                                <p className='recentTitle py-0 my-0' dangerouslySetInnerHTML={{__html: post.title.rendered}} />
                            </div>
                        </div>
                    </div>
                </a>
            ))
        }
      </div>
    );
  }

}

export default RecentPosts;