import { NavLink, Link } from 'react-router-dom';

const Pricing = () => {
    return(
        <>
        <div className='container p-md-4 py-4' style={{background: 'url("/assets/Banners/project_background.png")', backgroundSize: 'cover', backgroundPosition: 'top-center'}}>
                    <div className='text-center'>
                        <h2 className='text-uppercase font-weight-bold my-4'>Projects & Pricing</h2>
                    </div>
                    <p className='text-center'> The Ewen Park township promises you a well-balanced lifestyle. It is being built in the lap of nature to provide you with the best.</p>  

                    <p className='text-center'> This township is located in Queen Hills of Uttarakhand i.e. Ranikhet. It is one of the most serene and beautiful hill stations in Uttarakhand.</p>  

                    <div className='mt-5'>
                        <div className='row m-0 align-items-center justify-content-center'>
                            {/* Royal Villas  */}
                            <div className='col-md-4 mb-3'>
                                <div className='card bg-dark rounded-5 border-0 p-0'>
                                    <img src="/assets/images/royal-apartments.jpg" style={{borderRadius: '8px 8px 0px 0px', height: '230px', objectFit: 'cover', objectPosition:'top'}} />
                                    <div className='d-flex justify-content-center'>
                                        <div className='p-3 col-md-11' style={{fontFamily: 'roboto'}}>
                                            <div className='text-center'>
                                                <img src="/assets/images/PREMIUM.svg" style={{height:'18px'}} />
                                                <p className='text-uppercase text-center' style={{color: 'gold', fontFamily: 'roboto', letterSpacing: '-0.25px', fontSize: '18px', fontWeight: '700'}}>Royal Villas & Apartments</p>
                                            </div>
                                            {/* <h3 className='py-2 font-weight-bolder'>₹ 37,00,000*</h3> */}
                                            <div className='d-flex mt-2'>
                                                <div className=''>
                                                    <i className='fas fa-check-circle text-warning fa-sm' style={{position: 'relative', top: '-3px'}}></i>
                                                </div>
                                                <div className='px-2' style={{fontSize: '13px', color: 'rgba(255,255,255,0.8)'}}>On Site Maintenance</div>
                                            </div>

                                            <div className='d-flex'>
                                                <div className=''>
                                                    <i className='fas fa-check-circle text-warning fa-sm' style={{position: 'relative', top: '-3px'}}></i>
                                                </div>
                                                <div className='px-2' style={{fontSize: '13px', color: 'rgba(255,255,255,0.8)'}}>24/7 Water</div>
                                            </div>
                                            <div className='d-flex'>
                                                <div className=''>
                                                    <i className='fas fa-check-circle text-warning fa-sm' style={{position: 'relative', top: '-3px'}}></i>
                                                </div>
                                                <div className='px-2' style={{fontSize: '13px', color: 'rgba(255,255,255,0.8)'}}>24/7 Electricity</div>
                                            </div>
                                            <div className='d-flex'>
                                                <div className=''>
                                                    <i className='fas fa-check-circle text-warning fa-sm' style={{position: 'relative', top: '-3px'}}></i>
                                                </div>
                                                <div className='px-2' style={{fontSize: '13px', color: 'rgba(255,255,255,0.8)'}}>Wi-Fi & Mobile Network</div>
                                            </div>
                                            <div className='d-flex'>
                                                <div className=''>
                                                    <i className='fas fa-check-circle text-warning fa-sm' style={{position: 'relative', top: '-3px'}}></i>
                                                </div>
                                                <div className='px-2' style={{fontSize: '13px', color: 'rgba(255,255,255,0.8)'}}>Entry – UHF Based</div>
                                            </div>
                                            <div className='d-flex'>
                                                <div className=''>
                                                    <i className='fas fa-check-circle text-warning fa-sm' style={{position: 'relative', top: '-3px'}}></i>
                                                </div>
                                                <div className='px-2' style={{fontSize: '13px', color: 'rgba(255,255,255,0.8)'}}>Security (CCTV, Guards, etc.)</div>
                                            </div>

                                            <div className='d-flex'>
                                                <div className=''>
                                                    <i className='fas fa-check-circle text-warning fa-sm' style={{position: 'relative', top: '-3px'}}></i>
                                                </div>
                                                <div className='px-2' style={{fontSize: '13px', color: 'rgba(255,255,255,0.8)'}}>Himalayan View</div>
                                            </div>
                                            <div className='text-center text-light py-3'>
                                                {/* <div className='text-center text-primary pb-3' style={{fontSize: '24px', fontWeight: '600'}}>
                                                    <sup>₹</sup>25,00,000<sup>*</sup>
                                                </div> */}
                                                <Link to="/projects/royal-villas">
                                                    <button className='btn btn-warning rounded-pill shadow-none text-capitalize' style={{fontWeight: '500', padding: '6px 50px'}}>Learn More</button>
                                                </Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* Hill Village  */}
                            {/* <div className='col-md-4 mb-3'>
                                <div className='card bg-dark rounded-5 border-0 p-0 shadow-lg'>
                                    <img src="/assets/images/projects/The Ewen Park - Hill Village.jpg" style={{borderRadius: '8px 8px 0px 0px', height: '180px', objectFit: 'cover'}} />
                                    <div className='d-flex justify-content-center'>
                                        <div className='p-3 col-md-11' style={{fontFamily: 'roboto'}}>
                                            <div className='text-center'>
                                                <img src="/assets/images/PRE-LAUNCH.svg" style={{height:'18px'}} />
                                                <p className='text-uppercase text-light text-center' style={{fontFamily: 'roboto', letterSpacing: '-0.25px', fontSize: '18px', fontWeight: '700'}}>The Ewen Park - Hill Village</p>
                                            </div>
                                            <div className='d-flex mt-2'>
                                                <div className=''>
                                                    <i className='fas fa-check-circle text-primary fa-sm' style={{position: 'relative', top: '-3px'}}></i>
                                                </div>
                                                <div className='px-2' style={{fontSize: '13px', color: 'lightgray'}}>On Site Maintenance</div>
                                            </div>
                                            <div className='d-flex'>
                                                <div className=''>
                                                    <i className='fas fa-check-circle text-primary fa-sm' style={{position: 'relative', top: '-3px'}}></i>
                                                </div>
                                                <div className='px-2' style={{fontSize: '13px', color: 'lightgray'}}>Gym & Spa</div>
                                            </div>
                                            <div className='d-flex'>
                                                <div className=''>
                                                    <i className='fas fa-check-circle text-primary fa-sm' style={{position: 'relative', top: '-3px'}}></i>
                                                </div>
                                                <div className='px-2' style={{fontSize: '13px', color: 'lightgray'}}>24/7 Water & Electricity</div>
                                            </div>
                                            <div className='d-flex'>
                                                <div className=''>
                                                    <i className='fas fa-check-circle text-primary fa-sm' style={{position: 'relative', top: '-3px'}}></i>
                                                </div>
                                                <div className='px-2' style={{fontSize: '13px', color: 'lightgray'}}>Wi-Fi & Mobile Network</div>
                                            </div>
                                            <div className='d-flex'>
                                                <div className=''>
                                                    <i className='fas fa-check-circle text-primary fa-sm' style={{position: 'relative', top: '-3px'}}></i>
                                                </div>
                                                <div className='px-2' style={{fontSize: '13px', color: 'lightgray'}}>Entry – UHF Based, Security (CCTV, Guards, etc.)</div>
                                            </div>
                                            <div className='d-flex'>
                                                <div className=''>
                                                    <i className='fas fa-check-circle text-primary fa-sm' style={{position: 'relative', top: '-3px'}}></i>
                                                </div>
                                                <div className='px-2 text-start' style={{fontSize: '13px', color: 'lightgray'}}>Yoga, Sports, Temple, Party Hall, Club House For Senior citizens, Cinema Hall, etc.</div>
                                            </div>
                                            <div className='d-flex'>
                                                <div className=''>
                                                    <i className='fas fa-times-circle text-danger fa-sm' style={{position: 'relative', top: '-3px'}}></i>
                                                </div>
                                                <div className='px-2' style={{fontSize: '13px', color: 'lightgray'}}>Himalayan View</div>
                                            </div>
                                            <div className='text-center py-3'>
                                                <div className='mt-2 text-light' style={{fontSize: '12px'}}>
                                                    <Link to="/projects/hill-village">
                                                        <button className='btn btn-warning rounded-pill shadow-none text-capitalize' style={{fontWeight: '500', padding: '6px 50px'}}>Learn More</button>
                                                    </Link>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div> */}
                            {/* Royal Avenue  */}
                            <div className='col-md-4 mb-3'>
                                <div className='card bg-dark rounded-5 border-0 p-0'>
                                    <img src="/assets/images/projects/The Ewen Park - Himalayan Vista.jpg" style={{borderRadius: '8px 8px 0px 0px', height: '230px', objectFit: 'cover'}} />
                                    <div className='d-flex justify-content-center'>
                                        <div className='p-3 col-md-11' style={{fontFamily: 'roboto'}}>
                                            <div className='text-center'>
                                                <img src="/assets/images/PREMIUM.svg" style={{height:'18px'}} />
                                                <p className='text-uppercase text-center' style={{color: 'gold', fontFamily: 'roboto', letterSpacing: '-0.25px', fontSize: '18px', fontWeight: '700'}}>The Himalayan Vista</p>
                                            </div>
                                            {/* <h3 className='py-2 font-weight-bolder'>₹ 30,00,000*</h3> */}
                                            <div className='d-flex mt-2'>
                                                <div className=''>
                                                    <i className='fas fa-check-circle text-warning fa-sm' style={{position: 'relative', top: '-3px'}}></i>
                                                </div>
                                                <div className='px-2' style={{fontSize: '13px', color: 'rgba(255,255,255,0.8)'}}>On Site Maintenance</div>
                                            </div>
                                            <div className='d-flex'>
                                                <div className=''>
                                                    <i className='fas fa-check-circle text-warning fa-sm' style={{position: 'relative', top: '-3px'}}></i>
                                                </div>
                                                <div className='px-2' style={{fontSize: '13px', color: 'rgba(255,255,255,0.8)'}}>Gym & Spa</div>
                                            </div>
                                            <div className='d-flex'>
                                                <div className=''>
                                                    <i className='fas fa-check-circle text-warning fa-sm' style={{position: 'relative', top: '-3px'}}></i>
                                                </div>
                                                <div className='px-2' style={{fontSize: '13px', color: 'rgba(255,255,255,0.8)'}}>24/7 Water & Electricity</div>
                                            </div>
                                            <div className='d-flex'>
                                                <div className=''>
                                                    <i className='fas fa-check-circle text-warning fa-sm' style={{position: 'relative', top: '-3px'}}></i>
                                                </div>
                                                <div className='px-2' style={{fontSize: '13px', color: 'rgba(255,255,255,0.8)'}}>Wi-Fi & Mobile Network</div>
                                            </div>
                                            <div className='d-flex'>
                                                <div className=''>
                                                    <i className='fas fa-check-circle text-warning fa-sm' style={{position: 'relative', top: '-3px'}}></i>
                                                </div>
                                                <div className='px-2' style={{fontSize: '13px', color: 'rgba(255,255,255,0.8)'}}>Entry – UHF Based, Security (CCTV, Guards, etc.)</div>
                                            </div>
                                            <div className='d-flex'>
                                                <div className=''>
                                                    <i className='fas fa-check-circle text-warning fa-sm' style={{position: 'relative', top: '-3px'}}></i>
                                                </div>
                                                <div className='px-2 text-start' style={{fontSize: '13px', color: 'rgba(255,255,255,0.8)'}}>Yoga, Sports, Club House For Senior citizens, etc.</div>
                                            </div>
                                            <div className='d-flex'>
                                                <div className=''>
                                                    <i className='fas fa-check-circle text-warning fa-sm' style={{position: 'relative', top: '-3px'}}></i>
                                                </div>
                                                <div className='px-2' style={{fontSize: '13px', color: 'rgba(255,255,255,0.8)'}}>Himalayan View</div>
                                            </div>
                                            <div className='text-center text-light py-3'>
                                                {/* <div className='text-center text-primary pb-3' style={{fontSize: '24px', fontWeight: '600'}}>
                                                    <sup>₹</sup>28,00,000<sup>*</sup>
                                                </div> */}
                                                <Link to="/projects/himalayan-vista">
                                                    <button className='btn btn-warning rounded-pill shadow-none text-capitalize' style={{fontWeight: '500', padding: '6px 50px'}}>Learn More</button>
                                                </Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            {/* Kasar Himalayan Chalet  */}
                            <div className='col-md-4 mb-3'>
                                <div className='card bg-dark rounded-5 border-0 p-0'>
                                    <img src="/assets/images/projects/kasar.jpg" style={{borderRadius: '8px 8px 0px 0px', height: '230px', objectFit: 'cover'}} />
                                    <div className='d-flex justify-content-center'>
                                        <div className='p-3 col-md-11' style={{fontFamily: 'roboto'}}>
                                            <div className='text-center'>
                                                <img src="/assets/images/PREMIUM.svg" style={{height:'18px'}} />
                                                <p className='text-uppercase text-center' style={{color: 'gold', fontFamily: 'roboto', letterSpacing: '-0.25px', fontSize: '18px', fontWeight: '700'}}>Kasar Himalayan Chalet</p>
                                            </div>
                                            {/* <h3 className='py-2 font-weight-bolder'>₹ 30,00,000*</h3> */}
                                            <div className='d-flex mt-2'>
                                                <div className=''>
                                                    <i className='fas fa-check-circle text-warning fa-sm' style={{position: 'relative', top: '-3px'}}></i>
                                                </div>
                                                <div className='px-2' style={{fontSize: '13px', color: 'rgba(255,255,255,0.8)'}}>On Site Maintenance</div>
                                            </div>
                                            <div className='d-flex'>
                                                <div className=''>
                                                    <i className='fas fa-check-circle text-warning fa-sm' style={{position: 'relative', top: '-3px'}}></i>
                                                </div>
                                                <div className='px-2' style={{fontSize: '13px', color: 'rgba(255,255,255,0.8)'}}>Gym & Spa</div>
                                            </div>
                                            <div className='d-flex'>
                                                <div className=''>
                                                    <i className='fas fa-check-circle text-warning fa-sm' style={{position: 'relative', top: '-3px'}}></i>
                                                </div>
                                                <div className='px-2' style={{fontSize: '13px', color: 'rgba(255,255,255,0.8)'}}>24/7 Water & Electricity</div>
                                            </div>
                                            <div className='d-flex'>
                                                <div className=''>
                                                    <i className='fas fa-check-circle text-warning fa-sm' style={{position: 'relative', top: '-3px'}}></i>
                                                </div>
                                                <div className='px-2' style={{fontSize: '13px', color: 'rgba(255,255,255,0.8)'}}>Wi-Fi & Mobile Network</div>
                                            </div>
                                            <div className='d-flex'>
                                                <div className=''>
                                                    <i className='fas fa-check-circle text-warning fa-sm' style={{position: 'relative', top: '-3px'}}></i>
                                                </div>
                                                <div className='px-2' style={{fontSize: '13px', color: 'rgba(255,255,255,0.8)'}}>Entry – UHF Based, Security (CCTV, Guards, etc.)</div>
                                            </div>
                                            <div className='d-flex'>
                                                <div className=''>
                                                    <i className='fas fa-check-circle text-warning fa-sm' style={{position: 'relative', top: '-3px'}}></i>
                                                </div>
                                                <div className='px-2 text-start' style={{fontSize: '13px', color: 'rgba(255,255,255,0.8)'}}>Yoga, Sports, Club House For Senior citizens, etc.</div>
                                            </div>
                                            <div className='d-flex'>
                                                <div className=''>
                                                    <i className='fas fa-check-circle text-warning fa-sm' style={{position: 'relative', top: '-3px'}}></i>
                                                </div>
                                                <div className='px-2' style={{fontSize: '13px', color: 'rgba(255,255,255,0.8)'}}>Himalayan View</div>
                                            </div>
                                            <div className='text-center text-light py-3'>
                                                {/* <div className='text-center text-primary pb-3' style={{fontSize: '24px', fontWeight: '600'}}>
                                                    <sup>₹</sup>28,00,000<sup>*</sup>
                                                </div> */}
                                                <Link to="/projects/kasar-himalayan-chalet">
                                                    <button className='btn btn-warning rounded-pill shadow-none text-capitalize' style={{fontWeight: '500', padding: '6px 50px'}}>Learn More</button>
                                                </Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <p className='mt-4 mt-md-5'> Also, “The Ewen Park” has three phases in three different locations capturing the beautiful side of life, a clean environment, abundant space, and seamless connectivity.</p>  

                    <p> It's a serene world that embraces green life. Modern architecture merges seamlessly with the natural landscape.</p>  

                    <p> Enjoy wide expanses of greenery that provide ample relief.</p>  
                </div>
        </>
    );
}

export default Pricing;