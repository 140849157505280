import '../css/Partner.css';
import Helmet from 'react-helmet';
import { NavLink, Link } from 'react-router-dom';

const TnC = () => {
    document.title = "Terms & Conditions - Ewen Realtors";
    const ldJson = 
    {
        "@context" : "http://schema.org",
        "@type" : "Real Estate Business",
        "name" : "Ewen Realtors",
        "image" : "https://ewenrealtors.com/logo.svg",
        "telephone" : "+91 852-616-4444",
        "address" : {
          "@type" : "PostalAddress",
          "streetAddress" : "Ewen Realtors NH 109, Nainital Road, Kathgodam, Uttarakhand 263139"
        }
    }

    return(
        <div className="text-start pt-5">
            <Helmet>
                <meta name="description" content="Our terms and conditions apply to all uses, accesses, and browsing of our website; therefore, if you disagree with any part of these terms and conditions, you should not use our website." />
                <link rel="canonical" href="https://ewenrealtors.com/terms-and-conditions" />
                <meta property="og:locale" content="en_US" />
                <meta property="og:type" content="website" />
                <meta property="og:title" content="Terms & Conditions | Ewen Realtors - Best Real Estate in India" />
                <meta property="og:description" content="Our terms and conditions apply to all uses, accesses, and browsing of our website; therefore, if you disagree with any part of these terms and conditions, you should not use our website." />
                <meta property="og:url" content="https://ewenrealtors.com/terms-and-conditions" />
                <meta property="og:site_name" content="Ewen Realtors" />
                <meta property="article:publisher" content="https://www.facebook.com/ewenrealtors" />
                <meta property="article:modified_time" content="2022-03-23T06:20:43+00:00" />
                <meta property="og:image:width" content="800" />
                <meta property="og:image:height" content="450" />
                <meta property="og:image:type" content="image/jpeg" /> 
                
                {/* JSON-LD markup generated by Google Structured Data Markup Helper. */}
                <script type="application/ld+json">
                    {JSON.stringify(ldJson)}
                </script>
            </Helmet>
            <div className='container col-md-6 mb-5' style={{color: 'rgba(0,0,0,0.75)'}}>

                <h1>Terms and Conditions</h1>
                <p>The following terms and conditions regulate your use of our website.</p>

                <p>Our terms and conditions apply to all uses, accesses, and browsing of our website; therefore, if you disagree with any part of these terms and conditions, you should not use our website.</p>

                <p>When you register with our website, submit any information to our website, or use any of the services we provide on our website, you will be asked to expressly agree to these terms and conditions.</p>

                <p>Use of our website or agreement to our terms and conditions requires that you be at least 18 years old.</p> 

                <p>When you use our website or agree to these terms and conditions, you depict and certify to us that you are at least 18 years old.</p>

                <p>By using our website or agreeing to these terms and conditions, you consent to the utilization of cookies in accordance with our private information and cookies policy.</p>

                <h2>What Do You Need To Understand?</h2>
                
                <ul>
                    <li>
                        As part of the registration process or granting access to use our resources, you will be required to provide certain information about yourself (such as identification, contact details, etc.).
                    </li>
                    <li>
                        You consent that any data you provide will always be correct, valid, and up-to-date.
                    </li>
                    <li>
                        If you use any arrangement to access our Resources, you are responsible for keeping your login information confidential. Therefore, you are liable for all activities arising from your account. 
                    </li>
                    <li>
                        It is expressly forbidden to engage in any activity that disturbs or particularly hinders our resources, including the servers or networks to which our resources are found or connected.
                    </li>
                    <li>
                        You agree not to use any automated, unethical, or unusual means to access (or try to access) any of our Resources.
                    </li>
                    <li>
                        Attempting to replicate, duplicate, reproduce, sell, trade, rent, or resell our resources is precisely prohibited.
                    </li>
                    <li>
                        In the event that you engage in illegal activity, as defined above, then you are fully responsible for any consequences, losses, or damages we may encounter or feel as a consequence, and you may be held liable criminally or civilly.
                    </li>
                </ul>
                
                <h3>Gratuitous Ideas</h3>
                <p>Ewen Realtors embraces your feedback and comments on the website.</p> 

                <p>The information you submit to Ewen Realtors through this website, including comments, ideas, questions, designs, etc will be treated as NON-CONFIDENTIAL and NON-PROPRIETARY.</p>

                <p>As a matter of fact, we humbly request that you should not send us any information and details that you do not wish to assign to us, including any confidential information.</p>
                <h3>Trademark</h3>
                <p>Ewen Realtors holds all the trademarks, service marks, and logos depicted on this website.</p> 

                <p>Trademarks may not be replicated, displayed, or otherwise used without the written consent of Ewen Realtors, except as expressly provided for in these terms and conditions.</p>

                <p>You agree not to interact with or try to meddle with the procedure of this website in any way.</p>

                <h2>Privacy</h2>
                <p>To ensure your privacy, we have formed a separate Privacy Policy that outlines how we collect, manage, process, secure, and store your personal information.</p>

                <p>User Agreement includes our Privacy Policy. Click here to read our entire Privacy Policy.</p>

                <p>NOTE: This Agreement covers the full comprehension between you and Ewen Realtors relating to your access to or use of this website.</p>

                <h3>Cancellation</h3>
                <p>Ewen Realtors has sole right to cancel your plot booking anytime (only in case when Ewen Realtors feels violeted or finds any inappropriate activity which goes against our terms and conditions by you). </p>

            </div>
        </div>
    );
}

export default TnC;