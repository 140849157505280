import React, { useRef, useState } from 'react';
import emailjs from '@emailjs/browser';

const CareerForm = () => {

  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs.sendForm('service_se9nbis', 'template_xldjk8w', form.current, 'bXugWrokuHug3Z57X')
      .then((result) => {
        //   console.log(result.text);
          e.target.reset();
          alert('Your Request sended successfully to our team.');
        }, (error) => {
            alert(error.text);
            // console.log(error.text);
      });
      alert('Please Wait...');
  };

   return (
    <div className="container pt-4 col-md-8">
        <form ref={form} onSubmit={sendEmail}>
            <div className="text-start mb-4">
                <label className="pb-2">Username *</label>
                <input name="username" type='text' className="form-control" />
            </div>
            <div className="row m-0">
                <div className="col-md-6 p-0">
                    <div className="text-start mb-4">
                        <label className="pb-2">Email Address *</label>
                        <input name="email" type='email' className="form-control" />
                    </div>
                </div>
                <div className="col-md-6 p-0">
                    <div className="text-start mb-4 px-md-2">
                        <label className="pb-2">Phone number *</label>
                        <input name="phone" type='tel' className="form-control" />
                    </div>
                </div>
            </div>
            <div className="text-start mb-4">
                <label className="pb-2">Street Address *</label>
                <input name="address" type='address' className="form-control" />
            </div>
            <div className="row m-0">
                <div className="col-md-6 p-0">
                    <div className="text-start mb-4">
                        <label className="pb-2">City *</label>
                        <input name="city" type='text' className="form-control" />
                    </div>
                </div>
                <div className="col-md-6 p-0">
                    <div className="text-start mb-4 px-md-2">
                        <label className="pb-2">State/Province *</label>
                        <input name="state" type='text' className="form-control" />
                    </div>
                </div>
            </div>
            <div className="row m-0">
                <div className="col-md-6 p-0">
                    <div className="text-start mb-4">
                        <label className="pb-2">ZIP/Postal Code *</label>
                        <input name="zip" type='number' className="form-control" />
                    </div>
                </div>
                <div className="col-md-6 p-0">
                    <div className="text-start mb-4 px-md-2">
                        <label className="pb-2">Country *</label>
                        <input name="country" type='text' className="form-control" />
                    </div>
                </div>
            </div>
            <div className="row m-0">
                <div className="col-md-6 p-0">
                    <div className="text-start mb-4">
                        <label className="pb-2">Previous Job Title *</label>
                        <input name="previousJob" type='text' className="form-control" />
                    </div>
                </div>
                <div className="col-md-6 p-0">
                    <div className="text-start mb-4 px-md-2">
                        <label className="pb-2">Drop Your Resume Link Here *</label>
                        <input name="resume" type='text' className="form-control" />
                    </div>
                </div>
            </div>
            <div className="text-start">
                <button className="btn btn-info text-capitalize text-light btn-lg shadow-0 rounded-2" type="send">Send Request</button>
            </div>
        </form>
    </div>
  );
};

export default CareerForm;