import React, { Component } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';
import { useState, useEffect } from "react";
import '../css/PostView.css';
import BlogSearch from '../layout/BlogSearch';
import Helmet from 'react-helmet';
import moment from 'moment';
import BlogLatest from './BlogLatest';

class Posts extends Component {
  constructor(props) {
    super(props);
    this.state = {
      posts: [],
    };
  }

  componentDidMount() {
    axios.get("https://old.ewenrealtors.com/wp-json/wp/v2/posts?_embed&per_page=100&offset=3").then(posts => {
      this.setState({
        posts: posts.data
      });
    });
  }

  createMarkup(html) {
    return { __html: html };
  }

  render() {
    document.title = "Blog | Ewen Realtors - Best Real Estate in India";
    const ldJson = 
    {
        "@context" : "http://schema.org",
        "@type" : "Real Estate Business",
        "name" : "Ewen Realtors",
        "image" : "https://ewenrealtors.com/logo.svg",
        "telephone" : "+91 852-616-4444",
        "address" : {
          "@type" : "PostalAddress",
          "streetAddress" : "Ewen Realtors NH 109, Nainital Road, Kathgodam, Uttarakhand 263139"
        }
    }
    return (
      <>
        <div className='container col-md-8 text-start mt-md-5 mb-2'>
          <Helmet>
                  <meta name="description" content="Blog | Ewen Realtors - Best Real Estate in India" />
                  <link rel="canonical" href="https://ewenrealtors.com/blog" />
                  <meta property="og:locale" content="en_US" />
                  <meta property="og:type" content="website" />
                  <meta property="og:title" content="Blog | Ewen Realtors - Best Real Estate in India" />
                  <meta property="og:description" content="Blog | Ewen Realtors - Best Real Estate in India" />
                  <meta property="og:url" content="https://ewenrealtors.com/blog" />
                  <meta property="og:site_name" content="Ewen Realtors" />
                  <meta property="article:publisher" content="https://www.facebook.com/ewenrealtors" />
                  <meta property="article:modified_time" content="2022-03-23T06:20:43+00:00" />
                  <meta property="og:image:width" content="800" />
                  <meta property="og:image:height" content="450" />
                  <meta property="og:image:type" content="image/jpeg" /> 
                  
                  {/* JSON-LD markup generated by Google Structured Data Markup Helper. */}
                  <script type="application/ld+json">
                      {JSON.stringify(ldJson)}
                  </script>
            </Helmet>
          <div className='mb-2'>
            <Link to="/" className="badge badge-primary mx-1 text-success">Home</Link>
            <Link to="/blog" className="badge badge-warning mx-1 text-dark">Blog</Link>
          </div>
          <BlogLatest />
          {/* <BlogSearch /> */}
          <div className='row m-0 my-2' style={{zIndex: '300'}}>
            {
              this.state.posts.map(post => (
                <div className='col-md-6 p-2 my-2'>
                <Link to={`/blog/${post.slug}`} key={post.id}>
                    <div className='card border-0 rounded-5 p-0' key={post.id} style={{boxShadow: '0px 1px 17px 0px rgba(0,0,0,0.1)'}} id="blogPost">
                        <div className='p-0'>
                          <img alt="Ewen Realtors Blogs" src={post._embedded['wp:featuredmedia'][0].source_url} className="featuredImage" style={{borderRadius: '8px 8px 0px 0px'}} />
                          <div className='px-2 py-2' dangerouslySetInnerHTML={{__html: post.title.rendered}} style={{fontSize: '20px', fontWeight: '800'}} />
                          <p className='px-2 pt-1 postExcerpt'>
                              <font dangerouslySetInnerHTML={{__html: post.excerpt.rendered.substring(0,78)+'...&nbsp;Read more'}}/>
                          </p>
                        </div>
                        <div className='d-flex justify-content-between align-items-center pb-2 px-2'>
                          <div className="px-1">
                            <img src='/logo.svg' className='postUser' />
                          </div>
                          <div style={{flex: 1}}>
                            Ewen Realtors
                          </div>
                          <div>
                            <font style={{fontSize: '11px', color: 'gray'}}> 
                              <i className='fas fa-globe-asia'></i> {moment(post.date).format("MMM DD, YYYY")}
                            </font>
                          </div>
                        </div>
                    </div>
                </Link>
                </div>
              ))
            }
          </div>
          
          <div className='loader text-center'>
            <div class="spinner-border" role="status">
              <span class="visually-hidden">Loading...</span>
            </div>
          </div>
        </div>
      </>
    );
  }

}

export default Posts;