import Helmet from 'react-helmet';
import '../css/About.css';

const About = () => {
    document.title = "About Us | Ewen Realtors - Best Real Estate in India";
    const ldJson = 
    {
        "@context" : "http://schema.org", 
        "@type" : "Real Estate Business",
        "name" : "Ewen Realtors",
        "image" : "https://ewenrealtors.com/logo.svg",
        "telephone" : "+91 852-616-4444",
        "address" : {
          "@type" : "PostalAddress",
          "streetAddress" : "Ewen Realtors NH 109, Nainital Road, Kathgodam, Uttarakhand 263139"
        }
    }
    return (
        <div className="container my-4 text-start">
            <Helmet>
                <meta name="description" content="Ewen Realtors was founded in 2011 it was an unorganized sector but now we are registered as an organized sector. It was founded with the goal of ‘Defining Dreams’." />
                <link rel="canonical" href="https://ewenrealtors.com/about-us" />
                <meta property="og:locale" content="en_US" />
                <meta property="og:type" content="website" />
                <meta property="og:title" content="About Us | Ewen Realtors - Best Real Estate in India" />
                <meta property="og:description" content="Ewen Realtors was founded in 2011 it was an unorganized sector but now we are registered as an organized sector. It was founded with the goal of ‘Defining Dreams’." />
                <meta property="og:url" content="https://ewenrealtors.com/about-us" />
                <meta property="og:site_name" content="Ewen Realtors" />
                <meta property="article:publisher" content="https://www.facebook.com/ewenrealtors" />
                <meta property="article:modified_time" content="2022-03-23T06:20:43+00:00" />
                <meta property="og:image:width" content="800" />
                <meta property="og:image:height" content="450" />
                <meta property="og:image:type" content="image/jpeg" /> 
                
                {/* JSON-LD markup generated by Google Structured Data Markup Helper. */}
                <script type="application/ld+json">
                    {JSON.stringify(ldJson)}
                </script>
            </Helmet>
            <h1 className="mb-4">About Us</h1>
                <p>Ewen Realtors is a new startup with an aim of “Defining Dreams” with 11 years of experience in real estate with the JMB group. The company is firmly established as one of the best emerging real estate companies in Uttarakhand with a decade of experience. </p>

                <p>The reason for being and emerging as the best is that we understand our clients in every aspect by providing a wide array of real estate services. Also, we will walk you through every stage of the process, from buying to building to paperwork to home financing and much more.</p>

                <p>Ewen Realtors intend to take your real estate experience to new heights and keep your priorities on top. Since Covid-19 demand for townships in the mountains has increased and is gaining a lot of attention all over India. </p>

                <p>So, After successfully covering the mountains market the some of the finest development in Uttarakhand, Ewen Realtors has now landed in the Queen’s Meadow Ranikhet.</p>

                <p>In keeping with our commitment to providing a world-class township in Uttarakhand, we are pleased to introduce our ongoing project “The Ewen Park” with a majestic view of the Himalayas. Township is designed to offer you a wholesome living experience. Live a blissful life and embrace the beauty of nature.</p>
                
                <p>Smart living and a convenient lifestyle that you wished for in your life. A well-connected township that has nature as its neighbour. </p>

            <div className='row m-0 my-5 pt-5 align-items-center'>
                <div className='col-md-4'>
                    <img src="/assets/images/chairman-er.png" className='staff-image' />
                </div>
                <div className='col-md-8'>
                    <h2 className='mb-4'>Don’t Wait To Buy Real Estate, Buy Real Estate And Wait</h2>
                    <p>
                        Ewen Realtors is a company with a purpose whose mission is to help everyone find their dream place.
                    </p>
                    <p>
                        We are creating new destinations in Uttarakhand, they are not just homes they are lifestyle destinations boasting exemplary facilities that cannot be matched anywhere else.
                    </p>
                    <p>
                        We at Ewen Realtors have succeeded by placing our clients’ needs first. Their success has been our success, and our commitment to their needs and providing better services now and in the future. 
                    </p>
                    <p>
                        I invite you to learn more about our company, our projects, and our business philosophy throughout our website.
                    </p>
                    <p><b>Mr. Rajesh Rawat</b> <i>(Chairman)</i></p>
                </div>
            </div>

            <div className='row m-0 my-5 align-items-center'>
                <div className='col-md-4'>
                    <img src="/assets/images/ewenrealtors-ceo.png" className='staff-image' />
                </div>
                <div className='col-md-8'>
                    <h2 className='mb-4'>Real Estate Is An Imperishable Asset, Ever-Increasing In Value</h2>
                    <p>
                        The hills of Uttarakhand offer abundant second home properties, across segments, with the prospect of earning good rental income. We look at whether it makes sense for buyers to invest in property in Uttarakhand and the factors they should consider.
                    </p>
                    <p>
                        Following the COVID-19 outbreak, the real state segment has seen an increase in property purchases by buyers. Uttarakhand is emerging as another preferred second home destination, with people showing increased interest here. 
                    </p>
                    <p>
                        One such state, Uttarakhand, and its cities have been favorite investment destinations among investors and buyers from across the country. 
                    </p>
                    <p><b>Mr. Deepak Sharma</b> <i>(CEO)</i></p>
                </div>
            </div>

        </div>
    );
}

export default About;
