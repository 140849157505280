import '../css/Services.css';
import { NavLink } from 'react-router-dom';
import AllServices from './services/AllServices';
import Helmet from 'react-helmet';

const Services = () => {
    document.title = "Services | Ewen Realtors - Best Real Estate in India";
    const ldJson = 
    {
        "@context" : "http://schema.org",
        "@type" : "Real Estate Business",
        "name" : "Ewen Realtors",
        "image" : "https://ewenrealtors.com/logo.svg",
        "telephone" : "+91 852-616-4444",
        "address" : {
          "@type" : "PostalAddress",
          "streetAddress" : "Ewen Realtors NH 109, Nainital Road, Kathgodam, Uttarakhand 263139"
        }
    }
    return(
        <div className='text-start p-0 m-0'>
            <Helmet>
                <meta name="description" content="Ewen Realtors provide you with a number of services like finding the property, building, and designing can be very stressful jobs. We are here to make your life easier. We understand that investing in real estate can be a very ethical choice." />
                <link rel="canonical" href="https://ewenrealtors.com/services" />
                <meta property="og:locale" content="en_US" />
                <meta property="og:type" content="website" />
                <meta property="og:title" content="Services | Ewen Realtors - Best Real Estate in India" />
                <meta property="og:description" content="Ewen Realtors provide you with a number of services like finding the property, building, and designing can be very stressful jobs. We are here to make your life easier. We understand that investing in real estate can be a very ethical choice." />
                <meta property="og:url" content="https://ewenrealtors.com/services" />
                <meta property="og:site_name" content="Ewen Realtors" />
                <meta property="article:publisher" content="https://www.facebook.com/ewenrealtors" />
                <meta property="article:modified_time" content="2022-03-23T06:20:43+00:00" />
                <meta property="og:image:width" content="800" />
                <meta property="og:image:height" content="450" />
                <meta property="og:image:type" content="image/jpeg" /> 
                
                {/* JSON-LD markup generated by Google Structured Data Markup Helper. */}
                <script type="application/ld+json">
                    {JSON.stringify(ldJson)}
                </script>
            </Helmet>
                <AllServices />
                <div className='my-4 container'>
                    <h1 className="mb-4">Services</h1>
                    <img src="/assets/images/our-services.jpg" style={{width: '100%', borderRadius: '4px'}} />
                    <p className='mt-4'>Real estate is the best kind of investment. Also investing in real estate can be a very difficult choice and decision to make. Often, when people decide to invest in real estate, they back out. They don't want to fall into any loopholes or have to wander around to find services.</p>

                    <p>But Ewen Realtors got you covered. Ewen Realtors is an emerging real estate company in Uttarakhand. Their headquarters is in Kathgodam, Haldwani. Our company serves as a potential provider of real estate services. As a young team with a new startup, we are all passionate about what we do. </p>

                    <p>We offer a wide array of real estate services like property consultants, interior design, construction services, Vastu consultants and so on. The time of hopping from realtor to realtor, friend to friend, or relative to a relative is over. The aim of providing these services is to make your real estate journey effortless. </p>

                    {/* Services  */}
                    <div className='my-4'>
                        <h2 className='text-center py-4'>OUR SERVICES</h2>
                        <div className='row m-0 justify-content-center'>
                            <div className='col-md-4 p-3 mb-3'>
                                <NavLink exact to="/services/builder-and-developer" className="navLinks">
                                    <div className='card text-center border-0 p-0'>
                                        <img src="/assets/images/services/Builder-Developer.jpg" className='serviceImg' />
                                        <h3 className='p-2'>Builder & Developer</h3>
                                        <p className='px-3 text-center' style={{fontSize: '14px'}}>We offer the best builder and developer services because we value your dreams.</p>
                                    </div>
                                </NavLink>
                            </div>
                            <div className='col-md-4 p-3 mb-3'>
                                <NavLink exact to="/services/construction-service" className="navLinks">
                                    <div className='card text-center border-0 p-0'>
                                        <img src="/assets/images/services/Construction-Services.jpg" className='serviceImg' />
                                        <h3 className='p-2'>Construction Services</h3>
                                        <p className='px-3 text-center' style={{fontSize: '14px'}}>We offer high-quality construction services. We make no compromises on quality.</p>
                                    </div>
                                </NavLink>
                            </div>
                            <div className='col-md-4 p-3 mb-3'>
                                <NavLink exact to="/services/real-estate-consultant" className="navLinks">
                                    <div className='card text-center border-0 p-0'>
                                        <img src="/assets/images/services/Real-Estate-Consultant.jpg" className='serviceImg' />
                                        <h3 className='p-2'>Real Estate Consultant</h3>
                                        <p className='px-3 text-center' style={{fontSize: '14px'}}>Best real estate advisor to assist you to make the most important decision of your life.</p>
                                    </div>
                                </NavLink>
                            </div>
                            <div className='col-md-4 p-3 mb-3'>
                                <NavLink exact to="/services/property-legal-services" className="navLinks">
                                    <div className='card text-center border-0 p-0'>
                                        <img src="/assets/images/services/Property-Legal-Services.jpg" className='serviceImg' />
                                        <h3 className='p-2'>Property Legal Services</h3>
                                        <p className='px-3 text-center' style={{fontSize: '14px'}}>We provide authentic property legal services from property verification to registration.</p>
                                    </div>
                                </NavLink>
                            </div>
                            <div className='col-md-4 p-3 mb-3'>
                                <NavLink exact to="/services/home-loan-services" className="navLinks">
                                    <div className='card text-center border-0 p-0'>
                                        <img src="/assets/images/services/Home-Loan-Services.jpg" className='serviceImg' />
                                        <h3 className='p-2'>Home Loan Services</h3>
                                        <p className='px-3 text-center' style={{fontSize: '14px'}}>We focus on providing quick and easy home loan services with the best deals</p>
                                    </div>
                                </NavLink>
                            </div>
                            <div className='col-md-4 p-3 mb-3'>
                                <NavLink exact to="/services/vastu-consultant" className="navLinks">
                                    <div className='card text-center border-0 p-0'>
                                        <img src="/assets/images/services/Vastu-Consultant.jpg" className='serviceImg' />
                                        <h3 className='p-2'>Vastu Consultant</h3>
                                        <p className='px-3 text-center' style={{fontSize: '14px'}}>Consult with the best Vastu experts and bring happiness and prosperity into your life.</p>
                                    </div>
                                </NavLink>
                            </div>
                            <div className='col-md-4 p-3 mb-3'>
                                <NavLink exact to="/services/interior-designer" className="navLinks">
                                    <div className='card text-center border-0 p-0'>
                                        <img src="/assets/images/services/Interior-Designer.jpg" className='serviceImg' />
                                        <h3 className='p-2'>Interior Designer</h3>
                                        <p className='px-3 text-center' style={{fontSize: '14px'}}>Top interior designers who will make your home the most comfortable place to live.</p>
                                    </div>
                                </NavLink>
                            </div>
                        </div>
                    </div>

                    <div className="container">
                        <p style={{fontSize: '25px', fontWeight: '350'}}>A Bit More...</p>
                        <p style={{fontSize: '35px', fontWeight: '350', textAlign: 'start'}} className='justHeading'>
                            Our Services Are Intended To Increase The Profit Of Your Wealth And To Assist You At Every Stage Of The Real Estate Transaction.
                        </p>
                        <hr style={{borderBottom: '3px solid #000'}} className="mt-5" />
                        <div className='row m-0 mt-5'>
                            <div className='col-md-6'>
                                <p>
                                    Whether you are a landlord, investor, developer, buyer, or seller, our goal is to find a solution that offers innovation, quality, flexibility, and good value for money.
                                </p>
                                <p>
                                    Our expertise also extends to the entire development life cycle, from planning to completion. Our extensive agency and property management operations also play an important role in ensuring that we are up to date on market trends.
                                </p>
                            </div>
                            <div className='col-md-6'>
                                <p>
                                    We have assembled a team of experts to assist you in comprehending the real estate market from the standpoint of each client.
                                </p>
                                <p>
                                    We collaborate closely with a team of regional experts to provide high-quality sales instructions for individual assets and portfolios, as well as luxury properties, throughout Uttarakhand.
                                </p>
                            </div>
                        </div>
                    </div>

                    <h2 className='my-4'>fAQ&#8217;s</h2>

                    <div class="wp-block-uagb-faq uagb-faq__outer-wrap uagb-block-785af629 uagb-faq-icon-row uagb-faq-layout-accordion uagb-faq-expand-first-false uagb-faq-inactive-other-true uagb-faq-equal-height" data-faqtoggle="true" role="tablist"><div class="uagb-faq__wrap uagb-buttons-layout-wrap">
                    <div class="wp-block-uagb-faq-child uagb-faq-child__outer-wrap uagb-block-9c014bd5"><div class="uagb-faq-child__wrapper"><div class="uagb-faq-item" role="tab" tabindex="0"><div class="uagb-faq-questions-button uagb-faq-questions"><span class="uagb-question">What are all the services provided by real estate companies? </span></div><div class="uagb-faq-content"><span><p>Ans. All the services provided by real estate companies are construction services, real estate consultant, property legal services, home loan services, Vastu consultant, interior designing, builder, and developer services. </p></span></div></div></div></div>

                    <div class="wp-block-uagb-faq-child uagb-faq-child__outer-wrap uagb-block-7d8d49ce"><div class="uagb-faq-child__wrapper"><div class="uagb-faq-item" role="tab" tabindex="0"><div class="uagb-faq-questions-button uagb-faq-questions"><span class="uagb-question">What is the main aim of real estate companies?</span></div><div class="uagb-faq-content"><span><p>Ans. Real estate companies have a team of experts to assist their clients in increasing the profit of their wealth and to assist them at every stage of the real estate transaction </p></span></div></div></div></div>

                    <div class="wp-block-uagb-faq-child uagb-faq-child__outer-wrap uagb-block-6be47702"><div class="uagb-faq-child__wrapper"><div class="uagb-faq-item" role="tab" tabindex="0"><div class="uagb-faq-questions-button uagb-faq-questions"><span class="uagb-question">What is the main aim of construction services provided by real estate companies? </span></div><div class="uagb-faq-content"><span><p>Ans. The main aim of construction services provided by real estate companies is to take the responsibility off their clients shoulders. </p></span></div></div></div></div>

                    <div class="wp-block-uagb-faq-child uagb-faq-child__outer-wrap uagb-block-f7698ffd"><div class="uagb-faq-child__wrapper"><div class="uagb-faq-item" role="tab" tabindex="0"><div class="uagb-faq-questions-button uagb-faq-questions"><span class="uagb-question">Why do real estate companies offer home loan services?</span></div><div class="uagb-faq-content"><span><p>Ans. Real estate companies offer home loan services to provide you with a loan at a low-interest rate with multiple offers to make you one step closer to buying your dream house. </p></span></div></div></div></div>
                    </div></div>

                </div>

        </div>
    );
}

export default Services;