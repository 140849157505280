import { NavLink } from 'react-router-dom';
import AllProjects from '../services/AllProjects';
import Helmet from 'react-helmet';
import ContactForm from '../ContactForm';
import CallToAction from './CallToAction';
import Amenities from './Amenities';
import ReachUs from './ReachUs';

const KasarHimalayan = () => {
    document.title = "Kasar Himalayan Chalet - The Ewen Park | Ewen Realtors";
    const ldJson = 
    {
        "@context" : "http://schema.org",
        "@type" : "Real Estate Business",
        "name" : "Ewen Realtors",
        "image" : "https://ewenrealtors.com/logo.svg",
        "telephone" : "+91 852-616-4444",
        "address" : {
            "@type" : "PostalAddress",
            "streetAddress" : "Ewen Realtors NH 109, Nainital Road, Kathgodam, Uttarakhand 263139"
        }
    }

    return(
        <>
            <Helmet>
                {/* <meta name="description" content="Ewen Realtors presents Phase 2 of “The Ewen Park” i.e Dwarsu. It is a grand township near Baburkhola in Ranikhet. The place is immensely popular for its natural habitat and serene environment. The township which is being built in Dwarsu also has a wide range of vegetation which enhances the beauty of the township." /> */}
                <link rel="canonical" href="https://ewenrealtors.com/projects/royal-apartments" />
                <meta property="og:locale" content="en_US" />
                <meta property="og:type" content="website" />
                <meta property="og:title" content="Kasar Himalayan Chalet | Ewen Realtors - Best Real Estate in India" />
                <meta property="og:description" content="Ewen Realtors presents Phase 2 of “The Ewen Park” i.e Dwarsu. It is a grand township near Baburkhola in Ranikhet. The place is immensely popular for its natural habitat and serene environment. The township which is being built in Dwarsu also has a wide range of vegetation which enhances the beauty of the township." />
                <meta property="og:url" content="https://ewenrealtors.com/projects/himalayan-vista" />
                <meta property="og:site_name" content="Ewen Realtors" />
                <meta property="article:publisher" content="https://www.facebook.com/ewenrealtors" />
                <meta property="article:modified_time" content="2022-03-23T06:20:43+00:00" />
                <meta property="og:image:width" content="800" />
                <meta property="og:image:height" content="450" />
                <meta property="og:image:type" content="image/jpeg" /> 
                
                {/* JSON-LD markup generated by Google Structured Data Markup Helper. */}
                <script type="application/ld+json">
                    {JSON.stringify(ldJson)}
                </script>
            </Helmet>
            
            <AllProjects/>
            <div className='my-3 container text-start'>
                <div className='row m-0 align-items-center my-md-5 flex-row-reverse'>
                    {/* <div className='col-md-5 text-center px-md-5'> */}
                        {/* <KasarHimalayanCarousel/> */}
                    {/* </div> */}
                    <div className='col-md-12'>
                        <h1 className='text-start projectHeading'>Kasar Himalayan Chalet</h1>
                        <p style={{fontWeight:'800'}}>Where the whispers of nature become your daily symphony.</p>
                        <p className='text-secondary'>
                            Welcome to another phase of The Ewen Park - Kasar Devi Phase, a stunning way to luxury living, nestled in nature. Experience the enchanting magnetism of the Himalayas with breathtaking views of dusk and dawn, creating an ethereal ambiance that will captivate your senses.
                        </p>
                        <p className='text-secondary'>
                            Embrace a life of serenity and sophistication as you bask in the lap of nature while enjoying the city-like amenities while enjoying nature to the fullest.
                        </p>
                        <p className='text-secondary'>
                            Each detail of this luxurious residence has been carefully framed to offer the perfect blend of comfort and refinement. Every moment spent here will be pure bliss and a step towards refreshment from the hustle-bustle of city life.
                            Elevate your lifestyle and immerse yourself in the unparalleled beauty of The Ewen Park - Kasar Devi Phase. Your dream of a luxurious, nature-inspired haven awaits.
                        </p>
                    </div>
                </div>
                <Amenities/>
            </div>

            <CallToAction/>

            <div className='my-3 container text-start'>
                {/* Master Plan  */}
                <div className='py-4 mt-3'>
                    <h2 className='text-center pb-3'>Master Plan</h2>
                    <div class="row m-0 p-0 align-items-center">
                        <div class="nav flex-fill nav-pills me-3 col-md-3 d-flex justify-content-center p-0" id="v-pills-tab" role="tablist" aria-orientation="vertical">
			                <a href="https://drive.google.com/file/d/1_AI9NsVAuN987nPfxYXzr5hPq9-RUEwl/view?usp=drive_link" target='_blank'>
                                <button class="nav-link" id="v-pills-messages-tab" data-bs-toggle="pill" type="button" role="tab" aria-controls="tab4" aria-selected="false">Layout</button>
                            </a>
			                <a href="https://drive.google.com/file/d/1ecpJs34G51xY5WqRkKodoBqzOtEhEKZz/view?usp=drive_link" target='_blank'>
                                <button class="nav-link" id="v-pills-messages-tab" data-bs-toggle="pill" type="button" role="tab" aria-controls="tab4" aria-selected="false">Payment Plan</button>
                            </a>
                        </div>
                    </div>
                </div>
                

                {/* Location Map  */}
                <div className='py-4'>
                    <h2 className='text-center mb-3'>Location Map</h2>
                    <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d5322.5055653498575!2d79.5444167!3d29.659527800000003!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x2eb07a8ff42a302!2zMjnCsDM5JzM0LjMiTiA3OcKwMzInMzkuOSJF!5e1!3m2!1sen!2sin!4v1667972462401!5m2!1sen!2sin"allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade" style={{width: '100%', height: '400px', borderRadius: '10px'}} className="shadow-sm"></iframe>
                </div>

                <ReachUs/>

                <ContactForm/>
            </div>

        </>
    );
}

export default KasarHimalayan;