import emailjs from '@emailjs/browser';
import React, { useRef, useState } from 'react';    

const NotFound = () => {

    const form = useRef();

    const sendEmail = (e) => {
        e.preventDefault();

        emailjs.sendForm('service_se9nbis', 'template_gep47uf', form.current, 'bXugWrokuHug3Z57X')
        .then((result) => {
            //   console.log(result.text);
            e.target.reset();
            alert('Your Request sended successfully to our team.');
            }, (error) => {
                alert(error.text);
                // console.log(error.text);
        });
        alert('Please Wait...');
    };

    return (

        <div className="mt-5 mb-3 container col-md-5 pb-3">
            {/* <h1 className="textNotFound">404, PAGE NOT FOUND!</h1> */}
            <img src={'/assets/animations/404.gif'} style={{height: '150px', width: 'auto', objectFit: 'contain'}} />

            <div className="my-4">
                <p className="text-center" style={{fontWeight: '300 !important', fontSize: '24px'}}>If you have any queries, Please let us know below...</p>

                <form className="text-start my-3 pt-3" ref={form} onSubmit={sendEmail}>
                    <div className="row m-0">
                        <div className="mb-3 col-md-12">
                            <label>Username</label>
                            <input type="text" className="form-control" name="username" />
                        </div>
                        <div className="mb-3 col-md-6">
                            <label>Email</label>
                            <input type="email" className="form-control" name="email" />
                        </div>
                        <div className="mb-3 col-md-6">
                            <label>Phone</label>
                            <input type="tel" className="form-control" name="phone" />
                        </div>
                        <div className="mb-3 col-md-12">
                            <label>Message</label>
                            <textarea rows={5} className="form-control" name="message" value={'I want to know about - '}></textarea>
                        </div>
                        <div className="mb-3 col-md-12">
                            <button className="btn btn-primary shadow-none rounded-2 text-capitalize"><i className="fas fa-paper-plane fa-sm"></i>&nbsp; Send Query</button>
                        </div>

                    </div>
                </form>
            </div>

        </div>
    );
}

export default NotFound;