import { NavLink } from 'react-router-dom';
import AllProjects from '../services/AllProjects';
import Helmet from 'react-helmet';
import ContactForm from '../ContactForm';
import CallToAction from './CallToAction';
import Amenities from './Amenities';
// import RoyalApartmentCarousel from './Carousel/RoyalApartmentCarousel';
import ReachUs from './ReachUs';

const RiverValleyEstate = () => {
    document.title = "River Valley Estate - The Ewen Park | Ewen Realtors";
    const ldJson = 
    {
        "@context" : "http://schema.org",
        "@type" : "Real Estate Business",
        "name" : "Ewen Realtors",
        "image" : "https://ewenrealtors.com/logo.svg",
        "telephone" : "+91 852-616-4444",
        "address" : {
            "@type" : "PostalAddress",
            "streetAddress" : "Ewen Realtors NH 109, Nainital Road, Kathgodam, Uttarakhand 263139"
        }
    }

    return(
        <>
            <Helmet>
                {/* <meta name="description" content="Ewen Realtors presents Phase 2 of “The Ewen Park” i.e Dwarsu. It is a grand township near Baburkhola in Ranikhet. The place is immensely popular for its natural habitat and serene environment. The township which is being built in Dwarsu also has a wide range of vegetation which enhances the beauty of the township." /> */}
                <link rel="canonical" href="https://ewenrealtors.com/projects/royal-apartments" />
                <meta property="og:locale" content="en_US" />
                <meta property="og:type" content="website" />
                <meta property="og:title" content="Royal Apartment | Ewen Realtors - Best Real Estate in India" />
                <meta property="og:description" content="Ewen Realtors presents Phase 2 of “The Ewen Park” i.e Dwarsu. It is a grand township near Baburkhola in Ranikhet. The place is immensely popular for its natural habitat and serene environment. The township which is being built in Dwarsu also has a wide range of vegetation which enhances the beauty of the township." />
                <meta property="og:url" content="https://ewenrealtors.com/projects/himalayan-vista" />
                <meta property="og:site_name" content="Ewen Realtors" />
                <meta property="article:publisher" content="https://www.facebook.com/ewenrealtors" />
                <meta property="article:modified_time" content="2022-03-23T06:20:43+00:00" />
                <meta property="og:image:width" content="800" />
                <meta property="og:image:height" content="450" />
                <meta property="og:image:type" content="image/jpeg" /> 
                
                {/* JSON-LD markup generated by Google Structured Data Markup Helper. */}
                <script type="application/ld+json">
                    {JSON.stringify(ldJson)}
                </script>
            </Helmet>
            <AllProjects/>
            <div className='my-3 container text-start'>
                <div className='row m-0 align-items-center my-md-5 flex-row-reverse'>
                    <div className='col-md-5 text-center px-md-5'>
                        {/* <RiverValleyEstateCarousel/> */}
                    </div>
                    <div className='col-md-12'>
                        <h1 className='text-start projectHeading'>River Valley Estate</h1>
                        <p style={{fontWeight:'800'}}>Your retreat by the river awaits!</p>
                        {/* <p className='text-secondary font-italic'>Nature will be your neighbour.</p> */}
                        <p className='text-justify'>
                        Welcome to River Valley Estate, the newest phase of "The Ewen Park" township, nestled in the picturesque town of Kuwali, Ranikhet, Uttarakhand. This beautiful residential township is designed to offer you a tranquil retreat amidst the beauty of nature, with mesmerizing views of the river and valley.
                        </p>
                        <p className='text-justify'>
                        Moreover, River Valley Estate is a gated society with all the basic to lavish amenities needed to live a serene life close to nature. This phase also comes under “The Ewen Universe”. One of the standout features of this phase is its affordability. It offers a variety of plots at prices that suit all budgets. Now, you can build your dream home in nature with breathtaking views without breaking the bank.
                        </p>
                    </div>
                </div>
                {/* <Amenities/> */}
            </div>

            <CallToAction/>

            <div className='my-3 container text-start'>
                {/* Master Plan  */}
                <div className='py-4 mt-3'>
                    <h2 className='text-center pb-3'>Master Plan</h2>
                    <div class="row m-0 p-0 align-items-center">
                        <div class="nav flex-fill nav-pills me-3 col-md-3 d-flex justify-content-center p-0" id="v-pills-tab" role="tablist" aria-orientation="vertical">
			                <a href="/assets/projects/River Valley/The Ewen Park- River Valley.pdf" target='_blank'>
                                <button class="nav-link" id="v-pills-messages-tab" data-bs-toggle="pill" type="button" role="tab" aria-controls="tab4" aria-selected="false">Layout</button>
                            </a>
                            <button class="nav-link" id="v-pills-messages-tab" data-bs-toggle="pill" data-bs-target="#tab3" type="button" role="tab" aria-controls="tab3" aria-selected="false">Payment Plan</button>
                        </div>
                        <div class="tab-content col-md-12 text-center" id="v-pills-tabContent">
                            <div class="tab-pane fade" id="tab3" role="tabpanel" aria-labelledby="v-pills-messages-tab" tabindex="0">
                                <a href='/assets/projects/River Valley/River_valley_estate_payment.webp' target='_blank'>
                                    <img src='/assets/projects/River Valley/River_valley_estate_payment.webp' style={{width: '100%', maxHeight: '800px', objectFit: 'contain', borderRadius: '10px'}} />
                                </a>
                            </div>
                        </div>
                    </div>
                </div>

                {/* Location Map  */}
                <div className='py-4'>
                    <h2 className='text-center mb-3'>Location Map</h2>
                    <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d6929.88636718531!2d79.50443005!3d29.721405000000004!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x39a0acdcd20f1f4b%3A0xb5c9d8275381ba25!2sKuwali%2C%20Uttarakhand%20263652!5e0!3m2!1sen!2sin!4v1688828785019!5m2!1sen!2sin"allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade" style={{width: '100%', height: '400px', borderRadius: '10px'}} className="shadow-sm"></iframe>
                </div>

                <ReachUs/>

                <ContactForm/>
            </div>

        </>
    );
}

export default RiverValleyEstate;
