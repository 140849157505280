import React, { Component } from 'react';
import axios from 'axios';
import '../css/PostView.css';
import moment from 'moment';

class HomeRecentPosts extends Component {
  constructor(props) {
    super(props);
    this.state = {
      posts: [],
    };
  }

  componentDidMount() {
    axios.get("https://old.ewenrealtors.com/wp-json/wp/v2/posts?_embed&per_page=6").then(posts => {
      this.setState({
        posts: posts.data
      });
    });
  }

  createMarkup(html) {
    return { __html: html };
  }

  render() {
    return (
      <div className='container col-md-8 text-start p-0'>
          <h2 className='text-center'>Our Recent Posts</h2>
          <div className='row m-0'>
            {
                this.state.posts.map(post => (
                    <div className='col-md-4 p-3'>
                        <a href={`/blog/${post.slug}`} key={post.id}>
                            <div className='card pb-2 my-3 border-0' style={{height: 'fit-content'}} id="homeBlogImage">
                                <img alt={post.title.rendered} src={post.yoast_head_json.og_image[0].url} className="m-0" style={{width: '100%', borderRadius: '4px 4px 0px 0px'}} />
                                <h5 className='p-3 my-0' dangerouslySetInnerHTML={{__html: post.title.rendered}} />
                            </div>
                        </a>
                    </div>
                ))
            }
          </div>
      </div>
    );
  }

}

export default HomeRecentPosts;