import '../css/Footer.css';
import { Link, NavLink } from 'react-router-dom';
import { useLocation } from "react-router-dom";

const Footer = () => {

    const { pathname } = useLocation();

    if (pathname === "/menu") return null;

    return(
        <div className='myFooter px-md-5'>
            <div className='row m-0' id="mfooter">
                <div className='col-md-2'>
                    <div className='text-start' style={{textTransform: 'uppercase', color: 'gray'}}>Quick Links</div>
                    <ul className='myFooterList'>
                        <li><Link to="/">Home</Link></li>
                        <li><Link to="/services">Services</Link></li>
                        <li><Link to="/projects">Projects</Link></li>
                        <li><Link to="/blog">Blog</Link></li>
                        <li><Link to="/media">Gallery</Link></li>
                    </ul>
                </div>
                <div className='col-md-2'>
                    <div className='text-start' style={{textTransform: 'uppercase', color: 'gray'}}>about</div>
                    <ul className='myFooterList'>
                        <li><Link to="/about-us">About Us</Link></li>
                        <li><Link to="/contact-us">Contact Us</Link></li>
                        <li><Link to="/careers">Careers</Link></li>
                        {/* <li><Link to="/partner">Join As Partner</Link></li> */}
                    </ul>
                </div>
                <div className='col-md-2'>
                    <div className='text-start' style={{textTransform: 'uppercase', color: 'gray'}}>policy</div>
                    <ul className='myFooterList'>
                        <li><Link to="/disclaimer">Disclaimer</Link></li>
                        <li><Link to="/privacy-policy">Privacy</Link></li>
                        <li><Link to="/terms-and-conditions">Terms & Conditions</Link></li>
                        <li><Link to="/refund-policy">Refund</Link></li>
                    </ul>
                </div>
                <div className='col-md-2'>
                    <div className='text-start' style={{textTransform: 'uppercase', color: 'gray'}}>help</div>
                    <ul className='myFooterList'>
                        <li><Link to="/faqs">FAQ</Link></li>
                        <li><a href="mailto: info@ewenrealtors.com">Mail Us</a></li>
                        <li><a href="https://forms.gle/JM6Fo5nmuN9NwPqw6" target="_blank">Feedback</a></li>
                        <li><Link to="/sitemap">Sitemap</Link></li>
                    </ul>
                </div>
                <div className='col-md-2'>
                    <div className='text-start' style={{textTransform: 'uppercase', color: 'gray'}}>social</div>
                    <ul className='myFooterList'>
                        <li><a href="https://www.facebook.com/ewenrealtors/" target="_blank">Facebook</a></li>
                        <li><a href="https://www.instagram.com/ewenrealtors/" target="_blank">Instagram</a></li>
                        <li><a href="https://www.linkedin.com/company/ewenrealtors" target="_blank">LinkedIn</a></li>
                        <li><a href="https://in.pinterest.com/ewenrealtors" target="_blank">Pinterest</a></li>
                        <li><a href="https://twitter.com/EwenRealtors" target="_blank">Twitter</a></li>
                        <li><a href="https://www.youtube.com/channel/UCEHZDj1RG_PA1wUqlE4l4kQ" target="_blank">YouTube</a></li>
                    </ul>
                </div>
                <div className='col-md-2'>
                    <div className='text-start' style={{textTransform: 'uppercase', color: 'gray'}}>reach us</div>
                    <ul className='myFooterList'>
                        <li>
                            <a href="https://goo.gl/maps/KEjHCwPufqiARJ8h8" target="_blank">
                                Ewen Realtors<br/>
                                Near Udupiwala,<br/>
                                NH 109, Nainital Road,<br/>  
                                Kathgodam,<br/> 
                                Uttarakhand - 263126, India<br/>
                            </a>  
                        </li>
                        <li className='mt-3'><i className='fas fa-phone-alt'></i> <a href="tel: +918526164444">+91 852-616-4444</a></li>
                    </ul>
                </div>
                <div className='col-md-12 p-0'>
                    <div className='row m-0 justify-content-between'>
                        <div className='col-md-6 text-start pb-2' id="secondFooter">
                        <i class="fas fa-copyright text-warning px-1"></i>EwenRealtors.com &nbsp;|&nbsp; <Link to="/contact-us"><i className='fas fa-envelope text-warning px-1'></i> Contact Us</Link> &nbsp;|&nbsp; <Link to="/faqs"><i className='fas fa-question-circle text-warning px-1'></i> FAQ</Link> &nbsp;|&nbsp; <Link to="/investors">Investors</Link>
                        </div>
                        <div className='col-md-2 text-start pb-2'>
                            <img src="/assets/logo/pay_methods_branding.png" style={{height: '15px'}} />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Footer;
