import { NavLink } from 'react-router-dom';

const AllServices = () => {
    return(
        <div className='m-0 px-3 serviceShortcut' style={{overflowX: 'auto'}}>
            <NavLink exact to="/services/builder-and-developer" className="navLinks">
                <div className='rounded text-center px-3 mx-1 pt-3 pb-2' id="subNav">
                    <img src="/assets/images/services/icons/Builder Icon.svg" className='serviceIcon mb-2' /><br />Builder
                </div>
            </NavLink>
            <NavLink exact to="/services/construction-service" className="navLinks">
                <div className='rounded text-center px-3 mx-1 pt-3 pb-2' id="subNav">
                    <img src="/assets/images/services/icons/Construction Icon.svg" className='serviceIcon mb-2' /><br />Construction
                </div>
            </NavLink>
            <NavLink exact to="/services/real-estate-consultant" className="navLinks">
                <div className='rounded text-center px-3 mx-1 pt-3 pb-2' id="subNav">
                    <img src="/assets/images/services/icons/Real Estate Icon.svg" className='serviceIcon mb-2' /><br />RealEstate
                </div>
            </NavLink>
            <NavLink exact to="/services/property-legal-services" className="navLinks">
                <div className='rounded text-center px-3 mx-1 pt-3 pb-2' id="subNav">
                    <img src="/assets/images/services/icons/Property Legal Icon.svg" className='serviceIcon mb-2' /><br />Legal
                </div>
            </NavLink>
            <NavLink exact to="/services/home-loan-services" className="navLinks">
                <div className='rounded text-center px-3 mx-1 pt-3 pb-2' id="subNav">
                    <img src="/assets/images/services/icons/Home Loan Icon.svg" className='serviceIcon mb-2' /><br />Loan
                </div>
            </NavLink>
            <NavLink exact to="/services/vastu-consultant" className="navLinks">
                <div className='rounded text-center px-3 mx-1 pt-3 pb-2' id="subNav">
                    <img src="/assets/images/services/icons/Vastu Icon.svg" className='serviceIcon mb-2' /><br />Vastu
                </div>
            </NavLink>
            <NavLink exact to="/services/interior-designer" className="navLinks">
                <div className='rounded text-center px-3 mx-1 pt-3 pb-2' id="subNav">
                    <img src="/assets/images/services/icons/Interior Icon.svg" className='serviceIcon mb-2' /><br />Interior
                </div>
            </NavLink>
        </div>                    
    );
}

export default AllServices;