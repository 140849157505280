import '../css/Partner.css';
import Helmet from 'react-helmet';
import { NavLink, Link } from 'react-router-dom';

const PrivacyPolicy = () => {
    document.title = "Privacy Policy - Ewen Realtors";
    const ldJson = 
    {
        "@context" : "http://schema.org",
        "@type" : "Real Estate Business",
        "name" : "Ewen Realtors",
        "image" : "https://ewenrealtors.com/logo.svg",
        "telephone" : "+91 852-616-4444",
        "address" : {
          "@type" : "PostalAddress",
          "streetAddress" : "Ewen Realtors NH 109, Nainital Road, Kathgodam, Uttarakhand 263139"
        }
    }

    return(
        <div className="text-start pt-5">
            <Helmet>
                <meta name="description" content="Each detail has been provided with the knowledge of the owner, and proper precautions have been taken to protect the user's credentials from being misused, lost, or accidentally eliminated within the Ewen Realtors environment." />
                <link rel="canonical" href="https://ewenrealtors.com/privacy-policy" />
                <meta property="og:locale" content="en_US" />
                <meta property="og:type" content="website" />
                <meta property="og:title" content="Privacy Policy | Ewen Realtors - Best Real Estate in India" />
                <meta property="og:description" content="Each detail has been provided with the knowledge of the owner, and proper precautions have been taken to protect the user's credentials from being misused, lost, or accidentally eliminated within the Ewen Realtors environment." />
                <meta property="og:url" content="https://ewenrealtors.com/privacy-policy" />
                <meta property="og:site_name" content="Ewen Realtors" />
                <meta property="article:publisher" content="https://www.facebook.com/ewenrealtors" />
                <meta property="article:modified_time" content="2022-03-23T06:20:43+00:00" />
                <meta property="og:image:width" content="800" />
                <meta property="og:image:height" content="450" />
                <meta property="og:image:type" content="image/jpeg" /> 
                
                {/* JSON-LD markup generated by Google Structured Data Markup Helper. */}
                <script type="application/ld+json">
                    {JSON.stringify(ldJson)}
                </script>
            </Helmet>
            <div className='container col-md-6 mb-5' style={{color: 'rgba(0,0,0,0.75)'}}>

                <h1>Privacy And Policy</h1>

                <p>Ewen Realtors is earmarked to support the privacy and accuracy of the information that has been provided on this site.</p> 

                <p>Each detail has been provided with the knowledge of the owner, and proper precautions have been taken to protect the user's credentials from being misused, lost, or accidentally eliminated within the Ewen Realtors environment.</p> 

                <p>The data presented on this website have been carefully supervised, however, Ewen Realtors will not be held liable or accept any penalty for losses caused by the use of the data.</p> 

                <h2>Personal Information Use And Collection</h2> 

                <p>Various methods are available to us for collecting personal designation information from Users.</p>  
                
                <p>These include, but are not limited to, when users visit our website, fill out a form, or in connection with other activities, services, or features available on our website.</p> 
                
                <p>Users may be requested for, their name, email address, and phone number. Users may, however, visit our site anonymously. </p>
                
                <p>Users can always deny delivering individual identification data, except that it may stop them from engaging in specific site-related activities.</p> 

                <h2>Non-Personal Information Use And Collection </h2> 

                <p>We may stash non-personal identification information about users whenever they interact with our site.</p>  
                
                <p>Users' non-personal identification information may include the type of browser they use, the type of computer, or other information indicating how they found our sites, such as the operating system and the Internet service providers.</p> 
                
                <h2>How We Use Collected Data?</h2>
                <p>For the following purposes, Ewen Realtors use user’s personal information:</p> 

                <ul>
                    <li>
                        To send periodic emails. 
                    </li>
                    <li>
                For running campaigns, surveys, contests, or other site features.

                    </li>
                    <li>
                To improve the user experience: We may use data entirety to understand how our Users as a group utilize the services and resources furnished on our Site.

                    </li>
                    <li>
                For improving our site: We may use the feedback given by the users to improve our services and give them the best experience.  

                    </li>
                    <li>
                To send the user information they want to receive about topics of interest to them.

                    </li>
                    <li>
                For improving client services: Information provided by users permits us to respond to the customer's services request and support needs, more efficiently. 

                    </li>

                </ul>






                <h3>How We Protect Our Customers’ Information?</h3>
                <p>Keeping personal information and data safe on our website is our top priority. We adopt proper procedures for collecting, storing, and processing data.</p> 

                <p>We go to great lengths to guard against unauthorized access, exposure, or destruction of the User's information.</p> 

                <p>As with information protection, there are limitations to its persuasion and we compensate ourselves in the circumstance of an attack that is challenging to safeguard against.</p> 

                <p>We also do our best to recover any data that is lost as per available resources. Personal identification information about Users is not sold, traded, or rented to others.</p>  

                <p>Our deputies, our business partners, trusted affiliates, and advertisers may accept generic aggregated demographic information about Users that is not linked to any personal identification information.</p>  
                <h3>Changes To Privacy And Policy</h3>
                <p>Ewen Realtors shall update this privacy policy at its sole constraint. Users are advised to check this page periodically for any changes to the privacy policy and to stay informed about how our organization protects users' personal information.</p>  

                <p>The User hereby accepts and agrees that it is their responsibility to check this privacy policy page from time to time and become aware of changes.</p> 

                <p>By using this Site, the Users denote their approval of this policy as may be changed from time to time.</p>  

                <p>The Users are urged not to access this site if they do not approve of our privacy policy. The above-mentioned privacy policy shall be appropriate to the information and data collected by our call centers as well.</p> 

                <h2>Contact Us</h2> 
                <p>If you have any queries about this Privacy Policy, the rules of this site, or your transactions with this site, please reach us at Ewen Realtors (+91 852-616-4444).</p> 

            </div>
        </div>
    );
}

export default PrivacyPolicy;