import { Link } from 'react-router-dom';
import '../css/Sitemap.css';
import Helmet from 'react-helmet';
import SitemapBlogs from './SitemapBlogs';

const Sitemap = () => {
    document.title = "Sitemap - Ewen Realtors";
    return (
        <>
            <div className="container col-md-8 mt-3 mb-4">
                <h3 className='text-start font-weight-bolder px-3 pt-3'>Sitemap</h3>
                <div className="row m-0">
                    <div className="col-md-3 p-3 text-start">
                        <ul className='sitemap_title'><b>Pages</b>
                            <li className='pt-2'><Link to="/">Home</Link></li>
                            <li><Link to="/about-us">About Us</Link></li>
                            <li><Link to="/services">Services</Link></li>
                            <li><Link to="/projects">Projects</Link></li>
                            <li><Link to="/blog">Blogs</Link></li>
                            <li><Link to="/media">Gallery</Link></li>
                            <li><Link to="/careers">Careers</Link></li>
                            <li><Link to="/partner">Join As Partner</Link></li>
                        </ul>
                    </div>
                    <div className="col-md-3 p-3 text-start">
                        <ul className='sitemap_title'><b>Services</b>
                            <li className='pt-2'><Link to="/services/builder-and-developer">Builder & Developer</Link></li>
                            <li><Link to="/services/construction-service">Construction Services</Link></li>
                            <li><Link to="/services/real-estate-consultant">Real Estate Consultant</Link></li>
                            <li><Link to="/services/property-legal-services">Property Legal Services</Link></li>
                            <li><Link to="/services/home-loan-services">Home Loan Services</Link></li>
                            <li><Link to="/services/vastu-consultant">Vastu Consultant</Link></li>
                            <li><Link to="/services/interior-designer">Interior Designer</Link></li>
                        </ul>
                    </div>
                    <div className="col-md-3 p-3 text-start">
                        <ul className='sitemap_title'><b>Projects</b>
                            <li className='pt-2'><Link to="/projects/royal-villas">Royal Villas</Link></li>
                            <li><Link to="/projects/himalayan-vista">Himalayan Vista</Link></li>
                            <li><Link to="/projects/hill-village">Hill Village</Link></li>
                        </ul>
                        <ul className='sitemap_title pt-3'><b>Help & Support</b>
                            <li className='pt-2'><Link to="/faqs">FAQ</Link></li>
                            <li><Link to="/contact-us">Contact Us</Link></li>
                        </ul>
                    </div>
                    <div className="col-md-3 p-3 text-start">
                        <ul className='sitemap_title'><b>Policy</b>
                            <li className='pt-2'><Link to="/disclaimer">Disclaimer</Link></li>
                            <li><Link to="/privacy-policy">Privacy</Link></li>
                            <li><Link to="/terms-and-conditions">Terms & Conditions</Link></li>
                            <li><Link to="/refund-policy">Refund</Link></li>
                        </ul>
                    </div>
                    <div className="col-md-12 p-3 text-start">
                        <hr />
                        <ol className='blogList'><b>Blogs</b>
                            <div className='row m-0 mt-2'>
                                <SitemapBlogs />
                            </div>
                        </ol>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Sitemap;