import { NavLink } from 'react-router-dom';
import AllServices from './AllServices';
import Helmet from 'react-helmet';

const VastuConsultant = () => {
    document.title = "Vastu Consultant | Ewen Realtors - Best Real Estate in India";
    const ldJson = 
    {
        "@context" : "http://schema.org",
        "@type" : "Real Estate Business",
        "name" : "Ewen Realtors",
        "image" : "https://ewenrealtors.com/logo.svg",
        "telephone" : "+91 852-616-4444",
        "address" : {
          "@type" : "PostalAddress",
          "streetAddress" : "Ewen Realtors NH 109, Nainital Road, Kathgodam, Uttarakhand 263139"
        }
    }
    return( 
        <div className='text-start p-0 m-0'>
            <Helmet>
                <meta name="description" content="Vastu shastra is the only science that instructs us how to maintain the balance of the universe’s five components, namely water, air, fire, space, and sky, in the designing of houses, offices, and industries in order to extract the most positive energy." />
                <link rel="canonical" href="https://ewenrealtors.com/services/vastu-consultant" />
                <meta property="og:locale" content="en_US" />
                <meta property="og:type" content="website" />
                <meta property="og:title" content="Vastu Consultant | Ewen Realtors - Best Real Estate in India" />
                <meta property="og:description" content="Vastu shastra is the only science that instructs us how to maintain the balance of the universe’s five components, namely water, air, fire, space, and sky, in the designing of houses, offices, and industries in order to extract the most positive energy." />
                <meta property="og:url" content="https://ewenrealtors.com/services/vastu-consultant" />
                <meta property="og:site_name" content="Ewen Realtors" />
                <meta property="article:publisher" content="https://www.facebook.com/ewenrealtors" />
                <meta property="article:modified_time" content="2022-03-23T06:20:43+00:00" />
                <meta property="og:image:width" content="800" />
                <meta property="og:image:height" content="450" />
                <meta property="og:image:type" content="image/jpeg" /> 
                
                {/* JSON-LD markup generated by Google Structured Data Markup Helper. */}
                <script type="application/ld+json">
                    {JSON.stringify(ldJson)}
                </script>
            </Helmet>
            <AllServices />
            <div className='my-4 container'>
                <h1 className="mb-4">Vastu Consultant</h1>
                <img src="/assets/images/vastu-consultant.jpg" style={{width: '100%', borderRadius: '4px'}} />

                <p className='mt-3'>The ancient Vedic science of construction is known as Vastu Shastra. It aids in the extraction of advantages from the universe&#8217;s five elements.&nbsp;</p>

                <p>We need numerous professionals while buying a new home, such as property consultants, real estate agents, legal service experts, and interior designers, but we often overlook the need for a Vastu consultant.&nbsp;</p>

                <h2>You&#8217;re probably wondering who’s a Vastu consultant?</h2>

                <p>Vastu experts can help you bring happiness and prosperity into your life in a variety of ways.&nbsp;</p>

                <p>The Vastu specialists recommend some specific positioning and directional principles that aid to produce positive energy in every region of the structure.&nbsp;</p>

                <h3>Why Do We Require Them?</h3>

                <p>Vastu shastra is the only science that instructs us how to maintain the balance of the universe&#8217;s five components, namely water, air, fire, space, and sky, in the designing of houses, offices, and industries in order to extract the most positive energy.</p>

                <p>&nbsp;If you are always surrounded by negativity or tension in your life, it is time to visit a Vastu specialist. Purchasing a home or property can be a life-changing decision that can affect your life.&nbsp;</p>

                <p>This is where a Vastu expert comes in handy. Vastu consultants can assist in preserving the equilibrium of the &#8220;panch tattva&#8221; Vastu consultants can assist in living a happy and harmonious life.</p>

                <p>Vastu consultants assist their clients in building a house or business where they can receive the support of all five elements and maintain the equilibrium between them, which is required for a safe and productive life.</p>

                <h3>Basic Vastu Tips To Build A House</h3>

                <img src="/assets/images/vastu-tips.jpg" style={{width: '100%', borderRadius: '4px'}} className="my-3" />

                <p>Here are some of the basic Vastu tips and suggestions that you should know before building your home. So let’s go through with these major points below: &#8211;</p>

                <ol><li>Entrance of a house- The entrance to a house is very essential in the lives of its occupants. There are 32 possible entrances, according to Mahavastu. To attract fortunate conditions in life, you must establish a Vastu-perfect entryway.</li></ol>

                <ol start="2"><li>The direction of the toilet- A toilet in the wrong location can cause havoc. Try to build a toilet in the south-southwest, east-southeast, and west-northwest directions. A toilet in the northeast zone is strictly prohibited since it causes neurological difficulties.</li></ol>

                <ol start="3"><li>Borewell and water tanks- if you are creating a borewell and an underground water tank, then it must be in the north-northeast, east-northeast, or west zone only.</li></ol>

                <ol start="4"><li>Location of the bedroom- choose the location of the bedroom very carefully. A bedroom in the northeast can disturb your mind and can even cause dementia. Whereas a bedroom in the east-northeast will bring joy and inner happiness. It’s most suitable for old people. For a person in relation to Vor in politics, a bedroom in the east proves beneficial. For skilled workers and artists, the southwest zone is the best bedroom.</li></ol>

                <ol start="5"><li>Pooja room- for better clarity and peace of mind build a pooja room in the northeast zone.&nbsp;</li></ol>

                <ol start="6"><li>Study room for kids- If you want your kid to excel in studies make a study room in the west-southwest zone.&nbsp;</li></ol>

                <ol start="7"><li>Kitchen &#8211; west is the ideal location for a kitchen. Whereas, A kitchen in the northeast and southwest hampers your clarity and skills.&nbsp;</li></ol>

                <ol start="8"><li>Staircase-&nbsp; Stairs can go from north to south or east to west.&nbsp;</li></ol>

                <ol start="9"><li>Selection of colors- while each direction has specific colors at times it still does not suit the owner. Therefore, homeowners should adhere to the general guidelines for colors as per Vastu shastra&nbsp;</li></ol>

                <ul><li>Northeast- light blue</li><li>East- white or light blue</li><li>Southeast- as the direction is associated with fire, orange, pink, and silvers color can be used to enhance the energy.</li><li>North- Green, pista green</li><li>Northwest- this area is related to air so white, light grey and cream are the best colors.</li><li>West- it is the place of ‘Varun’ i.e water. So the best color is blue or white</li><li>Southwest- peach, mud color, biscuit color, or light brown.</li><li>South- red and yellow.</li></ul>

                <h3>Basic Direction For A Home To Be Built</h3>

                <img src="/assets/images/vastu-directions.jpg" style={{width: '100%', borderRadius: '4px'}} className="my-3" />

                <p>Here are some fundamental directional Vastu tips that you can learn and apply.&nbsp;</p>

                <ol><li><strong>North-west</strong></li></ol>

                <ul><li>Guest room&nbsp;</li><li>Children bedroom</li><li>&nbsp;Toilet/septic tanks</li><li>Parking&nbsp;</li></ul>

                <p>2<strong>. North</strong></p>

                <ul><li>Entrance</li><li>Open space</li><li>Living room</li><li>Bathroom&nbsp;</li></ul>

                <p><strong>3. North-east</strong></p>

                <ul><li>Entrance and temple</li><li>Living room</li><li>Balcony</li><li>Open space</li></ul>

                <p>&nbsp;&nbsp;&nbsp;4. <strong>West</strong></p>

                <ul><li>Dining room</li><li>Children bedroom</li><li>Study room</li></ul>

                <p>5. <strong>East</strong></p>

                <ul><li>Entrance&nbsp;</li><li>Living room</li><li>Open space</li><li>Bathroom&nbsp;</li><li>Guest room</li></ul>

                <p>6. <strong>South-west</strong></p>

                <ul><li>Master bedroom</li><li>Treasury&nbsp;</li><li>Wardrobe&nbsp;</li><li>Heavy items</li><li>staircase</li></ul>

                <p>7. <strong>South</strong></p>

                <ul><li>Bedroom</li><li>Staircase block</li><li>Storeroom</li></ul>

                <p><strong>8. South-east</strong></p>

                <ul><li>Kitchen-face east&nbsp;</li><li>Electric meter</li><li>Storeroom</li></ul>

                <h2>Vastu- Importance Of Vastu Shastra</h2>

                <img src="/assets/images/importance-of-vastu.jpg" style={{width: '100%', borderRadius: '4px'}} className="my-3" />

                <p>&nbsp;Although Vastu shastra is a five-thousand-year-old art, there has been a surge in interest in the subject since the 1990s. Over the last decade, Vastu has gained popularity.&nbsp;</p>

                <p>It’s a vast and Vedic science of living. The word Vastu is not equivalent to the word architecture. Vastu shastra is the edifice science of “BHAWAN STHAPATYA KALA” which means the science of building is based on applied aspects of ‘Atharva Veda”.</p>

                <p>&nbsp;It is important to follow Vastu shastra. If the construction of the building is not according to the principles of Vastu shastra then the action of people working or staying here will not be peaceful and progressive.&nbsp;</p>

                <p>It can become the reason for disorder and illness. This is the main reason for unhappiness and suffering. The spirit of the house was called “The Vastu Purusha” and the different cardinal directions are east, west, north, and south were assigned to different gods.&nbsp;</p>

                <p>The waves flowing in a particular direction have a specific influence on human life; it maximizes positivity.&nbsp;</p>

                <p>If the various activities in a house, shop, building, office and industry are directionally organized as per the Vastu shastra then it means to draw the power from nature in a natural way.&nbsp;</p>

                <p>Vastu shastra is not only applied in houses but also in commercial buildings and industries.</p>

                <p>Thus, there is a tremendous need for architects and Vastu consultants to collaborate because an architect can build a Porsche house but cannot assure a happy life for the people living in that house.&nbsp;</p>

                <p>Whereas Vastu Shastra on other hand can assure peace, prosperity, and progress to the owner. This mixture of ancient heritage and modern science can go a long way in revitalizing the edifice of science.&nbsp;</p>

                <p>In a nutshell, Vastu shastra lays forth the principles for designing residences, commercial buildings, and industries that blend in with the environment, nature, and the universe.</p>

                <h2>FAQ&#8217;s</h2>

                <div class="wp-block-uagb-faq uagb-faq__outer-wrap uagb-block-9e06c188 uagb-faq-icon-row uagb-faq-layout-accordion uagb-faq-expand-first-false uagb-faq-inactive-other-true uagb-faq-equal-height" data-faqtoggle="true" role="tablist"><div class="uagb-faq__wrap uagb-buttons-layout-wrap">
                <div class="wp-block-uagb-faq-child uagb-faq-child__outer-wrap uagb-block-7b5fbe43"><div class="uagb-faq-child__wrapper"><div class="uagb-faq-item" role="tab" tabindex="0"><div class="uagb-faq-questions-button uagb-faq-questions"><span class="uagb-question">What is Vatsu Shastra?</span></div><div class="uagb-faq-content"><span><p>Ans.  The Ancient Vedic science of construction is known as Vastu Shastra. It aids in the extraction of advantages from the universe&#8217;s five elements.  </p></span></div></div></div></div>

                <div class="wp-block-uagb-faq-child uagb-faq-child__outer-wrap uagb-block-7ceb0e90"><div class="uagb-faq-child__wrapper"><div class="uagb-faq-item" role="tab" tabindex="0"><div class="uagb-faq-questions-button uagb-faq-questions"><span class="uagb-icon-active uagb-faq-icon-wrap"><svg xmlns="http://www.w3.org/2000/svg" viewbox="0 0 448 512"><path d="M416 208H32c-17.67 0-32 14.33-32 32v32c0 17.67 14.33 32 32 32h384c17.67 0 32-14.33 32-32v-32c0-17.67-14.33-32-32-32z"></path></svg></span><span class="uagb-question"> Why we should hire a Vastu consultant? </span></div><div class="uagb-faq-content"><span><p>Ans.  Vastu experts can help you bring happiness and prosperity into your life in a variety of ways.  </p></span></div></div></div></div>

                <div class="wp-block-uagb-faq-child uagb-faq-child__outer-wrap uagb-block-f87eadd5"><div class="uagb-faq-child__wrapper"><div class="uagb-faq-item" role="tab" tabindex="0"><div class="uagb-faq-questions-button uagb-faq-questions"><span class="uagb-icon-active uagb-faq-icon-wrap"><svg xmlns="http://www.w3.org/2000/svg" viewbox="0 0 448 512"><path d="M416 208H32c-17.67 0-32 14.33-32 32v32c0 17.67 14.33 32 32 32h384c17.67 0 32-14.33 32-32v-32c0-17.67-14.33-32-32-32z"></path></svg></span><span class="uagb-question">What does Vastu Consultant do?</span></div><div class="uagb-faq-content"><span><p>Ans.  Vastu consultants can assist in preserving the equilibrium of the &#8220;panch tattva&#8221; Vastu consultants can assist in living a happy and harmonious life. </p></span></div></div></div></div>

                <div class="wp-block-uagb-faq-child uagb-faq-child__outer-wrap uagb-block-43a6e741"><div class="uagb-faq-child__wrapper"><div class="uagb-faq-item" role="tab" tabindex="0"><div class="uagb-faq-questions-button uagb-faq-questions"><span class="uagb-icon-active uagb-faq-icon-wrap"><svg xmlns="http://www.w3.org/2000/svg" viewbox="0 0 448 512"><path d="M416 208H32c-17.67 0-32 14.33-32 32v32c0 17.67 14.33 32 32 32h384c17.67 0 32-14.33 32-32v-32c0-17.67-14.33-32-32-32z"></path></svg></span><span class="uagb-question">Does Vastu Shastra is only applied at home? </span></div><div class="uagb-faq-content"><span><p>Ans.  Vastu shastra is not only applied in homes but also in commercial buildings and industries. </p></span></div></div></div></div>

                <div class="wp-block-uagb-faq-child uagb-faq-child__outer-wrap uagb-block-ca60d86f"><div class="uagb-faq-child__wrapper"><div class="uagb-faq-item" role="tab" tabindex="0"><div class="uagb-faq-questions-button uagb-faq-questions"><span class="uagb-question">What is &#8220;The Vastu Purusha&#8221;? </span></div><div class="uagb-faq-content"><span><p>Ans. The spirit of the house is called “The Vastu Purusha”, and the different cardinal directions are east, west, north, and south were assigned to different gods.   </p></span></div></div></div></div>

                <div class="wp-block-uagb-faq-child uagb-faq-child__outer-wrap uagb-block-7045cb2d"><div class="uagb-faq-child__wrapper"><div class="uagb-faq-item" role="tab" tabindex="0"><div class="uagb-faq-questions-button uagb-faq-questions"><span class="uagb-question">Who is Vastu Consultant?</span></div><div class="uagb-faq-content"><span><p>Ans.  The Vastu Consultant recommends some specific positioning and directional principles that aid to produce positive energy in every region of the structure which can help bring happiness and prosperity into the person&#8217;s life in a variety of ways.  </p></span></div></div></div></div></div></div>
            </div>
        </div>
    );
}

export default VastuConsultant;
