import React, { useRef, useState } from 'react';
import emailjs from '@emailjs/browser';
import Helmet from 'react-helmet';

const PostPageForm = () => {
    const form = useRef();

    const sendEmail = (e) => {
        e.preventDefault();

        emailjs.sendForm('service_02sbdgf', 'template_fghjv75', form.current, 'mdnt0vxswt93nM4fx')
        .then((result) => {
            //   console.log(result.text);
            e.target.reset();
            alert('Your Request sended successfully to our team.');
            }, (error) => {
                alert(error.text);
                // console.log(error.text);
        });
        alert('Please Wait...');
    };

    return(
        <>
            <div className="border rounded-3 p-0">
                <h3 className="mb-2 p-3" style={{borderRadius: '5px 5px 0px 0px'}} id="gradientBtn">Want To Live In Mountains?</h3>
                <form ref={form} onSubmit={sendEmail} id="demo-form" className='px-3 pb-3'>
                    <h4 className='pt-2 mt-2 mb-3'>Contact Us</h4>
                    {/* <label className="py-2">Username</label> */}
                    <input name="username" type="text" className="form-control mb-3" required placeholder='Username' />

                    {/* <label className="py-2">Phone</label> */}
                    <input  name="phone" type="tel" className="form-control mb-3" required placeholder='Phone' />

                    {/* <label className="py-2">Email</label> */}
                    <input name="email" type="email" className="form-control mb-3" required placeholder='Email' />

                    {/* <label className="py-2">Message</label> */}
                    <textarea name="message" className="form-control mb-1" rows={2}  placeholder='Write your message...'></textarea>

                    <button className="btn btn-dark rounded-2 text-light shadow-1 text-capitalize mt-3" type="submit">Send Message</button>
                </form>
            </div>
        </>
    );
}

export default PostPageForm;