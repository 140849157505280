import '../css/Main.css';
import '../css/Home.css';
import { NavLink, Link } from 'react-router-dom';
import Helmet from 'react-helmet';
import Carousel from './Main/Carousel';
import Amenities from './Main/Amenities';
import Facts from './Main/Facts';

import HomeRecentPosts from './HomeRecentPosts';
import Pricing from './Pricing';

import CostPaymentPlan from './CostPaymentPlan';

const Main = () => {
    document.title = "Ewen Realtors - Best Real Estate in India";
    const ldJson = 
    {
        "@context" : "http://schema.org",
        "@type" : "Real Estate Business",
        "name" : "Ewen Realtors",
        "image" : "https://ewenrealtors.com/logo.svg",
        "telephone" : "+91 852-616-4444",
        "address" : {
          "@type" : "PostalAddress",
          "streetAddress" : "Ewen Realtors NH 109, Nainital Road, Kathgodam, Uttarakhand 263139"
        }
    }

    return (
        <div className="text-start">
            <Helmet>
                <meta name="description" content="Hey! We have seen technologies bringing magical efficiencies in our daily life. Life of mountains need these efficient processes to ease up an already very HECTIC life." />
                <link rel="canonical" href="https://ewenrealtors.com/" />
                <meta property="og:locale" content="en_US" />
                <meta property="og:type" content="website" />
                <meta property="og:title" content="Ewen Realtors - Best Real Estate in India" />
                <meta property="og:description" content="Hey! We have seen technologies bringing magical efficiencies in our daily life. Life of mountains need these efficient processes to ease up an already very HECTIC life." />
                <meta property="og:url" content="https://ewenrealtors.com/" />
                <meta property="og:site_name" content="Ewen Realtors" />
                <meta property="article:publisher" content="https://www.facebook.com/ewenrealtors" />
                <meta property="article:modified_time" content="2022-03-23T06:20:43+00:00" />
                <meta property="og:image" content="https://old.ewenrealtors.com/wp-content/uploads/2022/01/ewenrealtors-dream-house.jpg" />
                <meta property="og:image:width" content="800" />
                <meta property="og:image:height" content="450" />
                <meta property="og:image:type" content="image/jpeg" /> 
                
                {/* JSON-LD markup generated by Google Structured Data Markup Helper. */}
                <script type="application/ld+json">
                    {JSON.stringify(ldJson)}
                </script>
            </Helmet>

            {/* carousel  */}
            <Carousel />

            {/* Amenities  */}
            <Amenities />

            {/* Projects & Plans  */}
            <Pricing />
            <div className='mt-4 mb-5 text-center'>
                <a href="#payment-plan">
                    <button className='btn btn-outline-primary text-capitalize px-3 py-2'>Cost & Payment Plan <i className='fas fa-arrow-down'></i></button>
                </a>
            </div>


            {/* Project Video  */}
            <div>
                <div className='container'>
                    <div className='projectVideo p-0 m-0'>
                        <iframe src="https://www.youtube.com/embed/ydZEb-jL49c" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen className='introVideo'></iframe>
                    </div>
                </div>
            </div>

            {/* Cost and Payment plan  */}
            <CostPaymentPlan />

            {/* About Us  */}
            <div className='container'>
                <div className='row m-0 my-5 align-items-center goingTR'>
                    <div className='col-md-5 aboutImgCont'>
                        <img src="/assets/images/ewen-studio.jpg" className='homeAboutImg' />
                    </div>
                    <div className='col-md-7 aboutUs'>
                        <h2 className='pb-4'>About Us - Ewen Realtors</h2>
                        <p>
                            Ewen Realtors was incorporated with an aim to ‘Define Dreams’ by taking your real estate experience to new heights in the Himalayas.
                        </p>
                        <p>
                            Not just as a promise but as an assurance to provide unsurpassed service and a promise to do our best to make your Himalayan Sojourn a reality.
                        </p>
                        <p>
                            We have increased transparency and trust in the real estate process by researching and compiling all information and assisting you with the best advice in your real estate journey.
                        </p>
                        <p>
                            When our lungs inflate with the onrush of scenery- The Air, The Himalayas, The Trees, Accompanied with spritual peace its then you will know, this is what it is to be happy!!!
                        </p>
                    </div>
                </div>
            </div>

            {/* CallToAction  */}
            <div className='py-5 my-md-3' style={{background: '#fff'}}>
                <div className='container mb-4'>
                    <div className='row m-0 justify-content-center align-items-center'>
                        <div className='col-md-8 callToActText'>
                            <h2 className='py-2 text-dark'>Did You Know?</h2>
                            <p className='text-dark'>
                            According to a survey, 90% people of urban areas want to reloacte to the mountains to live a healthy and peaceful life.
                            </p>
                        </div>
                        <div className='col-md-4 callToActBtns'>
                            <NavLink exact to="/blog/shift-towards-luxury-destination-ranikhet/" target={'_blank'}>
                                <button className='btn btn-lg text-capitalize btn-danger shadow-0 text-light'>
                                    <i className='fas fa-book'></i> Read More
                                </button>
                            </NavLink>
                            &nbsp;&nbsp;
                            <NavLink exact to="/blog">
                                <button className='btn btn-lg text-capitalize btn-dark'>
                                    <i className='fas fa-cubes'></i> Blog
                                </button>
                            </NavLink>
                        </div>
                    </div>
                </div>
                {/* Facts  */}
                <Facts />
            </div>


            {/* Send Mail  */}
            <div className='mt-md-5 p-md-5'>
                <div className='container'>
                    <div className='row m-0 align-items-center goingTR'>
                        <div className='col-md-6 px-md-5 didYouKnow'>
                            <h2 className='head3'>Need Any Help With The Projects?</h2>
                            <p className='py-3'>
                                Want to resolve your query regarding Project? If Yes, Send Us Mail To Solve Your Query
                            </p>
                            <a href="mailto: info@ewenrealtors.com">
                                <button className='btn btn-info text-light px-4 py-3 text-capitalize'><i className='fas fa-envelope'></i>&nbsp; Send Mail</button>
                            </a>
                        </div>  
                        <div className='col-md-6 px-md-4'>
                            <img src="/assets/images/did-you-know.JPG" className='didYouKnow-img rounded-3' style={{width: '100%'}} />    
                        </div>  
                    </div>
                </div>
            </div>
            
            {/* Memory  */}
            <div className='memCont px-md-3 py-4 d-flex align-items-center'>
                <div className='col-md-12'>
                    <div className='myCard row m-0 align-items-center'>
                        <div className='col-md-4 text-center'>
                            <img src={process.env.PUBLIC_URL + "assets/images/nayak-kesar-singh-rawat-ji.jpeg"} style={{height: 'auto', width: '85%', borderRadius: '50%'}} />
                        </div>
                        <div className='col-md-8 py-4' id="tricolor">
                            <p className='font-weight-bold text-center' id="triHead">
                                In Memory Of Late Nayak Kesar Singh Rawat, We Are Giving A Flat 5% Off To The Family Of The Soldiers.
                            </p>
                            <div className='pt-2 pb-4 text-center'>
                                <img src="/assets/images/separator.svg" style={{height: '25px'}} />
                            </div>
                            <p style={{fontSize: '16px'}} className='text-center'>
                                We salute all the brave soldiers who gave up their lives fearlessly to protect our nation and its people without even thinking of their family and friends.
                            </p>
                            <p style={{fontSize: '16px'}} className='text-center'>
                                Late Nayak Kesar Singh Rawat father of Mr. Rajesh Rawat (Chairman of Ewen Realtors) was a soldier in the Indian Army, who was martyred in 1996 while securing the border in Kargil.
                            </p>
                        </div>
                    </div>
                </div>
            </div>

            <div className='my-5'>
                <HomeRecentPosts />
                <div className='text-center'>
                    <NavLink exact to="/blog">
                        <button className='btn btn-outline-primary border-1 text-capitalize' style={{fontSize: '14px'}}><i class="fas fa-pager"></i>&nbsp;View All</button>
                    </NavLink>
                </div>
            </div>

        </div>
    );
}

export default Main;