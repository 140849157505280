const Amenities = () => {
    return(
        <div className="container">
            <div className="row m-0 amentiesSection align-items-center">
                <div className="col-md-6 px-md-3">
                    <h2 className='text-center font-weight-bold amenities pb-5'>
                        Every <i style={{color: 'orangered',}}>Life Matters</i>,<br/>We Do Care For Our Customer
                    </h2>
                </div>
                <div className="col-md-6 amenitiesItems">
                    <div id="carouselExampleCaptions" class="carousel slide" data-mdb-ride="carousel">
                        <div class="carousel-indicators">
                            <button
                            type="button"
                            data-mdb-target="#carouselExampleCaptions"
                            data-mdb-slide-to="0"
                            class="active"
                            aria-current="true"
                            aria-label="Slide 1"
                            ></button>
                            <button
                            type="button"
                            data-mdb-target="#carouselExampleCaptions"
                            data-mdb-slide-to="1"
                            aria-label="Slide 2"
                            ></button>
                            <button
                            type="button"
                            data-mdb-target="#carouselExampleCaptions"
                            data-mdb-slide-to="2"
                            aria-label="Slide 3"
                            ></button>
                            <button
                            type="button"
                            data-mdb-target="#carouselExampleCaptions"
                            data-mdb-slide-to="3"
                            aria-label="Slide 4"
                            ></button>
                        </div>
                        <div class="carousel-inner">
                            <div class="carousel-item active">
                                <img src="/assets/images/amenities/amenity1.jpg" class="d-block w-100" alt="Wild Landscape"/>
                                <div class="carousel-caption d-none d-md-block">
                                    <h5>Healthy & Happy Life</h5>
                                </div>
                            </div>
                            <div class="carousel-item">
                                    <img src="/assets/images/amenities/amenity2.jpg" class="d-block w-100" alt="Camera"/>
                                    <div class="carousel-caption d-none d-md-block">
                                        <h5>Himalayan View</h5>
                                    </div>
                            </div>
                            <div class="carousel-item">
                                <img src="/assets/images/amenities/amenity3.jpg" class="d-block w-100" alt="Exotic Fruits"/>
                                <div class="carousel-caption d-none d-md-block">
                                    <h5>Security</h5>
                                </div>
                            </div>
                            <div class="carousel-item">
                                <img src="/assets/images/amenities/amenity4.jpg" class="d-block w-100" alt="Exotic Fruits"/>
                                <div class="carousel-caption d-none d-md-block">
                                    <h5>Biophilic Design</h5>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Amenities;