import React, { useRef, useState } from 'react';
import Helmet from 'react-helmet';
import ContactForm from './ContactForm';


const Contact = () => {

  document.title = "Contact Us | Ewen Realtors - Best Real Estate in India";
    const ldJson = 
    {
        "@context" : "http://schema.org",
        "@type" : "Real Estate Business",
        "name" : "Ewen Realtors",
        "image" : "https://ewenrealtors.com/logo.svg",
        "telephone" : "+91 852-616-4444",
        "address" : {
          "@type" : "PostalAddress",
          "streetAddress" : "Ewen Realtors NH 109, Nainital Road, Kathgodam, Uttarakhand 263139"
        }
    }

  return (
    <div className="text-start container">
            <Helmet>
                <meta name="description" content="Contact Us | Ewen Realtors - Best Real Estate in India" />
                <link rel="canonical" href="https://ewenrealtors.com/contact-us" />
                <meta property="og:locale" content="en_US" />
                <meta property="og:type" content="website" />
                <meta property="og:title" content="Contact Us | Ewen Realtors - Best Real Estate in India" />
                <meta property="og:description" content="Contact Us | Ewen Realtors - Best Real Estate in India" />
                <meta property="og:url" content="https://ewenrealtors.com/contact-us" />
                <meta property="og:site_name" content="Ewen Realtors" />
                <meta property="article:publisher" content="https://www.facebook.com/ewenrealtors" />
                <meta property="article:modified_time" content="2022-03-23T06:20:43+00:00" />
                <meta property="og:image:width" content="800" />
                <meta property="og:image:height" content="450" />
                <meta property="og:image:type" content="image/jpeg" /> 
                
                {/* JSON-LD markup generated by Google Structured Data Markup Helper. */}
                <script type="application/ld+json">
                    {JSON.stringify(ldJson)}
                </script>
            </Helmet>
            
            <ContactForm/>

            <div className="mb-4">
                <h2 className="my-3">Reach Us</h2>
                <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d111507.83601425057!2d79.41051445820311!3d29.1464004!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x39a09b25b3566659%3A0xae65fde32b4e6eb7!2sEwen%20Realtors!5e0!3m2!1sen!2sin!4v1647061584638!5m2!1sen!2sin" allowFullScreen="" loading="lazy" style={{width: '100%', height: '400px', borderRadius: '8px'}}></iframe>
            </div>

        </div>
  );
};

export default Contact;