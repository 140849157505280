import '../css/Header.css';
import { Link, NavLink } from 'react-router-dom';
import Navbar from './Navbar';

const Header = () => {
    return (
        <div>
            <div style={{fontSize: '12px', background: '#172337'}} className='px-1 py-2 text-end fixed-top'>
                <div className='d-flex align-items-center' id="topBar">
                    <div className='col-6 text-start'>
                        <a href="tel: 8526164444" className='deskMob' style={{color: '#eee', textDecoration: 'none', margin:'0px 10px'}}><i className='fas fa-phone-alt'></i> +91 852-616-4444</a>  
                    </div>
                    <div className='col-6'>
                        <a href="tel: 8526164444" className='mobMob' style={{color: '#eee', textDecoration: 'none', margin:'0px 5px'}}><i className='fas fa-phone-alt'></i> +91 852-616-4444</a>  
                        <a href="mailto: info@ewenrealtors.com" style={{color: '#dc143c', textDecoration: 'none', margin:'0px 5px'}}><i className='fas fa-envelope fa-lg'></i></a>  
                        <a href="https://www.facebook.com/ewenrealtors/" target="_blank" style={{color: '#4267B2', textDecoration: 'none', margin:'0px 5px'}}><i className='fab fa-facebook fa-lg'></i></a>
                        <a href="https://www.instagram.com/ewenrealtors/" target="_blank" style={{color: '#dc143c', textDecoration: 'none', margin:'0px 5px'}}><i className='fab fa-instagram fa-lg'></i></a>
                        <a href="https://www.linkedin.com/company/ewenrealtors" target="_blank" style={{color: '#0e76a8 ', textDecoration: 'none', margin:'0px 5px'}}><i className='fab fa-linkedin fa-lg'></i></a>
                        <a href="https://twitter.com/EwenRealtors" target="_blank" style={{color: '#00acee ', textDecoration: 'none', margin:'0px 5px'}}><i className='fab fa-twitter fa-lg'></i></a>
                        <a href="https://www.youtube.com/channel/UCEHZDj1RG_PA1wUqlE4l4kQ" target="_blank" style={{color: '#FF0000', textDecoration: 'none', margin:'0px 5px'}}><i className='fab fa-youtube fa-lg'></i></a>
                        <a href="https://in.pinterest.com/ewenrealtors" target="_blank" style={{color: '#E60023', textDecoration: 'none', margin:'0px 5px'}}><i className='fab fa-pinterest fa-lg'></i></a>
                    </div>
                </div>

            </div>
            <div className='container pt-3 pb-2 pt-5' style={{background: '#fff'}}>
                <Link to="/">
                    <img src="/logo.svg" alt="logo" className="appLogo" />
                </Link>
                <div style={{fontSize: '12px'}}>
                    <b>RERA No. : UKREA05230000398</b>
                </div>
            </div>

            <Navbar />
        </div>
    );
}

export default Header; 