import { NavLink } from 'react-router-dom';


const AllProjects = () => {
    return(
        <div className='m-0 px-3 projectFlex align-items-center' style={{overflowX: 'auto !important'}}>
            <NavLink exact to="/projects/royal-villas" className="navLinksProject p-0">
                <div className='rounded text-center px-3 pt-3 pb-2' id="subNav">
                    <img src="/assets/logo/Royal Villas Project Logo.svg" className='projectIcon mb-2' />
                </div>
            </NavLink>
            <NavLink exact to="/projects/himalayan-vista" className="navLinksProject p-0">
                <div className='rounded text-center px-3 pt-3 pb-2' id="subNav">
                    <img src="/assets/logo/Himalayan Vista Project Logo.svg" className='projectIcon mb-2' />
                </div>
            </NavLink>
            <NavLink exact to="/projects/royal-apartments" className="navLinksProject p-0">
                <div className='rounded text-center px-3 pt-3 pb-2' id="subNav">
                    <img src="/assets/logo/royal_apartment_logo.svg" className='projectIcon mb-2' />
                </div>
            </NavLink>
            <NavLink exact to="/projects/kasar-himalayan-chalet" className="navLinksProject p-0">
                <div className='rounded text-center px-3 pt-3 pb-2' id="subNav">
                    <img src="/assets/logo/kasar_himalayan_chalet_logo.svg" className='projectIcon mb-2' />
                </div>
            </NavLink>
            <NavLink exact to="/projects/ewen-farms" className="navLinksProject p-0">
                <div className='rounded text-center px-3 pt-3 pb-2' id="subNav">
                    <img src="/assets/logo/farmhouse_logo.svg" className='projectIcon mb-2' />
                </div>
            </NavLink>
            <NavLink exact to="/projects/tathastu" className="navLinksProject p-0">
                <div className='rounded text-center px-3 pt-3 pb-2' id="subNav">
                    <img src="/assets/logo/Tathastu_LOGO.svg" className='projectIcon mb-2' />
                </div>
            </NavLink>

        </div>                    
    );
}

export default AllProjects;
